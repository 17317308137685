import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Feature] Reset Store');

export const IsLoading = createAction(
  '[Feature] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetFeaturesAsCategory = createAction(
  '[Feature] Get All Features As Category',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetAllFeatures = createAction(
  '[Feature] Get All Features',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string;
      filterValue?: string[];
    };
  }>()
);

export const SaveAllFeatures = createAction(
  '[Feature] Save All Features',
  props<{
    payload: any[];
  }>()
);

export const CreateFeature = createAction(
  '[Feature] Create Feature',
  props<{
    payload: {
      accessLevel: number;
      accessLevelDesc?: string;
      parentID: number;
      parentName: string;
      isDefault: true;
      name: string;
      allowFreeTrial: boolean;
      requiresSubscription: boolean;
    };
  }>()
);

export const EditFeature = createAction(
  '[Feature] Edit Feature',
  props<{
    payload: {
      accessLevel: number;
      accessLevelDesc: string;
      parentID: number;
      parentName: string;
      isDefault: true;
      name: string;
      featureId: number;
      allowFreeTrial: boolean;
      requiresSubscription: boolean;
    };
  }>()
);

// export const ChangeFeatureStatus = createAction(
//   '[Feature] Change Feature Status',
//   props<{
//     payload: {
//       featureId: number;
//     };
//   }>()
// );

export const ActivateFeature = createAction(
  '[Feature] Activate Feature',
  props<{
    payload: {
      featureId: number;
    };
  }>()
);

export const DeactivateFeature = createAction(
  '[Feature] Deactivate Feature',
  props<{
    payload: {
      featureId: number;
    };
  }>()
);

export const DeleteFeature = createAction(
  '[Feature] Delete Feature',
  props<{
    payload: {
      featureId: number;
    };
  }>()
);
