import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import * as fromApp from '../app/app.reducer';
import * as FlowmonoAssistantActions from './flowmono-assistant.actions';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  USE_ACCESS_TOKEN,
  USE_DEVELOPER_TOKEN,
} from '../../interceptors/app.interceptor.service';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from '../../services/notification.service';
import { GenericResponse, Notification } from '../../interfaces';
import { HelperService } from '../../services/helper.service';
import { Router } from '@angular/router';

@Injectable()
export class FlowmonoAssistanceEffects {
  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private router: Router
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(FlowmonoAssistantActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getDocumentInsight$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FlowmonoAssistantActions.GetDocumentInsight),
      withLatestFrom(this.store.select('auth')),
      switchMap(([flowmonoAssistantData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/AI/documentinsightbydocumentid/${flowmonoAssistantData?.payload?.documentId}`,

            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                FlowmonoAssistantActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return FlowmonoAssistantActions.SaveDocumentInsight({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );

                return FlowmonoAssistantActions.SaveDocumentInsight({
                  payload: [],
                });
              }
            }),

            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Flowmono Assistant][CatchError] Failed To Get Document Insight ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getDocumentSummary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FlowmonoAssistantActions.GetDocumentSummary),
      withLatestFrom(this.store.select('auth')),
      switchMap(([flowmonoAssistantData]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/AI/documentsummarybydocumentid/${flowmonoAssistantData?.payload?.documentId}`,

            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                FlowmonoAssistantActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return FlowmonoAssistantActions.SaveDocumentSummary({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                  // message:
                  //   'The document is a scanned image and cannot be processed for summarization.',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                  // true
                );

                return FlowmonoAssistantActions.SaveDocumentSummary({
                  payload: [],
                });
              }
            }),

            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Flowmono Assistant][CatchError] Failed To Get Document Summary ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlowmonoAssistantActions.CreateFeedback),
      withLatestFrom(this.store.select('auth')),
      switchMap(([flowmonoAssistantData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocDocumentUrl}/Feedback/createfeedback`,
            {
              objectGuId: authState?.user?.guId,
              objectName: authState.user.documents,
              email: authState.user.Email,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...flowmonoAssistantData.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                FlowmonoAssistantActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Flowmono Assistant] Create Feedback Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Flowmono Assistant] Failed To Create Feedback',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Flowmono Assistant][CatchError] Failed To Create Feedback ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  ingestFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlowmonoAssistantActions.IngestFile),
      withLatestFrom(this.store.select('auth')),
      switchMap(([flowmonoAssistantData, authState]) => {
        const context = this.router.url.includes('external')
          ? new HttpContext().set(USE_DEVELOPER_TOKEN, true)
          : new HttpContext().set(USE_ACCESS_TOKEN, true);

        // const user =
        //   authState.user !== null
        //     ? authState.user.UserId
        //     : flowmonoAssistantData.payload.userEmailAddress;
        /*   const user = this.router.url.includes('external')
          ? flowmonoAssistantData.payload.userEmailAddress
          : authState.user !== null
          ? authState.user.UserId
          : flowmonoAssistantData.payload.userEmailAddress; */

        // Set params

        let params = new HttpParams();

        params = params.set(
          'documentId',
          flowmonoAssistantData?.payload?.documentId!
        );

        params = params.set(
          'userEmailAddress',
          flowmonoAssistantData.payload.userEmailAddress!
        );

        params = params.set(
          'isDocumentReplaced',
          flowmonoAssistantData?.payload?.isDocumentReplaced!
        );

        params = params.set('isGuest', flowmonoAssistantData?.payload?.isGuest);

        if (!this.router.url.includes('external')) {
          params = params.set('userId', authState.user.UserId);
        }

        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/AI/ingestfile/${flowmonoAssistantData?.payload?.documentId}/${flowmonoAssistantData?.payload?.userEmailAddress}/${flowmonoAssistantData?.payload?.isDocumentReplaced}/${flowmonoAssistantData?.payload?.isGuest}`,

            {
              params: params,
              context: context,
            }
          )
          .pipe(
            map((resData: any) => {
              // this.store.dispatch(
              //   FlowmonoAssistantActions.FileLoading({ payload: false })
              // );

              if (resData.succeeded === true) {
                // const notification: Notification = {
                //   state: 'success',
                //   message: resData.message || resData.messages[0],
                // };

                // this.notificationService.openNotification(
                //   notification,
                //   'flwmn-notification-success'

                // );

                this.store.dispatch(
                  FlowmonoAssistantActions.SendTaskId({
                    payload: {
                      taskId: resData.entity,
                    },
                  })
                );
                return {
                  type: '[Flowmono Assistant] File Injection Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Flowmono Assistant]  File Injection Was Successful',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Flowmono Assistant][CatchError]  File Injection Failed ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  createQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlowmonoAssistantActions.CreateQuestion),
      withLatestFrom(this.store.select('auth')),
      switchMap(([flowmonoAssistantData, authState]) => {
        const context = this.router.url.includes('external')
          ? new HttpContext().set(USE_DEVELOPER_TOKEN, true)
          : new HttpContext().set(USE_ACCESS_TOKEN, true);

        const subscriberId =
          authState.user !== null
            ? authState.user.SubscriberId
            : flowmonoAssistantData.payload.subscriberId;

        // const user =
        //   authState.user !== null
        //     ? authState.user.UserId
        //     : flowmonoAssistantData.payload.userEmailAddress;

        const user = this.router.url.includes('external') // Check if external route
          ? flowmonoAssistantData.payload.userEmailAddress // Use email address for external routes
          : authState.user !== null
          ? authState.user.UserId // Use UserId for authenticated internal users
          : flowmonoAssistantData.payload.userEmailAddress;

        return this.http
          .post<any>(
            `${environment.onyxDocDocumentUrl}/AI/predict`,
            {
              ...flowmonoAssistantData.payload,
              userId: user,
              subscriberId: subscriberId,

              // userName: authState.user.firstName,
            },

            {
              context: context,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                FlowmonoAssistantActions.ChatLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                // const notification: Notification = {
                //   state: 'success',
                //   message: resData.message || resData.messages[0],
                // };

                // this.notificationService.openNotification(
                //   notification,
                //   'flwmn-notification-success'
                // );

                this.store.dispatch(
                  FlowmonoAssistantActions.SaveQuestion({
                    payload: resData.entity,
                  })
                );

                return {
                  type: '[Flowmono Assistant] Create Question Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Flowmono Assistant] Create Question Was Successful',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Flowmono Assistant][CatchError] Failed To Create Question ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getChatHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlowmonoAssistantActions.GetChatHistory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([flowmonoAssistantData, authState]) => {
        const context = this.router.url.includes('external')
          ? new HttpContext().set(USE_DEVELOPER_TOKEN, true)
          : new HttpContext().set(USE_ACCESS_TOKEN, true);

        /*   const user = this.router.url.includes('external')
          ? ''
          : authState.user !== null
          ? authState.user.UserId
          : ''; */
        // set params
        let params = new HttpParams();

        params = params.set(
          'documentId',
          flowmonoAssistantData?.payload?.documentId!
        );

        params = params.set(
          'userEmailAddress',
          flowmonoAssistantData.payload.userEmailAddress!
        );

        params = params.set('isGuest', flowmonoAssistantData.payload.isGuest!);

        if (!this.router.url.includes('external')) {
          params = params.set('userId', authState.user.UserId);
        }

        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/AI/chatmessagesbydocumentid/${flowmonoAssistantData?.payload?.documentId}/${flowmonoAssistantData.payload.isGuest}/${flowmonoAssistantData.payload.userEmailAddress}`,
            {
              params: params,
              context: context,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                FlowmonoAssistantActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                // const notification: Notification = {
                //   state: 'success',
                //   message: resData.message || resData.messages[0],
                // };

                // this.notificationService.openNotification(
                //   notification,
                //   'flwmn-notification-success'
                // );
                this.store.dispatch(
                  FlowmonoAssistantActions.SaveChatHistory({
                    payload: resData.entity,
                  })
                );

                return {
                  type: '[Flowmono Assistant] Chat History received successfully',
                };
              } else {
                // const notification: Notification = {
                //   state: 'error',
                //   message: resData.message || resData.messages[0],
                // };

                // // this.notificationService.openNotification(
                // //   notification,
                // //   'flwmn-notification-error'
                // // );

                this.store.dispatch(
                  FlowmonoAssistantActions.SaveChatHistory({
                    payload: null,
                  })
                );

                return {
                  type: '[Flowmono Assistant]  Chat History Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Flowmono Assistant][CatchError] Chat History Failed ${errorRes.message}`
              );
            })
          );
      })
    )
  );
}
