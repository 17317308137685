import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
  mergeMap,
} from 'rxjs/operators';
import * as ArchiveConfigurationAction from './archive-configuration.actions';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import {
  // USE_DEVELOPER_TOKEN,
  USE_ACCESS_TOKEN,
} from '../../interceptors/app.interceptor.service';
import { HelperService } from '../../services/helper.service';
@Injectable()
export class ArchiveConfigurationEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(
      ArchiveConfigurationAction.IsLoading({ payload: false })
    );

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllArchiveConfiguration$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ArchiveConfigurationAction.GetArchiveConfigurations),
        withLatestFrom(this.store.select('auth')),
        mergeMap(([archivedData, authState]) => {
          const subscriberId = archivedData.payload.subscriberId
            ? archivedData.payload.subscriberId
            : authState.user.SubscriberId;
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/ArchiveConfiguration/all/${subscriberId}/${authState.user.UserId}/${archivedData.payload.skip}/${archivedData.payload.take}`,

              {
                // params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ArchiveConfigurationAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    ArchiveConfigurationAction.SaveArchiveConfiguration({
                      payload: resData.entity,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Archive configuration] Failed To Get All Archive configuration',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Archive configuration] Failed To Get All Archive configuration ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  activateConfiguration$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ArchiveConfigurationAction.activateConfiguration),
        withLatestFrom(this.store.select('auth')),
        switchMap(([archiveConfigurationData, authState]) => {
          const payload = {
            ...archiveConfigurationData.payload,
            subscriberId: archiveConfigurationData.payload.subscriberId
              ? archiveConfigurationData.payload.subscriberId
              : authState.user.SubscriberId,
            userId: authState.user.UserId,
          };
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/ArchiveConfiguration/activate`,
              payload,

              {
                // params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ArchiveConfigurationAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Archive configuration] Archive configuration Activation Was Successful',
                  });

                  this.store.dispatch(
                    ArchiveConfigurationAction.IsLoading({ payload: true })
                  );

                  this.store.dispatch(
                    ArchiveConfigurationAction.GetArchiveConfigurations({
                      payload: {
                        skip: 0,
                        take: 10,
                      },
                    })
                  );

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Archive configuration] Archive configuration Action Was Failed',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Archive configuration] Archive configuration Action Was Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deactivateConfiguration$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ArchiveConfigurationAction.deactivateConfiguration),
        withLatestFrom(this.store.select('auth')),
        switchMap(([archiveConfigurationData, authState]) => {
          const payload = {
            ...archiveConfigurationData.payload,
            subscriberId: archiveConfigurationData.payload.subscriberId
              ? archiveConfigurationData.payload.subscriberId
              : authState.user.SubscriberId,
            userId: authState.user.UserId,
          };
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/ArchiveConfiguration/deactivate`,
              payload,

              {
                // params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ArchiveConfigurationAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Archive configuration] Archive configuration Deactivation Was Successful',
                  });

                  this.store.dispatch(
                    ArchiveConfigurationAction.IsLoading({ payload: true })
                  );

                  this.store.dispatch(
                    ArchiveConfigurationAction.GetArchiveConfigurations({
                      payload: {
                        skip: 0,
                        take: 10,
                      },
                    })
                  );

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Archive configuration] Archive configuration Deactivation Failed',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Archive configuration] Archive configuration Deactivation Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteConfiguration$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ArchiveConfigurationAction.deleteConfiguration),
        withLatestFrom(this.store.select('auth')),
        switchMap(([archiveConfigurationData, authState]) => {
          const payload = {
            ...archiveConfigurationData.payload,
            subscriberId: archiveConfigurationData.payload.subscriberId
              ? archiveConfigurationData.payload.subscriberId
              : authState.user.SubscriberId,
            userId: authState.user.UserId,
          };
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/ArchiveConfiguration/delete`,
              payload,

              {
                // params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ArchiveConfigurationAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Archive configuration] Archive configuration Delete Was Successful',
                  });

                  this.store.dispatch(
                    ArchiveConfigurationAction.IsLoading({ payload: true })
                  );

                  this.store.dispatch(
                    ArchiveConfigurationAction.GetArchiveConfigurations({
                      payload: {
                        skip: 0,
                        take: 10,
                      },
                    })
                  );

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Archive configuration] Archive configuration Delete Failed',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Archive configuration] Archive configuration Delete Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createConfiguration$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ArchiveConfigurationAction.CreateArchiveConfiguration),
        withLatestFrom(this.store.select('auth')),
        switchMap(([archiveConfigurationData, authState]) => {
          const payload = {
            ...archiveConfigurationData.payload,
            subscriberId: archiveConfigurationData.payload.subscriberId
              ? archiveConfigurationData.payload.subscriberId
              : authState.user.SubscriberId,
            userId: authState.user.UserId,
          };
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/ArchiveConfiguration/create`,
              payload,

              {
                // params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ArchiveConfigurationAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Archive configuration] Archive configuration Was Successful Created',
                  });

                  this.store.dispatch(
                    ArchiveConfigurationAction.IsLoading({ payload: true })
                  );

                  this.store.dispatch(
                    ArchiveConfigurationAction.GetArchiveConfigurations({
                      payload: {
                        skip: 0,
                        take: 10,
                      },
                    })
                  );

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Archive configuration] Create Archive configuration  Failed',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Archive configuration] Create Archive configuration  Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateConfiguration$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ArchiveConfigurationAction.UpdateArchiveConfiguration),
        withLatestFrom(this.store.select('auth')),
        switchMap(([archiveConfigurationData, authState]) => {
          const payload = {
            ...archiveConfigurationData.payload,
            subscriberId: archiveConfigurationData.payload.subscriberId
              ? archiveConfigurationData.payload.subscriberId
              : authState.user.SubscriberId,
            userId: authState.user.UserId,
          };
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/ArchiveConfiguration/update`,
              payload,

              {
                // params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  ArchiveConfigurationAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Archive configuration] Archive configuration Was Successful Updated',
                  });

                  this.store.dispatch(
                    ArchiveConfigurationAction.IsLoading({ payload: true })
                  );

                  this.store.dispatch(
                    ArchiveConfigurationAction.GetArchiveConfigurations({
                      payload: {
                        skip: 0,
                        take: 10,
                      },
                    })
                  );

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Archive configuration] Updating Archive configuration  Failed',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Archive configuration] Updating Archive configuration  Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );
}
