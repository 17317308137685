import { createAction, props } from '@ngrx/store';
import { Block, IProjectCollaborators } from '../../interfaces';
import { TemplateFilter } from '../../enums';

export const ResetStore = createAction('[FormBuilder] Reset Store');

export const IsLoading = createAction(
  '[FormBuilder] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const CreateForm = createAction(
  '[FormBuilder] Create Form',
  props<{
    payload: {
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
      objectGuId: string;
      objectName: string;
      name: string;
      description: string;
      projectJson: string;
      isTemplate: boolean;
      projectGuId: string;
      projectTags: string[];
      projectCategoryGuId: string;
      projectType: number;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const UpdateForm = createAction(
  '[FormBuilder] Update Form',
  props<{
    payload: {
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
      objectGuId: string;
      objectName: string;
      projectGuId: string;
      name?: string;
      isTemplate: boolean;
      description: string;
      projectJson: string;
      projectTags: string[];
      projectCategoryGuId: string;
      themeUrl?: string | null;
      themeGuId?: string | null;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const GetFormById = createAction(
  '[FormBuilder] Get Form By Id',
  props<{
    payload: {
      id: string;
    };
  }>()
);

export const recipientGetFormById = createAction(
  '[FormBuilder] Get Recipient Form By Id',
  props<{
    payload: {
      userId: string;
      id: string;
    };
  }>()
);

export const GetInvitedCollaborators = createAction(
  '[FormBuilder] Get Invited Collaborators',
  props<{
    payload: {
      projectguid: string;
    };
  }>()
);

export const submitRecipientForm = createAction(
  '[FormBuilder] Submit Form as Recipient',
  props<{
    payload: {
      jsonData: string;
      projectInstanceGuId: string;
      projectGuId: string;
      responseTime: number;
      userTaskGuid?: string;
      feedbackRating: number;
      comment: string;
    };
  }>()
);

export const SaveInvitedCollaborators = createAction(
  '[FormBuilder] Save Invited Collaborators',
  props<{
    payload: any[];
  }>()
);

export const CreateProjectCollaborators = createAction(
  '[FormBuilder] Create Project Collaborators',
  props<{
    payload: {
      projectGuId: string;
      projectCollaborators: IProjectCollaborators[];
    };
  }>()
);

export const CreateTemplateForm = createAction(
  '[FormBuilder] Create Template Form',
  props<{
    payload: {
      objectGuId: string;
      objectName: string;
      projectGuId: string;
      name: string;
      description: string;
      projectJson: string;
      projectPermission: number;
      projectCategoryGuId: string;
    };
  }>()
);

export const EditTemplateForm = createAction(
  '[FormBuilder] Edit Template Form',
  props<{
    payload: {
      objectGuId: string;
      objectName: string;
      projectGuId: string;
      name: string;
      description: string;
      projectJson: string;
      projectCategoryGuId: string;
    };
  }>()
);

export const DeleteTemplateForm = createAction(
  '[FormBuilder] Delete Template Form',
  props<{
    payload: {
      projectGuId: string;
    };
  }>()
);

export const GetProjectTemplates = createAction(
  '[FormBuilder] Get Project Templates',
  props<{
    payload: {
      skip: number;
      take: number;
      searchTerm?: string;
      filter?: TemplateFilter;
    };
  }>()
);

export const SaveProjectTemplates = createAction(
  '[FormBuilder] Save Project Templates',
  props<{
    payload: any[];
  }>()
);

export const GetProjectCategories = createAction(
  '[FormBuilder] Get Project Categories',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const SaveProjectCategories = createAction(
  '[FormBuilder] Save Project Categories',
  props<{
    payload: any[];
  }>()
);

export const GetProjectCategoryById = createAction(
  '[FormBuilder] Get Project Category By Id',
  props<{
    payload: {
      skip: number;
      take: number;
      id: string;
      searchTerm?: string;
      filter?: TemplateFilter;
    };
  }>()
);

export const CreateTemplateCategory = createAction(
  '[FormBuilder] Create Template Category',
  props<{
    payload: {
      name: string;
      description: string;
      projectCategoryGuid: string;
    };
  }>()
);

export const EditTemplateCategory = createAction(
  '[FormBuilder] Edit Template Category',
  props<{
    payload: {
      name: string;
      description: string;
      projectCategoryGuid: string;
    };
  }>()
);

export const DeleteTemplateCategory = createAction(
  '[FormBuilder] Delete Template Category',
  props<{
    payload: {
      projectCategoryGuid: string;
    };
  }>()
);

export const UploadDocuments = createAction(
  '[FormBuilder] Upload Documents',
  props<{
    payload: {
      FileName: string;
      Extension: string;
      File: File;
    };
    // additionalPayload?: {
    //   bearerToken?: string;
    // };
  }>()
);

export const saveAndPublish = createAction(
  '[FormBuilder] Save And Publish Form',
  props<{
    payload: {
      status: number;
      emails: string;
      projectLink: string;
      projectPublishType: number;
      projectGuId: string;
      startDate?: string;
      endDate?: string;
      time?: string;
      message: string;
      collectEmailAddresses: boolean;
      allowMultipleResponsesPerRecipient: boolean;
    };
  }>()
);

export const createProjectPage = createAction(
  '[FormBuilder] Create Project Page',
  props<{
    payload: {
      name: string;
      jsonData: string;
      guId: string;
      projectGuId: string;
    };
  }>()
);

export const updateProjectPage = createAction(
  '[FormBuilder] Update Project Page',
  props<{
    payload: {
      name: string;
      jsonData: string;
      guId: string;
      projectGuId: string;
    };
  }>()
);

export const deleteProjectPage = createAction(
  '[FormBuilder] Delete Project Page',
  props<{
    id: string;
  }>()
);

export const createProjectPageControl = createAction(
  '[FormBuilder] Create Project Page Control',
  props<{
    payload: {
      name: string;
      jsonData: string;
      guId: string;
      fieldControlType: number;
      fieldControlTypeDesc: string;
      dbStorageMode: number;
      apiFieldName: string;
      projectPageGuid: string;
      isRequired: boolean;
    };
  }>()
);

export const updateProjectPageControl = createAction(
  '[FormBuilder] Update Project Page Control',
  props<{
    payload: {
      name: string;
      jsonData: string;
      guId: string;
      fieldControlType: number;
      fieldControlTypeDesc: string;
      dbStorageMode: number;
      apiFieldName: string;
      projectPageGuid: string;
      isRequired: boolean;
    };
  }>()
);

export const deleteProjectPageControl = createAction(
  '[FormBuilder] Delete Project Page Control',
  props<{
    id: string;
  }>()
);

export const uploadFile = createAction(
  '[FormBuilder] Upload File',
  props<{
    formData: FormData;
  }>()
);

// process

export const IsCreatingProcess = createAction(
  '[FormBuilder] Is Creating Process',
  props<{
    payload: boolean;
  }>()
);

export const CreateProcess = createAction(
  '[FormBuilder] Create Process',
  props<{
    payload: any;
    blocks: Partial<Block>[];
  }>()
);

export const IsGettingProcessCategories = createAction(
  '[FormBuilder] Is Getting Process Categories',
  props<{
    payload: boolean;
  }>()
);

export const GetProcessCategories = createAction(
  '[FormBuilder] Get Process Categories',
  props<{ payload: { skip: number; take: number } }>()
);

export const SaveProcessCategories = createAction(
  '[FormBuilder] Save Process Categories',
  props<{ payload: any }>()
);

export const UpdateBlocks = createAction(
  '[Flowz Designer] Update Blocks',
  props<{
    payload: {
      blocks: Partial<Block>[];
      processGuid: string;
    };
  }>()
);

export const GetApiCollections = createAction(
  '[FormBuilder] Get API Collections',
  props<{
    payload: {
      search?: string;
      skip: number;
      take: number;
    };
  }>()
);

export const GetEndpointsByCollectionId = createAction(
  '[FormBuilder] Get Endpoints By Collection Id',
  props<{
    payload: {
      id: string;
      search?: string;
      skip: number;
      take: number;
    };
  }>()
);

export const GetEndpointDetails = createAction(
  '[FormBuilder] Get Endpoint Details',
  props<{
    id: string;
  }>()
);

export const VerifyControlContent = createAction(
  '[FormBuilder] Verify Control Content',
  props<{
    payload: {
      verificationUrl: string;
      headers: { [key: string]: string };
      apiType: number;
      body: any;
    };
  }>()
);

export const GetThemes = createAction(
  '[FormBuilder] Get Themes',
  props<{
    payload: {
      skip: number;
      take: number;
      filter?: number;
    };
  }>()
);

export const UploadTheme = createAction(
  '[FormBuilder] Upload Theme',
  props<{
    payload: {
      ThemeName: string;
      ThemeAccessLevel: number;
      ThemeType: number;
      ThemeImage: FormData;
    };
  }>()
);

export const CloneTemplate = createAction(
  '[FormBuilder] Clone Template',
  props<{
    payload: {
      name: string;
      projectGuId: string;
    };
  }>()
);

export const DeleteTheme = createAction(
  '[FormBuilder] Delete Theme',
  props<{
    guid: string;
  }>()
);

export const DuplicatePage = createAction(
  '[FormBuilder] Duplicate Page',
  props<{
    payload: { name: string; guId: string };
  }>()
);
