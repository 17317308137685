<ng-container *ngIf="(isAuthLoading | async) !== false">
  <div class="general-loader-container">
    <div class="general-loader-backdrop"></div>

    <div class="general-loader-content">
      <app-general-loader></app-general-loader>
    </div>
  </div>
</ng-container>

<!-- Test Cases -->

<section id="addition-info">
  <div class="space-fill"></div>

  <div class="login-page-content-inner">
    <div class="logo-section">
      <div>
        <img
          src="/assets/logo/flowmono-logo.svg"
          alt="Flowmono Logo White"
          class="logo"
        />
      </div>

      <div
        *ngIf="registrationForm.get('firstName')?.value"
        class="text-capitalize fw-600"
      >
        Hi,
        {{ registrationForm.get('firstName')?.value }}
        {{ registrationForm.get('lastName')?.value }}
      </div>
    </div>

    <div class="auth-login-form-container" [hidden]="page !== 1">
      <div class="welcome-message">
        <ng-container *ngIf="page === 1">
          <p class="flwmn-heading-4 flwmn-text-black fw-700">
            You’re almost there!
          </p>
        </ng-container>
      </div>

      <div class="auth-form-container">
        <form [formGroup]="registrationForm">
          <div class="registration-form-page-1">
            <div class="registration-form-row">
              <div class="input-style1 input-md input-style-rounded-edge">
                <label class="input-label">First name</label>

                <mat-form-field appearance="outline" [style.width.%]="100">
                  <input type="text" matInput formControlName="firstName" />

                  <mat-error *ngIf="registrationFormControls.firstName.invalid">
                    <img
                      src="/assets/icons/error.svg"
                      alt=""
                      class="img-fluid error-icon"
                    />
                    {{ getErrorMessage('firstName') }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="input-style1 input-md input-style-rounded-edge">
                <label class="input-label">Last name</label>

                <mat-form-field appearance="outline" [style.width.%]="100">
                  <input type="text" matInput formControlName="lastName" />

                  <mat-error *ngIf="registrationFormControls.lastName.invalid">
                    <img
                      src="/assets/icons/error.svg"
                      alt=""
                      class="img-fluid error-icon"
                    />
                    {{ getErrorMessage('lastName') }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div
              class="input-style1 input-md input-style-rounded-edge"
              *ngIf="!isUserInvited"
            >
              <label class="input-label">Company name</label>

              <mat-form-field appearance="outline" [style.width.%]="100">
                <input
                  #email
                  type="text"
                  matInput
                  formControlName="company_name"
                  (input)="
                    registrationForm.patchValue({
                      company_name: email.value.replaceAll(' ', '')
                    })
                  "
                />

                <mat-error
                  *ngIf="registrationFormControls.company_name.invalid"
                >
                  <img
                    src="/assets/icons/error.svg"
                    alt=""
                    class="img-fluid error-icon"
                  />
                  {{ getErrorMessage('company_name') }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="input-style1 input-md input-style-rounded-edge">
              <label class="input-label">Contact number</label>

              <mat-form-field appearance="outline" [style.width.%]="100">
                <input
                  #individualPhoneNumber
                  type="tel"
                  inputmode="tel"
                  matInput
                  formControlName="phone_number"
                  (input)="
                    registrationForm.patchValue({
                      phone_number: individualPhoneNumber.value.replaceAll(
                        ' ',
                        ''
                      )
                    })
                  "
                />
                <mat-error
                  *ngIf="registrationFormControls.phone_number.invalid"
                >
                  <img
                    src="/assets/icons/error.svg"
                    alt=""
                    class="img-fluid error-icon"
                  />
                  {{ getErrorMessage('phone_number') }}
                </mat-error>
              </mat-form-field>
            </div>

            <ng-template #visibleEyeIcon>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3866 8.00001C10.3866 9.32001 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C9.31995 5.61334 10.3866 6.68001 10.3866 8.00001Z"
                  stroke="var(--flwmn-primary-color)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
                  stroke="var(--flwmn-primary-color)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </ng-template>

            <ng-template #hiddenEyeIcon>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.68661 6.31334L6.31328 9.68668C5.87995 9.25334 5.61328 8.66001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C8.65995 5.61334 9.25328 5.88001 9.68661 6.31334Z"
                  stroke="var(--flwmn-primary-color)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.8799 3.84669C10.7132 2.96669 9.3799 2.48669 7.9999 2.48669C5.64656 2.48669 3.45323 3.87336 1.92656 6.27336C1.32656 7.21336 1.32656 8.79336 1.92656 9.73336C2.45323 10.56 3.06656 11.2734 3.73323 11.8467"
                  stroke="var(--flwmn-primary-color)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.61328 13.02C6.37328 13.34 7.17995 13.5133 7.99995 13.5133C10.3533 13.5133 12.5466 12.1267 14.0733 9.72668C14.6733 8.78668 14.6733 7.20668 14.0733 6.26668C13.8533 5.92002 13.6133 5.59335 13.3666 5.28668"
                  stroke="var(--flwmn-primary-color)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.34 8.46667C10.1666 9.40668 9.39996 10.1733 8.45996 10.3467"
                  stroke="var(--flwmn-primary-color)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.31325 9.68665L1.33325 14.6666"
                  stroke="var(--flwmn-primary-color)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.6668 1.33331L9.68677 6.31331"
                  stroke="var(--flwmn-primary-color)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </ng-template>

            <div class="input-style1 input-md input-style-rounded-edge">
              <label class="input-label">Password</label>

              <mat-form-field appearance="outline" [style.width.%]="100">
                <input
                  matInput
                  [type]="show ? 'text' : 'password'"
                  formControlName="password"
                />

                <span matSuffix class="img-fluid" (click)="show = !show">
                  <ng-container *ngIf="show">
                    <ng-container
                      *ngTemplateOutlet="visibleEyeIcon"
                    ></ng-container>
                  </ng-container>

                  <ng-container *ngIf="!show">
                    <ng-container
                      *ngTemplateOutlet="hiddenEyeIcon"
                    ></ng-container>
                  </ng-container>
                </span>

                <mat-error *ngIf="registrationFormControls.password.invalid">
                  <img
                    src="/assets/icons/error.svg"
                    alt=""
                    class="img-fluid error-icon"
                  />
                  {{ getErrorMessage('password') }}
                </mat-error>
              </mat-form-field>

              <div class="check-box-wrapper mb-3">
                <div class="password-check">
                  <span class="check-container">
                    <span
                      class="box-check"
                      [ngClass]="
                        uppercasePattern.test(passwordValue) &&
                        lowercasePattern.test(passwordValue)
                          ? 'check'
                          : ''
                      "
                    ></span>
                  </span>

                  <span>
                    <p class="flwmn-para-1 py-0 my-0">
                      Includes both lowercase (a-z) and uppercase (A-Z) letters
                    </p>
                  </span>
                </div>

                <div class="password-check">
                  <span class="check-container">
                    <span
                      class="box-check"
                      [ngClass]="
                        numberPattern.test(passwordValue) ? 'check' : ''
                      "
                    ></span>
                  </span>

                  <span>
                    <p class="flwmn-para-1 py-0 my-0">
                      At least one numeric digit
                    </p>
                  </span>
                </div>

                <div class="password-check">
                  <span class="check-container">
                    <span
                      class="box-check"
                      [ngClass]="
                        specialCharPattern.test(passwordValue) ? 'check' : ''
                      "
                    ></span>
                  </span>

                  <span>
                    <p class="flwmn-para-1 py-0 my-0">
                      Minimum of 1 Special character
                    </p>
                  </span>
                </div>

                <div class="password-check">
                  <span class="check-container">
                    <span
                      class="box-check"
                      [ngClass]="passwordValue?.length > 7 ? 'check' : ''"
                    ></span>
                  </span>

                  <span>
                    <p class="flwmn-para-1 py-0 my-0">
                      Minimum of 8 Characters
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <ng-template #visibleEyeIcon>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3866 8.00001C10.3866 9.32001 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C9.31995 5.61334 10.3866 6.68001 10.3866 8.00001Z"
                stroke="var(--flwmn-primary-color)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
                stroke="var(--flwmn-primary-color)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </ng-template>

          <ng-template #hiddenEyeIcon>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.68661 6.31334L6.31328 9.68668C5.87995 9.25334 5.61328 8.66001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C8.65995 5.61334 9.25328 5.88001 9.68661 6.31334Z"
                stroke="var(--flwmn-primary-color)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.8799 3.84669C10.7132 2.96669 9.3799 2.48669 7.9999 2.48669C5.64656 2.48669 3.45323 3.87336 1.92656 6.27336C1.32656 7.21336 1.32656 8.79336 1.92656 9.73336C2.45323 10.56 3.06656 11.2734 3.73323 11.8467"
                stroke="var(--flwmn-primary-color)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.61328 13.02C6.37328 13.34 7.17995 13.5133 7.99995 13.5133C10.3533 13.5133 12.5466 12.1267 14.0733 9.72668C14.6733 8.78668 14.6733 7.20668 14.0733 6.26668C13.8533 5.92002 13.6133 5.59335 13.3666 5.28668"
                stroke="var(--flwmn-primary-color)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.34 8.46667C10.1666 9.40668 9.39996 10.1733 8.45996 10.3467"
                stroke="var(--flwmn-primary-color)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.31325 9.68665L1.33325 14.6666"
                stroke="var(--flwmn-primary-color)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.6668 1.33331L9.68677 6.31331"
                stroke="var(--flwmn-primary-color)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </ng-template>

          <div class="w-100 d-flex justify-content-end">
            <button
              matRipple
              type="button"
              class="btn flwmn-btn-linear-gradient flwmn-btn-md login-btn mt-3 ms-auto"
              [disabled]="registrationForm.invalid"
              (click)="skipInvite()"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="invites-container" [hidden]="page !== 1.5">
      <div class="welcome-message">
        <p class="flwmn-heading-4 flwmn-text-black fw-700 text-center">
          Invite your team
        </p>

        <p class="flwmn-para-3 flwmn-text-black fw-400 text-center">
          You have created a new account, invite your team to enjoy seamless
          collaboration.
        </p>
      </div>

      <div class="infite-form-container">
        <form [formGroup]="inviteForm">
          <div class="invite-container">
            <div class="input-style1 input-md input-style-rounded-edge">
              <label class="input-label">Specify users by email address</label>

              <mat-form-field appearance="outline" [style.width.%]="100">
                <input
                  #email
                  type="email"
                  inputmode="email"
                  matInput
                  formControlName="email"
                  placeholder="press enter to add email"
                  (keydown.enter)="onEnterPress($event)"
                />
              </mat-form-field>
            </div>

            <div
              class="email-container"
              formArrayName="invitedUsers"
              *ngFor="
                let invitedUser of invitedUserArray.controls;
                let i = index
              "
            >
              <div class="added-email" [formGroupName]="i">
                <div class="initials">
                  <span class="initial">{{
                    invitedUser.value.email[0] + invitedUser.value.email[1]
                  }}</span>
                  <span class="flwmn-para-1 flwmn-text-black text-truncate">{{
                    invitedUser.value.email
                  }}</span>
                </div>

                <div class="role-container d-flex align-items-center gap-2">
                  <div class="role-selectors">
                    <div class="input-style1 input-style-rounded-edge">
                      <label class="input-label">Role</label>

                      <mat-form-field
                        appearance="outline"
                        [style.width.%]="100"
                      >
                        <mat-select formControlName="role">
                          <ng-container
                            *ngIf="
                              allRoles !== null && allRoles?.length !== 0;
                              else noRolesByEmail
                            "
                          >
                            <ng-container *ngFor="let role of allRoles">
                              <mat-option [value]="role.id">
                                {{ role?.name }}
                              </mat-option>
                            </ng-container>
                          </ng-container>

                          <ng-template #noRolesByEmail>
                            <ng-container *ngIf="allRoles === null">
                              <mat-option disabled>
                                Loading roles...</mat-option
                              >
                            </ng-container>

                            <ng-container *ngIf="allRoles?.length === 0">
                              <mat-option disabled>
                                No role is available</mat-option
                              >
                            </ng-container>
                          </ng-template>
                        </mat-select>

                        <mat-error
                          *ngIf="
                            invitedUserArray.controls[i].get('role')!.errors
                              ?.required
                          "
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          Required
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="delete-icon" role="button">
                    <app-svg
                      matRipple
                      (click)="removeUserFromSpecifiedUsersArray(i)"
                      src="assets/icons/delete.svg"
                      svgPathStroke="#ec5779"
                    ></app-svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="my-5">
            <button
              matRipple
              type="submit"
              class="btn flwmn-btn-primary-outline flwmn-btn-md flwmn-btn-full login-btn rounded-2"
              [disabled]="
                inviteForm.invalid || invitedUserArray.value.length === 0
              "
            >
              Send Invite
            </button>
          </div> -->
        </form>

        <div class="p-3">
          <mat-progress-bar mode="determinate" value="10"></mat-progress-bar>
        </div>

        <div class="w-100 d-flex justify-content-between my-4">
          <button
            matRipple
            type="submit"
            class="btn flwmn-btn-primary-outline flwmn-btn-md"
            (click)="changePage(1)"
          >
            Back
          </button>

          <button
            matRipple
            type="submit"
            class="btn flwmn-btn-primary flwmn-btn-md"
            [disabled]="invitedUserArray.invalid"
            (click)="changePage(2)"
          >
            {{
              invitedUserArray.value.length === 0 ? ' I’d do it later' : 'Next'
            }}
          </button>
        </div>
      </div>
    </div>

    <!-- Reason -->
    <div class="reason-container" [hidden]="page !== 2">
      <p class="flwmn-heading-4 flwmn-text-black fw-700">
        What would you like to use flowmono for?
      </p>

      <div class="radio-options-container">
        <mat-radio-group
          aria-label="Select an option"
          [(ngModel)]="usageType"
          color="primary"
        >
          <div class="options-container">
            <span class="radios-option">
              <mat-radio-button value="0">Work</mat-radio-button>
            </span>

            <span class="radios-option">
              <mat-radio-button value="1">Personal</mat-radio-button>
            </span>
          </div>
        </mat-radio-group>
      </div>

      <mat-progress-bar mode="determinate" value="10"></mat-progress-bar>

      <div class="w-100 d-flex justify-content-between">
        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary-outline flwmn-btn-md"
          (click)="backToFirst()"
        >
          Back
        </button>

        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary-outline flwmn-btn-md"
          [disabled]="!usageType"
          (click)="nextBaseOnUsageType()"
        >
          Next
        </button>
      </div>
    </div>

    <!-- Organisation Size -->
    <div class="reason-container" [hidden]="page !== 2.5">
      <p class="flwmn-heading-4 flwmn-text-black fw-700">
        How large is your organization?
      </p>

      <div class="radio-options-container">
        <mat-radio-group
          aria-label="Select an option"
          [(ngModel)]="organizationSize"
          color="primary"
        >
          <div class="options-container">
            <span class="radios-option">
              <mat-radio-button value="1-10">1-10</mat-radio-button>
            </span>

            <span class="radios-option">
              <mat-radio-button value="11-25">11-25</mat-radio-button>
            </span>

            <span class="radios-option">
              <mat-radio-button value="25-500">26-500</mat-radio-button>
            </span>

            <span class="radios-option">
              <mat-radio-button value="501-2000">501-2000</mat-radio-button>
            </span>

            <span class="radios-option">
              <mat-radio-button value="2001+">2001+</mat-radio-button>
            </span>

            <span class="radios-option">
              <mat-radio-button value="I'm not sure"
                >I'm not sure</mat-radio-button
              >
            </span>
          </div>
        </mat-radio-group>
      </div>

      <mat-progress-bar mode="determinate" value="20"></mat-progress-bar>

      <div class="w-100 d-flex justify-content-between">
        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary-outline flwmn-btn-md"
          (click)="BackAfterHowLargeQuestion()"
        >
          Back
        </button>

        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary-outline flwmn-btn-md"
          [disabled]="!organizationSize"
          (click)="changePage(3)"
        >
          Next
        </button>
      </div>
    </div>

    <!-- Role -->
    <div class="reason-container" [hidden]="page !== 3">
      <p class="flwmn-heading-4 flwmn-text-black fw-700">What is your role ?</p>

      <div class="radio-options-container">
        <mat-radio-group
          aria-label="Select an option"
          [(ngModel)]="creatorRole"
          color="primary"
        >
          <div class="options-container">
            <span class="radios-option">
              <mat-radio-button value="Owner">Owner</mat-radio-button>
            </span>

            <span class="radios-option">
              <mat-radio-button value="C-Suite">C-Suite</mat-radio-button>
            </span>

            <span class="radios-option">
              <mat-radio-button value="Vice President"
                >Vice President</mat-radio-button
              >
            </span>

            <span class="radios-option">
              <mat-radio-button value="Director">Director</mat-radio-button>
            </span>

            <span class="radios-option">
              <mat-radio-button value="Team Leader"
                >Team Leader</mat-radio-button
              >
            </span>

            <span class="radios-option">
              <mat-radio-button value="Team Member"
                >Team Member</mat-radio-button
              >
            </span>

            <span class="radios-option">
              <mat-radio-button value="Freelancer">Freelancer</mat-radio-button>
            </span>

            <span class="radios-option">
              <mat-radio-button value="Other">Other</mat-radio-button>
            </span>
          </div>
        </mat-radio-group>
      </div>
      <mat-progress-bar mode="determinate" value="30"></mat-progress-bar>

      <div class="w-100 d-flex justify-content-between">
        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary-outline flwmn-btn-md"
          (click)="changePage(2.5)"
        >
          Back
        </button>

        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary-outline flwmn-btn-md"
          [disabled]="!creatorRole"
          (click)="changePage(3.5)"
        >
          Next
        </button>
      </div>
    </div>

    <!-- Hear About Us -->
    <div class="reason-container" [hidden]="page !== 3.5">
      <p class="flwmn-heading-4 flwmn-text-black fw-700">
        How did you hear about us?
      </p>

      <div class="radio-options-container">
        <mat-radio-group
          aria-label="Select an option"
          [(ngModel)]="howYouHeard"
          color="primary"
        >
          <div class="options-container">
            <span class="radios-option">
              <mat-radio-button [value]="referralTypes.SearchEngines"
                >Search Engine (Google, Bing etc)</mat-radio-button
              >
            </span>

            <span class="radios-option">
              <mat-radio-button [value]="referralTypes.SocialMedia"
                >Facebook/Instagram/X</mat-radio-button
              >
            </span>

            <span class="radios-option">
              <mat-radio-button [value]="referralTypes.Quora"
                >Quora</mat-radio-button
              >
            </span>

            <span class="radios-option">
              <mat-radio-button [value]="referralTypes.Friends"
                >Friend/Colleague</mat-radio-button
              >
            </span>

            <span class="radios-option">
              <mat-radio-button [value]="referralTypes.BillBoard"
                >Billboard</mat-radio-button
              >
            </span>

            <span class="radios-option">
              <mat-radio-button [value]="referralTypes.BlogsAndArticles"
                >Blogs and Articles</mat-radio-button
              >
            </span>

            <span class="radios-option">
              <mat-radio-button [value]="referralTypes.Others"
                >Other</mat-radio-button
              >
            </span>
          </div>
        </mat-radio-group>
      </div>

      <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>

      <div class="w-100 d-flex justify-content-between">
        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary-outline flwmn-btn-md"
          (click)="backBasedOnUsageType()"
        >
          Back
        </button>

        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary-outline flwmn-btn-md"
          [disabled]="!howYouHeard"
          (click)="changePage(4)"
        >
          Next
        </button>
      </div>
    </div>

    <!-- Tools -->
    <div class="reason-container" [hidden]="page !== 4">
      <p class="flwmn-heading-4 flwmn-text-black fw-700">
        Do you use any of these tools?
      </p>

      <div class="radio-options-container">
        <div class="options-container">
          <span class="radios-option">
            <mat-checkbox color="primary" (click)="setToolsUsed('DocuSign')"
              >DocuSign</mat-checkbox
            >
          </span>

          <span class="radios-option">
            <mat-checkbox color="primary" (click)="setToolsUsed('Adobe Sign')"
              >Adobe Sign</mat-checkbox
            >
          </span>

          <span class="radios-option">
            <mat-checkbox color="primary" (click)="setToolsUsed('Zoho')"
              >Zoho</mat-checkbox
            >
          </span>

          <span class="radios-option">
            <mat-checkbox color="primary" (click)="setToolsUsed('K2')"
              >K2</mat-checkbox
            >
          </span>

          <span class="radios-option">
            <mat-checkbox color="primary" (click)="setToolsUsed('Kissflow')"
              >Kissflow</mat-checkbox
            >
          </span>

          <span class="radios-option">
            <mat-checkbox color="primary" (click)="setToolsUsed('ProcessMaker')"
              >ProcessMaker</mat-checkbox
            >
          </span>
        </div>
      </div>

      <mat-progress-bar mode="determinate" value="70"></mat-progress-bar>

      <div class="w-100 d-flex justify-content-between">
        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary-outline flwmn-btn-md"
          (click)="checkInviteStatusBeforeBacking()"
        >
          Back
        </button>

        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary-outline flwmn-btn-md"
          (click)="changePage(4.5)"
        >
          Next
        </button>
      </div>
    </div>

    <!-- New Feature Interest -->
    <div class="reason-container" [hidden]="page !== 4.5">
      <p class="flwmn-heading-4 flwmn-text-black fw-700">
        Which features are you interested in trying?
      </p>

      <div class="radio-options-container">
        <div class="options-container">
          <span class="radios-option">
            <mat-checkbox
              color="primary"
              (click)="setInterestedFeatures('E-signature')"
              >E-signature</mat-checkbox
            >
          </span>

          <span class="radios-option">
            <mat-checkbox
              color="primary"
              (click)="setInterestedFeatures('Document Management')"
              >Document Management</mat-checkbox
            >
          </span>

          <span class="radios-option">
            <mat-checkbox
              color="primary"
              (click)="setInterestedFeatures('Form Builder')"
              >Form Builder</mat-checkbox
            >
          </span>

          <span class="radios-option">
            <mat-checkbox
              color="primary"
              (click)="setInterestedFeatures('Document Workflow')"
              >Document Workflow</mat-checkbox
            >
          </span>

          <span class="radios-option">
            <mat-checkbox
              color="primary"
              (click)="setInterestedFeatures('AI Assistant')"
              >AI Assistant</mat-checkbox
            >
          </span>

          <span class="radios-option">
            <mat-checkbox
              color="primary"
              (click)="setInterestedFeatures('SLA Management')"
              >SLA Management</mat-checkbox
            >
          </span>

          <span class="radios-option">
            <mat-checkbox
              color="primary"
              (click)="setInterestedFeatures('Bussiness Process Management')"
              >Business Process Management</mat-checkbox
            >
          </span>
        </div>
      </div>

      <mat-progress-bar mode="determinate" value="99"></mat-progress-bar>

      <div class="w-100 d-flex justify-content-between">
        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary-outline flwmn-btn-md"
          (click)="changePage(4)"
        >
          Back
        </button>

        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary flwmn-btn-md"
          (click)="onSubmit()"
        >
          Finish
        </button>
      </div>
    </div>

    <!-- Theme Section -->
    <!-- <div class="reason-container" [hidden]="page !== 5">
      <p class="flwmn-heading-4 flwmn-text-black fw-700">
        Lastly, setup your profile picture and theme.
      </p>

      <div class="radio-options-container">
        <div class="profile-theme-container">
          <div class="profile-picture-section">
            <div
              class="profile-picture-container"
              matRipple
              (click)="profilePictureUpload.click()"
            >
              <div
                class="edit-container"
                [ngClass]="{ 'd-none': profilePicturePreview }"
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3510_124323)">
                    <path
                      d="M30.9354 7.33094C30.2966 6.66174 29.4145 6.2663 28.4106 6.2663H23.3612V6.20546C23.3612 5.44501 23.057 4.71497 22.5399 4.22828C22.0228 3.71116 21.3232 3.40698 20.5627 3.40698H11.4373C10.6464 3.40698 9.94677 3.71116 9.42966 4.22828C8.91255 4.74539 8.60837 5.44501 8.60837 6.20546V6.2663H3.58935C2.58555 6.2663 1.70342 6.66174 1.06464 7.33094C0.425856 7.96972 0 8.88227 0 9.85565V25.0039C0 26.0077 0.395437 26.8899 1.06464 27.5287C1.70342 28.1674 2.61597 28.5933 3.58935 28.5933H28.4106C29.4145 28.5933 30.2966 28.1979 30.9354 27.5287C31.5741 26.8899 32 25.9773 32 25.0039V9.85565C32 8.85185 31.6046 7.96972 30.9354 7.33094ZM30.4183 25.0039H30.3878C30.3878 25.5515 30.1749 26.0382 29.8099 26.4032C29.4449 26.7682 28.9582 26.9811 28.4106 26.9811H3.58935C3.04183 26.9811 2.55513 26.7682 2.19011 26.4032C1.8251 26.0382 1.61217 25.5515 1.61217 25.0039V9.85565C1.61217 9.30812 1.8251 8.82143 2.19011 8.45641C2.55513 8.09139 3.04183 7.87847 3.58935 7.87847H9.46008C9.91635 7.87847 10.2814 7.51345 10.2814 7.05717V6.17504C10.2814 5.84044 10.403 5.53626 10.616 5.32333C10.8289 5.1104 11.1331 4.98873 11.4677 4.98873H20.5627C20.8973 4.98873 21.2015 5.1104 21.4144 5.32333C21.6274 5.53626 21.7491 5.84044 21.7491 6.17504V7.05717C21.7491 7.51345 22.1141 7.87847 22.5703 7.87847H28.4411C28.9886 7.87847 29.4753 8.09139 29.8403 8.45641C30.2053 8.82143 30.4183 9.30812 30.4183 9.85565V25.0039Z"
                      fill="white"
                    />
                    <path
                      d="M16.0005 9.94702C13.932 9.94702 12.0461 10.7987 10.7077 12.1371C9.33887 13.506 8.51758 15.3615 8.51758 17.4299C8.51758 19.4984 9.36929 21.3843 10.7077 22.7227C12.0765 24.0915 13.932 24.9128 16.0005 24.9128C18.0689 24.9128 19.9548 24.0611 21.2932 22.7227C22.6621 21.3539 23.4834 19.4984 23.4834 17.4299C23.4834 15.3615 22.6316 13.4755 21.2932 12.1371C19.9548 10.7987 18.0689 9.94702 16.0005 9.94702ZM20.1373 21.5972C19.0727 22.6314 17.6126 23.3006 16.0005 23.3006C14.3883 23.3006 12.9282 22.6314 11.8636 21.5972C10.7989 20.5326 10.1602 19.0725 10.1602 17.4603C10.1602 15.8482 10.8294 14.3881 11.8636 13.3234C12.9282 12.2588 14.3883 11.62 16.0005 11.62C17.6126 11.62 19.0727 12.2892 20.1373 13.3234C21.202 14.3881 21.8408 15.8482 21.8408 17.4603C21.8712 19.0725 21.202 20.5326 20.1373 21.5972Z"
                      fill="white"
                    />
                    <path
                      d="M26.8284 12.8975C27.6516 12.8975 28.3189 12.2302 28.3189 11.407C28.3189 10.5838 27.6516 9.9165 26.8284 9.9165C26.0052 9.9165 25.3379 10.5838 25.3379 11.407C25.3379 12.2302 26.0052 12.8975 26.8284 12.8975Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3510_124323">
                      <rect width="32" height="32" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <p class="flwmn-caption no-select">Upload here</p>
              </div>

              <img
                [src]="profilePicturePreview"
                alt=""
                class="profile-picture img-fluid"
              />
            </div>

            <input
              #profilePictureUpload
              type="file"
              accept="image/*"
              name=""
              class="d-none"
              (change)="onUploadProfilePicture($event)"
            />
          </div>

          <div class="theme-container">
            <div
              matRipple
              (click)="onSelectTheme($event)"
              data-theme="theme1"
              class="theme theme1"
              [ngClass]="{
                selected: selectedTheme === 'theme1'
              }"
            ></div>

            <div
              matRipple
              (click)="onSelectTheme($event)"
              data-theme="theme2"
              class="theme theme2"
              [ngClass]="{
                selected: selectedTheme === 'theme2'
              }"
            ></div>

            <div
              matRipple
              (click)="onSelectTheme($event)"
              data-theme="theme3"
              class="theme theme3"
              [ngClass]="{
                selected: selectedTheme === 'theme3'
              }"
            ></div>

            <div
              matRipple
              (click)="onSelectTheme($event)"
              data-theme="theme4"
              class="theme theme4"
              [ngClass]="{
                selected: selectedTheme === 'theme4'
              }"
            ></div>

            <div
              matRipple
              (click)="onSelectTheme($event)"
              data-theme="theme5"
              class="theme theme5"
              [ngClass]="{
                selected: selectedTheme === 'theme5'
              }"
            ></div>

            <div
              matRipple
              (click)="onSelectTheme($event)"
              data-theme="theme6"
              class="theme theme6"
              [ngClass]="{
                selected: selectedTheme === 'theme6'
              }"
            ></div>

            <div
              matRipple
              (click)="onSelectTheme($event)"
              data-theme="theme7"
              class="theme theme7"
              [ngClass]="{
                selected: selectedTheme === 'theme7'
              }"
            ></div>
          </div>
        </div>
      </div>

      <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>

      <div class="w-100 d-flex justify-content-between">
        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary-outline flwmn-btn-md"
          (click)="changePage(4.5)"
        >
          Back
        </button>

        <button
          matRipple
          type="submit"
          class="btn flwmn-btn-primary-outline flwmn-btn-md"
          (click)="onSubmit()"
        >
          Finish
        </button>
      </div>
    </div> -->
  </div>

  <div class="space-fill"></div>
</section>
