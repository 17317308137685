import { createAction, props } from '@ngrx/store';

import { flowmonoAssistanceReducerState } from './flowmono-assistant.reducer';

export const ResetStore = createAction('[Flowmono Assistance] Reset Store');

export const IsLoading = createAction(
  '[Flowmono Assistance] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const FileLoading = createAction(
  '[Flowmono Assistance] Processing File',
  props<{
    payload: boolean;
  }>()
);

export const ChatLoading = createAction(
  '[Flowmono Assistance]  Chat Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetDocumentInsight = createAction(
  '[Flowmono Assistance] Get Document Insight',
  props<{
    payload: {
      documentId?: number;
    };
  }>()
);

export const SendTaskId = createAction(
  '[Flowmono Assistance] Send Task Id',
  props<{
    payload: {
      taskId: string;
    };
  }>()
);

export const SaveDocumentInsight = createAction(
  '[Flowmono Assistance] Save Document Insight',
  props<{
    payload: any;
  }>()
);

export const GetDocumentSummary = createAction(
  '[Flowmono Assistance] Get Document Summary',
  props<{
    payload: {
      documentId: number;
    };
  }>()
);

export const SaveDocumentSummary = createAction(
  '[Flowmono Assistance] Save Document Summary',
  props<{
    payload: flowmonoAssistanceReducerState['documentSummary'];
  }>()
);

/* Chat history */
export const GetChatHistory = createAction(
  '[Flowmono Assistance] Get Chat History',
  props<{
    payload: {
      documentId?: number;
      isGuest: boolean;
      userEmailAddress: string;
      userId?: string;
    };
  }>()
);

export const SaveChatHistory = createAction(
  '[Flowmono Assistance] Save Chat History',
  props<{
    payload: flowmonoAssistanceReducerState['chatHistory'];
  }>()
);

export const CreateFeedback = createAction(
  '[Flowmono Assistance] Create Feedback',
  props<{
    payload: {
      objectGuid?: string;
      objectName?: string;
      comment: string;
      feedbackRating: number;
      feedbackType: number;
    };
  }>()
);

export const IngestFile = createAction(
  '[Flowmono Assistance] Ingest File',
  props<{
    payload: {
      documentId?: number;
      isDocumentReplaced: boolean;
      userEmailAddress?: string;
      isGuest: boolean;
    };
  }>()
);

export const CreateQuestion = createAction(
  '[Flowmono Assistance] Question and Answer',
  props<{
    payload: {
      question?: string;
      documentId?: number;
      userFirstName: string;
      userEmailAddress: string;
      isGuest: boolean;
      subscriberId: number;
    };
  }>()
);

export const SaveQuestion = createAction(
  '[Flowmono Assistance] Save Question and Answer',
  props<{
    payload: flowmonoAssistanceReducerState['questionAndAnswer'];
  }>()
);
