import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';
import { Observable, Subscription } from 'rxjs';
import { HelperService } from 'src/app/@core/services/helper.service';
import { Notification } from 'src/app/@core/interfaces';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AuthEffects } from 'src/app/@core/stores/auth/auth.effects';

@Component({
  selector: 'app-device-verification',
  templateUrl: './device-verification.component.html',
  styleUrls: ['./device-verification.component.scss'],
})
export class DeviceVerificationComponent implements OnInit {
  isLoading$!: Observable<boolean>;
  year!: number;
  guid: string | null = null;
  userId: string | null = null;
  feedback: string | null = null;
  acceptStatus: boolean = false;
  continueToDashboardLoader: boolean = false;
  showContinueToDashboard: boolean = false;
  time: string;
  OnyxDocData: {
    accessToken: string;
    refreshToken: string;
    userToken: string;
    exp: number;
  } | null;

  rejectStatus: boolean = false;

  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private helperService: HelperService,
    private notificationService: NotificationService,
    private authEffect: AuthEffects
  ) {
    const queryParams = this.route.snapshot.queryParams;

    if (queryParams.hash) {
      const decryptedHash = this.helperService.decryptData(
        this.helperService.decodeAndReplaceSpaceWithPlus(queryParams.hash)
      );

      this.guid = decryptedHash.split('&')[0].split('=')[1];

      this.userId = decryptedHash.split('&')[1].split('=')[1];

      this.time = decryptedHash.split('&')[2].split('=')[1]?.replace(/'/g, '');

      this.feedback = decryptedHash.split('&')[3].split('=')[1];

      if (!this.guid || !this.userId || !this.feedback) {
        this.getInvalidLinkNotification();
      }
    } else {
      this.getInvalidLinkNotification();
    }
  }

  ngOnInit() {
    this.isLoading$ = this.store.pipe(select(authSelectors.getAuthIsLoading));

    this.year = new Date().getFullYear();

    // this.guid = this.route.snapshot.queryParams.guid;

    // this.userId = this.route.snapshot.queryParams.userId;

    // this.feedback = this.route.snapshot.queryParams.feedback;
    this.store.dispatch(AuthActions.InitializeApp_DeveloperToken());

    this.acceptOrRectDevice();

    this.listenToAcceptDeviceEffect();

    this.listenToRejectDeviceEffect();
  }

  acceptOrRectDevice() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    const interval = setInterval(() => {
      if (localStorage.getItem('OnyxDoc_auth') !== null) {
        clearInterval(interval);
        if (this.feedback === 'accept') {
          this.onAcceptDevice();
        } else {
          this.onRejectDevice();
        }
      }
    }, 3000);
  }

  onAcceptDevice() {
    // this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.acceptDevice.bind(this)
    );
  }

  acceptDevice() {
    this.store.dispatch(
      AuthActions.AcceptDevice({
        payload: {
          guid: this.guid,
          userId: this.userId,
          time: this.time ?? null,
        },
      })
    );
  }

  onRejectDevice() {
    // this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.rejectDevice.bind(this)
    );
  }

  rejectDevice() {
    this.store.dispatch(
      AuthActions.RejectDevice({
        payload: {
          guid: this.guid,
          userId: this.userId,
          time: this.time ?? null,
        },
      })
    );
  }

  listenToAcceptDeviceEffect() {
    this.subscription.add(
      this.authEffect.acceptDevice$.subscribe((res: any) => {
        if (res.isSuccess && (res.message || res.messages[0])) {
          this.acceptStatus = true;
          this.store.dispatch(AuthActions.IsLoading({ payload: false }));
          this.getSuccessNotification(res);
        } else {
          this.getInvalidLinkNotification();
        }
      })
    );
  }

  listenToRejectDeviceEffect() {
    this.subscription.add(
      this.authEffect.rejectDevice$.subscribe((res: any) => {
        if (res.succeeded && (res.message || res.messages[0])) {
          this.rejectStatus = true;
        } else {
          this.getInvalidLinkNotification();
        }
      })
    );
  }

  getInvalidLinkNotification() {
    const notification: Notification = {
      state: 'error',
      message: 'This link is invalid. Please check the URL and try again.',
    };

    this.notificationService.openNotification(
      notification,
      'flwmn-notification-error'
    );

    this.router.navigate(['/']);
  }

  getSuccessNotification(resData: any) {
    const notification: Notification = {
      state: 'success',
      message: resData.message || resData.messages[0],
    };

    this.notificationService.openNotification(
      notification,
      'flwmn-notification-success'
    );
  }
}
