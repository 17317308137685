import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  fromEvent,
  mapTo,
  merge,
  of,
} from 'rxjs';

import { environment } from 'src/environments/environment';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';
import * as CryptoJS from 'crypto-js';
import { Notification } from '../interfaces';
import { NotificationService } from './notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService implements OnDestroy {
  private subscription: Subscription = new Subscription();
  messenger = new BehaviorSubject<any>(null);

  private salt: string = 'Dfcvb542*&sdcf87r';
  private passPhrase: string = 'Av2345fgbnhes78@#dntewdfrgujhged';
  private blockSize: number = parseInt('32', 10);
  private iv: string = 'Mked098lasn34mg6';
  private iterationCount: number = parseInt('2', 10);

  //   "Encryption": {
  //   "SaltValue": "Dfcvb542*&sdcf87r",
  //   "PasswordIteration": "2",
  //   "HashAlgorithm": "SHA256",
  //   "PassPhrase": "Av2345fgbnhes78@#dntewdfrgujhged",
  //   "Blocksize": 32,
  //   "IV": "Mked098lasn34mg6",
  //   "KeySize": "128"
  // },
  constructor(
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {}

  manageDeveloperTokenAndCallFunction(customFunction: Function) {
    const onyxDocAuthData: {
      bearer_token: string;
      expiryDate: number;
    } = JSON.parse(localStorage.getItem('OnyxDoc_auth')!);

    if (
      onyxDocAuthData === null ||
      onyxDocAuthData.expiryDate < new Date().getTime()
    ) {
      this.store.dispatch(AuthActions.InitializeApp_DeveloperToken());

      this.subscription.add(
        this.store
          .pipe(select(authSelectors.getDeveloperTokenStatus))
          .subscribe((status): any => {
            if (status && status === true) {
              customFunction();
            }
          })
      );
    } else {
      customFunction();
    }
  }

  checkExpiryStatusOfNg2Idle() {
    /**if a user is
     * logged in and leaves the browser window or
     * their device goes to sleep
     * and in that mean time,
     * 'ng2Idle.main.expiry' || 'OnyxDoc' || 'OnyxDoc_auth' expires
     * this function acts as a force logout feature
     */
    document.addEventListener('visibilitychange', () => {
      const onyxDocData: {
        accessToken: string;
        userToken: string;
        exp: number;
      } = JSON.parse(localStorage.getItem('OnyxDoc')!);

      const onyxDocAuthData: {
        bearer_token: string;
        expiryDate: number;
      } = JSON.parse(localStorage.getItem('OnyxDoc_auth')!);

      const ng2IdleMainExpiry = parseInt(
        localStorage.getItem('ng2Idle.main.expiry')!
      );

      if (
        (ng2IdleMainExpiry <= new Date().getTime() ||
          (onyxDocData?.exp <= new Date().getTime() && ng2IdleMainExpiry) ||
          (onyxDocAuthData?.expiryDate <= new Date().getTime() &&
            ng2IdleMainExpiry)) &&
        document.visibilityState === 'visible'
      ) {
        // const notification: Notification = {
        //   state: 'warning',
        //   title: 'System Notification',
        //   message:
        //     'Welcome Back. Your session has expired. Logging you out now.',
        // };

        // this.notificationService.openSnackBar(
        //   notification,
        //   'flwmn-notification-warning',
        //   true
        // );

        // setTimeout(() => {
        this.authService.hasSessionLogoutBeenCalled = false;
        this.authService.stopActivityMonitor();

        localStorage.removeItem('OnyxDoc');
        localStorage.removeItem('OnyxDoc_auth');

        location.reload();
        // }, 5000);
      }
    });
  }

  private deriveKey(): CryptoJS.lib.WordArray {
    return CryptoJS.PBKDF2(
      this.passPhrase,
      CryptoJS.enc.Utf8.parse(this.salt),
      {
        keySize: this.blockSize / 4,
        iterations: this.iterationCount,
        hasher: CryptoJS.algo.SHA1,
      }
    );
  }

  public encryptData(request: string): string {
    const key = this.deriveKey();
    const ivWordArray = CryptoJS.enc.Utf8.parse(this.iv);

    const encrypted = CryptoJS.AES.encrypt(request, key, {
      iv: ivWordArray,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });

    return encrypted.toString();
  }

  public decryptData(request: string): string {
    const key = this.deriveKey();
    const ivWordArray = CryptoJS.enc.Utf8.parse(this.iv);

    const decrypted = CryptoJS.AES.decrypt(request, key, {
      iv: ivWordArray,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  AES_Encryption_Decryption(instance: 'encrypt' | 'decrypt', data: string) {
    let result!: any;

    const encryptionKey = CryptoJS.enc.Utf8.parse(environment.AES_SecretKey);
    const salt = CryptoJS.enc.Base64.parse('SXZhbiBNZWR2ZWRldg=='); // This is the byte array in .net fiddle

    const iterations = 1000; // https://learn.microsoft.com/en-us/dotnet/api/system.security.cryptography.rfc2898derivebytes?view=netcore-3.1
    const keyAndIv = CryptoJS.PBKDF2(encryptionKey, salt, {
      keySize: 256 / 32 + 128 / 32,
      iterations: iterations, // The default iteration count is 1000 so the two methods use the same iteration count.
      hasher: CryptoJS.algo.SHA1,
    });

    /**
     * so PBKDF2 in CryptoJS is direct in that it
     * always begins at the beginning of the password, whereas the .net
     * implementation offsets by the last length each time .GetBytes() is called
     * so we had to generate a Iv + Salt password and then split it
     */

    const hexKeyAndIv = CryptoJS.enc.Hex.stringify(keyAndIv);
    const key = CryptoJS.enc.Hex.parse(hexKeyAndIv.substring(0, 64));
    const iv = CryptoJS.enc.Hex.parse(
      hexKeyAndIv.substring(64, hexKeyAndIv.length)
    );

    // As you're using Encoding.Unicode in .net, we have to use CryptoJS.enc.Utf16LE here.
    if (instance === 'encrypt') {
      result = CryptoJS.AES.encrypt(CryptoJS.enc.Utf16LE.parse(data), key, {
        iv: iv,
        padding: CryptoJS.pad.ZeroPadding,
      }).toString();
    } else if (instance === 'decrypt') {
      result = CryptoJS.AES.decrypt(data, key, {
        iv: iv,
        padding: CryptoJS.pad.ZeroPadding,
      }).toString(CryptoJS.enc.Utf16LE);
    }

    return result;
  }

  // AES_Encryption_Decryption(instance: 'encrypt' | 'decrypt', data: string) {
  //   let result!: any;

  //   const encryptionKey = CryptoJS.enc.Utf8.parse(environment.AES_SecretKey);
  //   const salt = CryptoJS.enc.Base64.parse('SXZhbiBNZWR2ZWRldg=='); // This is the byte array in .net fiddle

  //   const iterations = 1000; // https://learn.microsoft.com/en-us/dotnet/api/system.security.cryptography.rfc2898derivebytes?view=netcore-3.1
  //   const keyAndIv = CryptoJS.PBKDF2(encryptionKey, salt, {
  //     keySize: 256 / 32 + 128 / 32,
  //     iterations: iterations, // The default iteration count is 1000 so the two methods use the same iteration count.
  //     hasher: CryptoJS.algo.SHA1,
  //   });

  //   /**
  //    * so PBKDF2 in CryptoJS is direct in that it
  //    * always begins at the beginning of the password, whereas the .net
  //    * implementation offsets by the last length each time .GetBytes() is called
  //    * so we had to generate a Iv + Salt password and then split it
  //    */

  //   const hexKeyAndIv = CryptoJS.enc.Hex.stringify(keyAndIv);
  //   const key = CryptoJS.enc.Hex.parse(hexKeyAndIv.substring(0, 64));
  //   const iv = CryptoJS.enc.Hex.parse(
  //     hexKeyAndIv.substring(64, hexKeyAndIv.length)
  //   );

  //   // As you're using Encoding.Unicode in .net, we have to use CryptoJS.enc.Utf16LE here.
  //   if (instance === 'encrypt') {
  //     result = CryptoJS.AES.encrypt(CryptoJS.enc.Utf16LE.parse(data), key, {
  //       iv: iv,
  //       padding: CryptoJS.pad.ZeroPadding,
  //     }).toString();
  //   } else if (instance === 'decrypt') {
  //     result = CryptoJS.AES.decrypt(data, key, {
  //       iv: iv,
  //       padding: CryptoJS.pad.ZeroPadding,
  //     }).toString(CryptoJS.enc.Utf16LE);
  //   }

  //   return result;
  // }

  // AES_Encryption_Decryption(instance: 'encrypt' | 'decrypt', data: string) {
  //   let result!: any;

  //   const encryptionKey = CryptoJS.enc.Utf8.parse(environment.AES_SecretKey);

  //   const salt = CryptoJS.enc.Base64.parse('SXZhbiBNZWR2ZWRldg=='); // This is the byte array in .net fiddle

  //   const iterations = 1000; // https://learn.microsoft.com/en-us/dotnet/api/system.security.cryptography.rfc2898derivebytes?view=netcore-3.1

  //   const keyAndIv = CryptoJS.PBKDF2(encryptionKey, salt, {
  //     keySize: 256 / 32 + 128 / 32,

  //     iterations: iterations, // The default iteration count is 1000 so the two methods use the same iteration count.

  //     hasher: CryptoJS.algo.SHA1,
  //   });

  //   /**

  //    * so PBKDF2 in CryptoJS is direct in that it

  //    * always begins at the beginning of the password, whereas the .net

  //    * implementation offsets by the last length each time .GetBytes() is called

  //    * so we had to generate a Iv + Salt password and then split it

  //    */

  //   const hexKeyAndIv = CryptoJS.enc.Hex.stringify(keyAndIv);

  //   const key = CryptoJS.enc.Hex.parse(hexKeyAndIv.substring(0, 64));

  //   const iv = CryptoJS.enc.Hex.parse(
  //     hexKeyAndIv.substring(64, hexKeyAndIv.length)
  //   );

  //   // As you're using Encoding.Unicode in .net, we have to use CryptoJS.enc.Utf16LE here.

  //   if (instance === 'encrypt') {
  //     result = CryptoJS.AES.encrypt(CryptoJS.enc.Utf16LE.parse(data), key, {
  //       iv: iv,

  //       padding: CryptoJS.pad.Pkcs7,
  //     }).toString();
  //   } else if (instance === 'decrypt') {
  //     result = CryptoJS.AES.decrypt(data, key, {
  //       iv: iv,

  //       padding: CryptoJS.pad.Pkcs7,
  //     }).toString(CryptoJS.enc.Utf16LE);
  //   }

  //   return result;
  // }

  // public async Task<string> Encrypt(string plainText)
  // {
  //     byte[] text = Encoding.Unicode.GetBytes(plainText);
  //     string encryptedResult = null;

  //     using (AesManaged aes = new AesManaged())
  //     {
  //         Rfc2898DeriveBytes pdb = new Rfc2898DeriveBytes(_configuration["Encryption:Key"],
  //             new byte[] { 0x49, 0x76, 0x61, 0x6e, 0x20, 0x4d, 0x65, 0x64, 0x76, 0x65, 0x64, 0x65, 0x76 });
  //         aes.Key = pdb.GetBytes(32);
  //         aes.IV = pdb.GetBytes(16);
  //         //k2.GetBytes(16);
  //         aes.Padding = PaddingMode.Zeros;
  //         ICryptoTransform encryptor = aes.CreateEncryptor(aes.Key, aes.IV);
  //         using (MemoryStream ms = new MemoryStream())
  //         {
  //             using (CryptoStream cs = new CryptoStream(ms, aes.CreateEncryptor(), CryptoStreamMode.Write))
  //             {
  //                 cs.Write(text, 0, text.Length);
  //                 cs.FlushFinalBlock();
  //             }

  //             encryptedResult = Convert.ToBase64String(ms.ToArray());
  //         }
  //     }

  //     return encryptedResult;
  // }

  // AES_Encryption_Decryption(instance: 'encrypt' | 'decrypt', data: string) {
  //   let result!: any;

  //   const encryptionKey = CryptoJS.enc.Utf8.parse(environment.AES_SecretKey);

  //   const salt = CryptoJS.enc.Base64.parse('SXZhbiBNZWR2ZWRldg=='); // This is the byte array in .net fiddle

  //   const iterations = 1000; // https://learn.microsoft.com/en-us/dotnet/api/system.security.cryptography.rfc2898derivebytes?view=netcore-3.1

  //   const keyAndIv = CryptoJS.PBKDF2(encryptionKey, salt, {
  //     keySize: 256 / 32 + 128 / 32,

  //     iterations: iterations, // The default iteration count is 1000 so the two methods use the same iteration count.

  //     hasher: CryptoJS.algo.SHA1,
  //   });

  //   /**

  //    * so PBKDF2 in CryptoJS is direct in that it

  //    * always begins at the beginning of the password, whereas the .net

  //    * implementation offsets by the last length each time .GetBytes() is called

  //    * so we had to generate a Iv + Salt password and then split it

  //    */

  //   const hexKeyAndIv = CryptoJS.enc.Hex.stringify(keyAndIv);

  //   const key = CryptoJS.enc.Hex.parse(hexKeyAndIv.substring(0, 64));

  //   const iv = CryptoJS.enc.Hex.parse(
  //     hexKeyAndIv.substring(64, hexKeyAndIv.length)
  //   );

  //   // As you're using Encoding.Unicode in .net, we have to use CryptoJS.enc.Utf16LE here.

  //   if (instance === 'encrypt') {
  //     result = CryptoJS.AES.encrypt(CryptoJS.enc.Utf16LE.parse(data), key, {
  //       iv: iv,

  //       padding: CryptoJS.pad.Pkcs7,
  //     }).toString();
  //   } else if (instance === 'decrypt') {
  //     result = CryptoJS.AES.decrypt(data, key, {
  //       iv: iv,

  //       padding: CryptoJS.pad.Pkcs7,
  //     }).toString(CryptoJS.enc.Utf16LE);
  //   }

  //   return result;
  // }

  decryptResponse(resData: any) {
    return {
      ...resData,
      entity:
        resData.entity !== null
          ? JSON.parse(
              this.AES_Encryption_Decryption('decrypt', resData.entity as any)
            )
          : resData.entity,
    };
  }

  async checkIfDocumentIsEncrypted(
    reader: FileReader,
    file: any,
    resolve: any,
    reject: any
  ) {
    let isDocumentEncrypted: boolean | null;

    reader.onload = async () => {
      try {
        const files = new Blob([reader.result!], { type: '' });

        isDocumentEncrypted = null;

        files.text().then((x) => {
          if (
            x.includes('Encrypt') ||
            x
              .substring(x.lastIndexOf('<<'), x.lastIndexOf('>>'))
              .includes('/Encrypt')
          ) {
            isDocumentEncrypted = true;
          } else {
            isDocumentEncrypted = false;
          }

          resolve({
            reader: reader,
            file: file,
            isDocumentEncrypted: isDocumentEncrypted,
          });
        });
      } catch (error: any) {
        const notification: Notification = {
          state: 'error',
          message: `${error.name}: ${error.message}`,
        };

        this.notificationService.openNotification(
          notification,
          'flwmn-notification-error'
        );

        reject(error);
      }
    };

    reader.readAsArrayBuffer(file);
  }

  readonly online$: Observable<boolean> = merge(
    of(navigator.onLine),
    fromEvent(window, 'online').pipe(mapTo(true)),
    fromEvent(window, 'offline').pipe(mapTo(false))
  );

  // private getErrorMessageFromHttpErrorResponse(
  //   errorRes: HttpErrorResponse
  // ): string {
  //   switch (errorRes.status) {
  //     case 401:
  //       location.reload();
  //       // this.store.dispatch(AuthActions.Logout({ payload: {} }));
  //       // return 'Your session has timed out. Please login to continue';
  //       return 'Reloading...';

  //     case 403:
  //       return 'You are not authorized to access this resource. Please contact admin.';

  //     case 404:
  //       return 'The URL of this resource does not exist. Please contact support.';

  //     // case 500:
  //     //   return `Sorry, this is not working properly. We know about this mistake and we are working to fix it`;

  //     // case 503:
  //     //   return `Sorry, the server is currently unavailable. We know about this mistake and we are working to fix it`;

  //     default:
  //       return 'An error occurred please try again.';
  //   }
  // }

  handleErrorMessages(errorRes: HttpErrorResponse, type: string) {
    // const notification: Notification = {
    //   state: 'error',
    //   title: 'System Notification',
    //   message: this.getErrorMessageFromHttpErrorResponse(errorRes),
    // };

    // this.notificationService.openNotification(
    //   notification,
    //   'flwmn-notification-error',
    //   true
    // );

    this.store.dispatch({
      type: type,
    });

    return of();
  }

  getBrowserName(browserAgent?: string | null) {
    const userAgent = browserAgent || navigator.userAgent;

    if (this.detectBraveFeatures() && !this.isTorBrowser()) {
      return 'Brave';
    } else if (userAgent.includes('Edg')) {
      return 'Microsoft Edge';
    } else if (userAgent.includes('OPR')) {
      return 'Opera';
    } else if (
      userAgent.includes('Chrome') &&
      !userAgent.includes('Chromium')
    ) {
      return 'Google Chrome';
    } else if (userAgent.includes('Firefox') && !this.isTorBrowser()) {
      return 'Mozilla Firefox';
    } else if (this.isTorBrowser()) {
      return 'Tor Browser';
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      return 'Safari';
    } else if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
      return 'Internet Explorer';
    } else if (userAgent.includes('Chromium')) {
      return 'Chromium';
    } else {
      return 'Unknown browser';
    }
  }

  isTorBrowser() {
    const userAgent = navigator.userAgent;
    const isFirefox = userAgent.includes('Firefox');

    const isPrivacyFocused =
      !window.RTCPeerConnection || !window.WebGLRenderingContext;

    return isFirefox && isPrivacyFocused;
  }

  detectBraveFeatures() {
    const rtcSupport = window.RTCPeerConnection;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const isBraveCanvasBlocked =
      context && context.getImageData(0, 0, 1, 1).data.length === 0;

    return !rtcSupport && isBraveCanvasBlocked;
  }

  decodeAndReplaceSpaceWithPlus(encodedString: string) {
    // Decode the string first
    const decodedString = decodeURIComponent(encodedString);
    // Replace spaces with '+'
    return decodedString.replace(/ /g, '+');
  }

  generateGUID(): string {
    const s4 = (): string => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
