import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import * as fromApp from '../app/app.reducer';
import * as SubscriptionActions from '../subscription/subscription.actions';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {
  ApiResponse,
  GenericResponse,
  Notification,
  SubscriptionResponse,
} from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import { USE_DEVELOPER_TOKEN } from '../../interceptors/app.interceptor.service';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class SubscriptionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private router: Router,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(SubscriptionActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getSubscriptionById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.GetSubscriptionById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([subscriptionData, authState]) => {
          const params = new HttpParams().set(
            'isSubscriberNewlyCreated',
            subscriptionData?.payload?.isSubscriberNewlyCreated ?? false
          );

          return this.http
            .get<GenericResponse>(
              `${
                environment.onyxDocSubscriptionUrl
              }/Subscriptions/getsubscriptionbyid/${
                subscriptionData?.payload?.subscriberId ??
                authState.user.SubscriberId
              }/${subscriptionData.payload.id}/${
                subscriptionData?.payload?.userId ?? authState.user.UserId
              }`,
              {
                context: new HttpContext().set(
                  USE_DEVELOPER_TOKEN,
                  subscriptionData.payload.isSubscriberNewlyCreated
                    ? true
                    : false
                ),

                params,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionActions.IsLoading({ payload: false })
                );
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    SubscriptionActions.SaveSubscriptionById({
                      payload: resData.entity,
                    })
                  );
                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch(
                    SubscriptionActions.SaveSubscriptionById({
                      payload: null,
                    })
                  );

                  return {
                    type: '[Subscription] Failed To Get Subscription  By Id',
                  };
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription][CatchError] Failed To Get Subscription  By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getSubscriptionEndDateById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.GetSubscriptionEndDateById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/getsubscriptionenddatebyid/${authState.user.SubscriberId}/${subscriptionData.payload.id}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return SubscriptionActions.SaveSubscriptionEndDateById({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  SubscriptionActions.SaveSubscriptionEndDateById({
                    payload: null,
                  })
                );

                return {
                  type: '[Subscription] Failed To Get Subscription End Date By Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Get Subscription End Date By Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSubscriptionsByPlan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.GetSubscriptionsByPlan),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/getsubscriptionsbyplan/${authState.user.SubscriberId}/${subscriptionData.payload.subscriptionplanid}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return SubscriptionActions.SaveSubscriptionsByPlan({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  SubscriptionActions.SaveSubscriptionsByPlan({
                    payload: null,
                  })
                );

                return {
                  type: '[Subscription] Failed To Get Subscription By Plan',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Get Subscription By Plan ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSubscriptionsByProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.GetSubscriptionsByProduct),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/getsubscriptionsbyproduct/${authState.user.SubscriberId}/${authState.user.UserId}/${subscriptionData.payload.productId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return SubscriptionActions.SaveSubscriptionsByProduct({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  SubscriptionActions.SaveSubscriptionsByProduct({
                    payload: null,
                  })
                );

                return {
                  type: '[Subscription] Failed To Get Subscription By Product',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Get Subscription By Product ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getActiveSubscriptionsByProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.GetActiveSubscriptionsByProduct),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/getsubscriptionsbyproductandsubscriptionstatus/${authState.user.SubscriberId}/${authState.user.UserId}/${subscriptionData.payload.productId}/${subscriptionData.payload.subscriptionStatus}/${subscriptionData.payload.skip}/1000`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return SubscriptionActions.SaveActiveSubscriptionsByProduct({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  SubscriptionActions.SaveActiveSubscriptionsByProduct({
                    payload: null,
                  })
                );

                return {
                  type: '[Subscription] Failed To Get Active Subscription By Product',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Get Active Subscription By Product ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getActiveSubscriptionsByPlan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.GetActiveSubscriptionsByPlan),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/getactivesubscriptionsbyplan/${authState.user.SubscriberId}/${subscriptionData.payload.subscriptionplanid}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return SubscriptionActions.SaveActiveSubscriptionsByPlan({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  SubscriptionActions.SaveActiveSubscriptionsByPlan({
                    payload: null,
                  })
                );

                return {
                  type: '[Subscription] Failed To Get Active Subscription By Plan',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Get Active Subscription By Plan ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.GetSubscriptions),
      withLatestFrom(this.store.select('auth')),
      switchMap(([susbscriptionData, authState]) => {
        this.store.dispatch(SubscriptionActions.IsFetching({ payload: true }));
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/getsubscriptions/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionActions.IsFetching({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriptionActions.SaveSubscriptions({
                  payload: resData.entity.reverse(),
                });
                // return ascendingSort(resData.entity, 'name');
              } else {
                // this.store.dispatch(
                //   GeneralActions.SetNotification({
                //     payload: {
                //       isSuccess: false,
                //       message: resData.message || resData.messages[0],
                //     },
                //   })
                // );

                return {
                  type: '[Subscription] Failed To Get Subscriptions',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Get Subscriptions ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getActiveSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.GetActiveSubscriptions),
      withLatestFrom(this.store.select('auth')),
      switchMap(([susbscriptionData, authState]) => {
        this.store.dispatch(SubscriptionActions.IsFetching({ payload: true }));
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/getactivesubscriptions/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionActions.IsFetching({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriptionActions.SaveActiveSubscriptions({
                  payload: resData.entity.reverse(),
                });
                // return ascendingSort(resData.entity, 'name');
              } else {
                // this.store.dispatch(
                //   GeneralActions.SetNotification({
                //     payload: {
                //       isSuccess: false,
                //       message: resData.message || resData.messages[0],
                //     },
                //   })
                // );

                return {
                  type: '[Subscription] Failed To Get Active Subscriptions',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Get Active Subscriptions ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getAllActiveSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.GetAllActiveSubscriptions),
      withLatestFrom(this.store.select('auth')),
      switchMap(([susbscriptionData, authState]) => {
        this.store.dispatch(SubscriptionActions.IsFetching({ payload: true }));
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/getallactivesubscriptions/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionActions.IsFetching({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriptionActions.SaveAllActiveSubscriptions({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Subscription] Failed To Get All Active Subscriptions',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Get All Active Subscriptions ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getSubscriptionsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.GetSubscriptionStatus),
      withLatestFrom(this.store.select('auth')),
      switchMap(([susbscriptionData, authState]) => {
        this.store.dispatch(SubscriptionActions.IsFetching({ payload: true }));
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/getsubscriptionstatus/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionActions.IsFetching({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriptionActions.SaveSubscriptionsStatus({
                  payload: resData.entity.reverse(),
                });
                // return ascendingSort(resData.entity, 'name');
              } else {
                // this.store.dispatch(
                //   GeneralActions.SetNotification({
                //     payload: {
                //       isSuccess: false,
                //       message: resData.message || resData.messages[0],
                //     },
                //   })
                // );

                return {
                  type: '[Subscription] Failed To Get Subscription Status',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Get Subscription Status ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getActiveSubscriptionBySubscriberId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.GetActiveSubscriptionsBySubscriberId),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/getactivesubscriptionsbysubscriberid/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return SubscriptionActions.SaveActiveSubscriptionsBySubscriberId(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  SubscriptionActions.SaveActiveSubscriptionsBySubscriberId({
                    payload: null,
                  })
                );

                return {
                  type: '[Subscription] Failed To Get Active Subscription By Subscriber Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Get Active Subscription By Subscriber Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  GetSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.GetAllSubscriptions),
      withLatestFrom(this.store.select('auth')),
      switchMap(([susbscriptionData, authState]) => {
        this.store.dispatch(SubscriptionActions.IsFetching({ payload: true }));
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/GetSubscriptions/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionActions.IsFetching({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriptionActions.SaveSubscriptions({
                  payload: resData.entity.reverse(),
                });
                // return ascendingSort(resData.entity, 'name');
              } else {
                // this.store.dispatch(
                //   GeneralActions.SetNotification({
                //     payload: {
                //       isSuccess: false,
                //       message: resData.message || resData.messages[0],
                //     },
                //   })
                // );

                return {
                  type: '[Subscription] Failed To Get Subscriptions',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Get Subscriptions ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  cancelSubscription$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.CancelSubscription),
        withLatestFrom(this.store.select('auth')),
        switchMap(([subscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/Subscriptions/cancelsubscription`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...subscriptionData.payload,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionActions.IsFetching({ payload: false })
                );

                if (resData.succeeded) {
                  // const notification: Notification = {
                  //   state: 'success',
                  //   message:
                  //     resData?.message || resData?.messages[0] || 'Suceess',
                  // };

                  // this.notificationService.openSnackBar(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );

                  this.store.dispatch({
                    type: '[Subscription] Cancel Subscription  Was Successful',
                  });
                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Subscription] Failed To Cancel Subscription ',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription][CatchError] Failed To Cancel Subscription  ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  activateSubscription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.ActivateSubscription),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/activatesubscription`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...subscriptionData.payload,
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(SubscriptionActions.GetSubscriptions());

                return {
                  type: '[Subscription] Activate Subscription  Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Subscription] Failed To Activate Subscription ',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Activate Subscription  ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deactivateSubscription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.DeactivateSubscription),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/deactivatesubscription`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...subscriptionData.payload,
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(SubscriptionActions.GetSubscriptions());

                return {
                  type: '[Subscription] Deactivate Subscription  Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Subscription] Failed To Deactivate Subscription ',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Deactivate Subscription  ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createSubscription$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.CreateSubscription),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<ApiResponse<SubscriptionResponse>>(
              `${environment.onyxDocSubscriptionUrl}/Subscriptions/create`,
              {
                subscriberId: authState.user.Role.SubscriberId,
                ...susbscriptionData.payload,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  SubscriptionActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch(SubscriptionActions.GetSubscriptions());

                  this.store.dispatch({
                    type: '[Subscription] Create Subscription  Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Subscription] Failed To Create Subscription ',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription][CatchError] Failed To Renew Subscription ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.UpdateSubscripiton),
      withLatestFrom(this.store.select('auth')),
      switchMap(([susbscriptionData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/update`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              ...susbscriptionData.payload,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                this.store.dispatch(SubscriptionActions.GetSubscriptions());

                this.router.navigate([
                  '/app/account-settings/subscription-plan-configuration',
                ]);
                return {
                  type: '[Subscription] Update Subscription Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Subscription] Failed To Update Subscription ',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError] Failed To Update Subscription  ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  renewSubscription$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.RenewSubscription),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/Subscriptions/renewsubscription`,
              {
                ...susbscriptionData.payload,
                subscriberId: authState.user.Role.SubscriberId,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Subscription was renewed successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Subscription] Subscription Was renewed successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData?.message || resData?.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription] Failed To Renew Subscription',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription][CatchError] Failed To Renew Subscription ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  changeSubscription$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.ChangeSubscription),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/Subscriptions/changesubscription`,
              {
                ...susbscriptionData.payload,
                subscriberId: authState.user.Role.SubscriberId,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Subscription was changed successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Subscription] Subscription Was changed successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to Change subscription',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription] Failed To Change Subscription',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription][CatchError] Failed To Change Subscription ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  // Card

  addCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SubscriptionActions.AddCard),
        withLatestFrom(this.store.select('auth')),
        switchMap(([userData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/Cards/addcard`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.Role.SubscriberId,
                email: authState.user.Email,
                ...userData.payload,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionActions.IsLoading({ payload: false })
                );
                if (resData.succeeded === true) {
                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Document] Failed To Initialize Adding a Card',
                  };
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription][CatchError]  Failed To Initialize Adding a Card ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getCards$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.GetCards),
      withLatestFrom(this.store.select('auth')),
      switchMap(([cardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Cards/getCards/${authState.user?.SubscriberId}/${authState.user?.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return SubscriptionActions.SaveCard({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  SubscriptionActions.SaveCard({
                    payload: null,
                  })
                );

                return {
                  type: '[Card] Failed To Get All Cards',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription][CatchError]  Failed To Get All Cards ${errorRes.message}`
              );
            })
          );
      })
    );
  });
}
