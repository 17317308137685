<ng-container
  *ngIf="
    (isAuthLoading | async) !== false ||
    (isSubscriptionLoading | async) !== false
  "
>
  <div class="general-loader-container">
    <div class="general-loader-backdrop"></div>

    <div class="general-loader-content">
      <app-general-loader></app-general-loader>
    </div>
  </div>
</ng-container>

<section id="sign-up-container">
  <div class="space-fill"></div>

  <div class="login-page-content-inner">
    <ng-container>
      <div class="auth-login-form-container" *ngIf="page === 1">
        <div class="welcome-message">
          <ng-container>
            <p class="flwmn-heading-4 flwmn-text-black fw-700 text-center">
              Create your Flowmono account
            </p>

            <p class="flwmn-para-3 flwmn-text-black fw-400 text-center">
              Get started for free. No credit card needed.
            </p>
          </ng-container>
        </div>

        <div class="auth-form-container">
          <form [formGroup]="registrationForm" (submit)="onSubmit()">
            <div class="mb-2">
              <div class="third-party-options">
                <div
                  class="third-party-option"
                  matRipple
                  (click)="onSignUpWithGoogle()"
                >
                  <span class="sign-type-logo">
                    <app-svg
                      src="/assets/icons/auth/google-logo-icon.svg"
                      svgHeight="1.25rem"
                      svgWidth="1.25rem"
                    ></app-svg>
                  </span>

                  <span class="sign-type-title fw-600"
                    >Sign up with Google</span
                  >
                </div>

                <div
                  class="third-party-option"
                  matRipple
                  (click)="onSignUpWithLinkedIn()"
                >
                  <span class="sign-type-logo">
                    <app-svg
                      src="assets/icons/auth/linkedin-logo-icon.svg"
                      svgHeight="1.5rem"
                      svgWidth="1.5rem"
                    ></app-svg>
                  </span>

                  <span class="sign-type-title fw-600"
                    >Sign up with LinkedIn</span
                  >
                </div>

                <div
                  class="third-party-option last-option"
                  matRipple
                  (click)="onSignUpWithMicrosoft()"
                >
                  <span class="sign-type-logo">
                    <app-svg
                      src="assets/icons/auth/azure-ad-third-party-auth.svg"
                      svgHeight="1.25rem"
                      svgWidth="1.25rem"
                    ></app-svg>
                  </span>

                  <span class="sign-type-title fw-600"
                    >Sign up with Microsoft</span
                  >
                </div>
              </div>

              <div class="text-divider">OR</div>
            </div>

            <div class="registration-form-page-1">
              <div class="input-style1 input-md input-style-rounded-edge">
                <label class="input-label">Email Address</label>

                <mat-form-field appearance="outline" [style.width.%]="100">
                  <input
                    #email
                    type="email"
                    inputmode="email"
                    matInput
                    formControlName="email"
                    (input)="
                      registrationForm.patchValue({
                        email: email.value.replaceAll(' ', '')
                      })
                    "
                  />

                  <mat-error *ngIf="registrationFormControls.email.invalid">
                    <img
                      src="/assets/icons/error.svg"
                      alt=""
                      class="img-fluid error-icon"
                    />
                    {{ getErrorMessage('email') }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="">
              <button
                matRipple
                type="submit"
                class="btn flwmn-btn-linear-gradient flwmn-btn-md flwmn-btn-full login-btn mt-3"
                [disabled]="registrationForm.invalid"
              >
                Let’s GO!
              </button>
            </div>

            <div class="w-100 d-flex justify-content-center mb-0">
              <p class="flwmn-para-1 no-account-text mt-3 w-75">
                <span>
                  By clicking Create account now, you have agreed to
                  <a
                    [routerLink]="['/register']"
                    [queryParams]="{ developer: true }"
                    >Flowmono’s Terms & Conditions
                  </a>
                </span>
              </p>
            </div>

            <p class="flwmn-para-2 no-account-text" [hidden]="page !== 1">
              <span>
                Already a user?
                <a [routerLink]="['/login']">Login</a>
              </span>
            </p>
          </form>
        </div>
      </div>

      <div class="existing-team" *ngIf="page === 1.5">
        <div class="hanging-borders-container">
          <span class="hanging-borders"></span>
          <span class="hanging-borders"></span>
        </div>

        <div class="description-title">
          <p class="flwmn-heading-4 flwmn-text-black fw-600">
            We found your team on Flowmono!
          </p>

          <p class="flwmn-para-2 flwmn-text-black fw-400 descrip">
            Join your team’s account and drive more collaboration between you
            and your team.
          </p>
        </div>

        <div class="existing-company-info-container">
          <div class="company-logo rounded-circle border">
            <img
              *ngIf="discoveredCompany?.logo"
              src="
                    https://flowmonostorage.blob.core.windows.net/uploads/Revent_Admin_logo.png
                  "
              alt="Organization Profile Picture"
              class="img-logo"
            />

            <span
              *ngIf="!discoveredCompany?.logo"
              class="w-100 h-100 d-flex justify-content-center align-items-center flwmn-text-black fw-600 text-capitalize"
            >
              {{
                discoveredCompany?.domainName
                  ? discoveredCompany?.domainName[0]
                  : discoveredCompany?.name[0]
              }}
              {{
                discoveredCompany?.domainName
                  ? discoveredCompany?.domainName[1]
                  : discoveredCompany?.name[1]
              }}
            </span>
          </div>

          <div class="company-info">
            <span class="flwmn-para-2 fw-600">{{
              discoveredCompany?.name
                ? discoveredCompany?.name
                : discoveredCompany?.domainName
            }}</span>

            <span class="flwmn-para-1"
              >{{ allUsers?.length }} Member
              {{ allUsers?.length! > 1 ? '(s)' : '' }}</span
            >
          </div>
        </div>

        <div class="login-options">
          <button
            matRipple
            type="button"
            class="btn flwmn-btn-linear-gradient flwmn-btn-md flwmn-btn-full login-btn rounded my-4"
            (click)="initiateJoinExistingOrganization(discoveredCompany?.id)"
          >
            Request to join
          </button>

          <div class="text-divider">OR</div>

          <button
            matRipple
            type="button"
            class="btn flwmn-btn-primary-outline flwmn-btn-md flwmn-btn-full login-btn rounded mt-1"
            (click)="goToPage(1)"
          >
            Create a new account
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="space-fill"></div>
</section>
