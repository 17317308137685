import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import * as DefaultRoleActions from './default-role.actions';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class DefaultRoleEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private router: Router,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(DefaultRoleActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getDefaultRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DefaultRoleActions.GetDefaultRoles),
      withLatestFrom(this.store.select('auth')),
      switchMap(([defaultRoleData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/RolePermissions/defaultroles`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              ...defaultRoleData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )

          .pipe(
            map((resData) => {
              this.store.dispatch(
                DefaultRoleActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return DefaultRoleActions.SaveDefaultRoles({
                  payload: {
                    roles: resData.entity.role,
                    totalCount: resData.entity.count,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return DefaultRoleActions.SaveDefaultRoles({
                  payload: [],
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Default Role][CatchError] Failed To Get Default Role ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getDefaultRoleById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DefaultRoleActions.GetDefaultRoleById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([defaultRoleData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocAuthUrl}/RolePermissions/getdefaultrolebyid/${defaultRoleData.payload.id}/${authState.user.SubscriberId}/${authState.user.UserId}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )

            .pipe(
              map((resData) => {
                this.store.dispatch(
                  DefaultRoleActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Default Role] Get Default Role By Id Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Default Role] Failed To Get Default Role By Id',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Default Role][CatchError] Failed To Get Default Role By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createDefaultRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DefaultRoleActions.CreateDefaultRole),
      withLatestFrom(this.store.select('auth')),
      switchMap(([defaultRoleData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/RolePermissions/createdefaultrole/${authState.user.UserId}`,
            {
              ...defaultRoleData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )

          .pipe(
            map((resData) => {
              this.store.dispatch(
                DefaultRoleActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.router.navigate([
                  '/app/account-settings/default-role-configuration',
                ]);

                this.store.dispatch(
                  DefaultRoleActions.IsLoading({ payload: true })
                );

                this.store.dispatch(
                  DefaultRoleActions.GetDefaultRoles({
                    payload: { skip: 0, take: 10 },
                  })
                );

                return {
                  type: '[Default Role] Create Default Role Was Succesful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Default Role] Failed To Create Default Role',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Default Role][CatchError] Failed To Create Default Role ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  editDefaultRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DefaultRoleActions.EditDefaultRole),
      withLatestFrom(this.store.select('auth')),
      switchMap(([defaultRoleData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/RolePermissions/updatedefaultrole/${authState.user.UserId}`,
            {
              ...defaultRoleData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )

          .pipe(
            map((resData) => {
              this.store.dispatch(
                DefaultRoleActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Default role updated successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.router.navigate([
                  '/app/account-settings/default-role-configuration',
                ]);

                this.store.dispatch(
                  DefaultRoleActions.IsLoading({ payload: true })
                );

                this.store.dispatch(
                  DefaultRoleActions.GetDefaultRoles({
                    payload: { skip: 0, take: 10 },
                  })
                );

                return {
                  type: '[Default Role] Edit Default Role Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Default Role] Failed To Edit Default Role',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Default Role][CatchError] Failed To Edit Default Role ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deleteDefaultRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DefaultRoleActions.DeleteDefaultRole),
      withLatestFrom(this.store.select('auth')),
      switchMap(([defaultRoleData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/RolePermissions/deletedefaultrole/${authState.user.UserId}`,
            {
              ...defaultRoleData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )

          .pipe(
            map((resData) => {
              this.store.dispatch(
                DefaultRoleActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DefaultRoleActions.IsLoading({ payload: true })
                );

                this.store.dispatch(
                  DefaultRoleActions.GetDefaultRoles({
                    payload: {
                      skip: defaultRoleData?.additionalPayload?.skip
                        ? defaultRoleData?.additionalPayload?.skip
                        : 0,

                      take: defaultRoleData?.additionalPayload?.take
                        ? defaultRoleData?.additionalPayload?.take
                        : 10,
                    },
                  })
                );

                return {
                  type: '[Default Role] Delete Default Role Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Default Role] Failed To Delete Default Role',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Default Role][CatchError] Failed To Delete Default Role ${errorRes.message}`
              );
            })
          );
      })
    );
  });
}
