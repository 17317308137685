import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Observable, Subscription } from 'rxjs';
import { HelperService } from 'src/app/@core/services/helper.service';
import { ThemeService } from 'src/app/@core/services/theme.service';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as SubscriptionActions from 'src/app/@core/stores/subscription-plan/subscription-plan.actions';
import * as subscriptionSelectors from 'src/app/@core/stores/subscription-plan/subscription-plan.selectors';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';
import * as GeneralActions from 'src/app/@core/stores/general/general.actions';
import * as generalSelectors from 'src/app/@core/stores/general/general.selectors';
import {
  SocialAuthService,
  GoogleLoginProvider,
  // FacebookLoginProvider,
  SocialUser,
} from '@abacritt/angularx-social-login';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';

import { AuthEffects } from 'src/app/@core/stores/auth/auth.effects';
import {
  PricingPlanType,
  SubscriberTypeEnum,
  SubscriptionFrequency,
  SubscriptionPlanTypeEnum,
  // SubscriptionFrequency,
  // SubscriptionPlanTypeEnum,
  ThirdPartyTypeEnum,
} from 'src/app/@core/enums';
import { getActiveSubscriptionPlanForRegistration } from 'src/app/@core/utils/helpers';
// import { SocialAuthService } from '@abacritt/angularx-social-login';
import { ThirdPartyErrorComponent } from '../modals/third-party-error/third-party-error.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/@core/services/auth.service';
import { InteractionStatus } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
// import { regexValidator } from 'src/app/@core/utils/helpers';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  isAuthLoading!: Observable<boolean>;
  isSubscriptionLoading!: Observable<boolean>;
  isFromDeveloperWebsite: boolean = false;
  page = 1;
  year!: number;

  show: boolean = false;
  registrationForm!: FormGroup;
  latitude: number = 0;
  longitude: number = 0;
  linkedInAuthUrl: any;
  ipAddress: string;
  location: string;

  discoveredCompany!: any;
  doesOrgDomainNameExist = true;
  subscription!: any;
  pricing: any;
  allUsers!: any[];
  country: 'Nigeria' | 'Others' = 'Others';

  registrationType!: 'individual' | 'corporate';

  private _subscription: Subscription = new Subscription();

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private themeService: ThemeService,
    private fb: FormBuilder,
    private helperService: HelperService,
    private authEffects: AuthEffects,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private dialog: MatDialog,
    private authService: AuthService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this._subscription.add(
      this.route.queryParams.subscribe((res) => {
        if (res?.developer) {
          this.isFromDeveloperWebsite = res?.developer;
        }
      })
    );
  }

  ngOnInit(): void {
    this.themeService.removeWebappThemes();

    this.isAuthLoading = this.store.select(authSelectors.getAuthIsLoading);

    this.year = new Date().getFullYear();

    this.isSubscriptionLoading = this.store.select(
      subscriptionSelectors.getSubscriptionPlanIsLoading
    );

    this.createRegistrationForm();

    this.listenToCheckEmailEffects();

    this.listenToCheckUpDomainNameEffects();

    this.getLocation();

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.getActiveSubscriptionPlans.bind(this)
    );

    this.listenToGetAllActiveSubscriptionPlansForWebsite();

    this.authService.listenToAzureADLoginSuccess();

    this.manageAzureADLoginAfterRedirect();

    this.getIpAddressDetails();

    this.listenToAuthRegisterEffects();
  }

  get registrationFormControls() {
    return this.registrationForm.controls;
  }

  getActiveSubscriptionPlans() {
    this.store.dispatch(SubscriptionActions.IsLoading({ payload: true }));

    this.store.dispatch(
      SubscriptionActions.GetActiveSubscriptionPlansForWebsite()
    );
  }

  listenToGetAllActiveSubscriptionPlansForWebsite() {
    this._subscription.add(
      this.store
        .select(subscriptionSelectors.getAllActiveSubscriptionPlansForWebsite)

        .subscribe((resData) => {
          if (resData !== null) {
            this.subscription = getActiveSubscriptionPlanForRegistration(
              resData
              // this.registrationType
            );

            this.subscription?.subscriptionPlanPricings?.forEach(
              (subscriptionPlanPricing: any) => {
                if (
                  this.country === 'Nigeria' &&
                  subscriptionPlanPricing.currencyCode === 'NGN' &&
                  subscriptionPlanPricing.pricingPlanType ===
                    PricingPlanType.monthly
                ) {
                  this.pricing = subscriptionPlanPricing;
                } else if (
                  this.country === 'Others' &&
                  subscriptionPlanPricing.currencyCode === 'USD' &&
                  subscriptionPlanPricing.pricingPlanType ===
                    PricingPlanType.monthly
                ) {
                  this.pricing = subscriptionPlanPricing;
                }
              }
            );
          }

          // else {
          //   this.helperService.manageDeveloperTokenAndCallFunction(
          //     this.getActiveSubscriptionPlans.bind(this)
          //   );
          // }
        })
    );
  }

  createRegistrationForm() {
    this.registrationForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  getIpAddressDetails() {
    this.store.dispatch(GeneralActions.GetIpAddressDetails());
  }

  listenToGetIpAddressDetailsSelector() {
    this.subscription.add(
      this.store
        .select(generalSelectors.getIpAddressDetails)
        .subscribe((resData: any) => {
          if (resData !== null) {
            this.ipAddress = resData?.ip;
            this.location = resData?.country_name;
          }
        })
    );
  }

  onClickContinue() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.checkEmail.bind(this)
    );
  }

  checkEmail() {
    this.store.dispatch(
      AuthActions.CheckEmail({
        payload: { contactEmail: this.registrationForm.value.email },
      })
    );
  }

  listenToCheckEmailEffects() {
    this._subscription.add(
      this.authEffects.checkEmail$.subscribe((resData) => {
        if (resData !== null) {
          if (resData?.succeeded === true) {
            this.initiateCheckUpDomainName();
          }
        }
      })
    );
  }

  initiateCheckUpDomainName(optionalData?: {
    isThirdParty: boolean;
    thirdPartyType: ThirdPartyTypeEnum;
    firstName: string;
    lastName: string;
    email: string;
    photoUrl: string;
  }) {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.CheckUpDomainName.bind(this, optionalData)
    );
  }

  CheckUpDomainName(optionalData?: {
    isThirdParty: boolean;
    thirdPartyType: ThirdPartyTypeEnum;
    firstName: string;
    lastName: string;
    email: string;
    photoUrl: string;
  }) {
    this.store.dispatch(
      AuthActions.CheckUpDomainName({
        payload: {
          email: (
            optionalData?.email ?? (this.registrationForm.value.email as string)
          )
            .toLowerCase()
            .trim(),
        },
        alternatePayload: optionalData
          ? {
              isThirdParty: optionalData.isThirdParty!,
              thirdPartyType: optionalData.thirdPartyType!,
              firstName: optionalData.firstName!,
              lastName: optionalData.lastName!,
              email: optionalData.email!,
              photoUrl: optionalData.photoUrl!,
            }
          : null,
      })
    );
  }

  listenToCheckUpDomainNameEffects() {
    this._subscription.add(
      this.authEffects.checkUpDomainName$.subscribe((resData) => {
        if (resData?.resData !== null) {
          if (resData?.resData.entity?.existingDomain !== null) {
            this.doesOrgDomainNameExist = true;
          } else {
            this.doesOrgDomainNameExist = false;
          }

          if (resData?.alternatePayload) {
            if (this.doesOrgDomainNameExist === false) {
              const userData = resData?.alternatePayload;

              this.thirdPartyDispatch(
                {
                  firstName: userData?.firstName,
                  lastName: userData?.lastName,
                  email: userData?.email,
                  photoUrl: userData.photoUrl,
                },
                userData.thirdPartyType
              );
            } else {
              this.discoveredCompany = resData?.resData?.entity?.existingDomain;
              this.allUsers = Object.values(resData.resData?.entity?.usersList);

              const userData = resData?.alternatePayload;

              this.registrationForm.patchValue({
                email: userData?.email,
                password: ``,
                firstname: userData?.firstName,
                lastname: userData?.lastName,
              });

              this.goToPage(1.5);
            }
          } else {
            this.actionAfterDomainNameCheck(resData?.resData?.entity);
          }
        }
      })
    );
  }

  actionAfterDomainNameCheck(resData: any) {
    if (resData.subscriberType === 'Individual') {
      this.registrationType = 'individual';
    } else if (resData.subscriberType === 'Corporate') {
      this.registrationType = 'corporate';
    }

    if (this.doesOrgDomainNameExist === true) {
      this.discoveredCompany = resData?.existingDomain;
      this.allUsers = Object.values(resData?.usersList);

      this.goToPage(1.5);
    } else if (this.doesOrgDomainNameExist === false) {
      this.onSignUp();
    }
  }

  initiateJoinExistingOrganization(id: number) {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.joinExistingOrganization.bind(this, id)
    );
  }

  joinExistingOrganization(id: number) {
    this.store.dispatch(
      AuthActions.JoinExistingOrganization({
        payload: {
          email: this.registrationForm.value.email,
          password: this.registrationForm.value.password,
          firstName: this.registrationForm.value.firstname,
          lastName: this.registrationForm.value.lastname,
          subscriberId: id,
        },
      })
    );
  }

  onSubmit() {
    this.onClickContinue();
  }

  onSignUp() {
    let deviceGuid = localStorage.getItem('deviceGuid');
    if (deviceGuid === undefined || deviceGuid === null) {
      deviceGuid = this.helperService.generateGUID();
      localStorage.setItem('deviceGuid', deviceGuid);
    }

    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.store.dispatch(
      AuthActions.StartRegistration({
        payload: {
          subscription: {
            subscriberId: this.subscription?.subscriberId,
            productId: this.subscription?.productId,
            subscriptionPlanPricingId: this.pricing?.id,
            subscriberName: '',
            subscriptionPlanId: this.subscription?.id,
            initialSubscriptionPlanId: 0,
            renewedSubscriptionPlanId: 0,
            subscriptionType:
              this.subscription?.subscriptionType ??
              SubscriptionPlanTypeEnum.Standard,
            currencyId: this.pricing?.currencyId,
            paymentChannelId: 0,
            currencyCode: this.pricing?.currencyCode,
            noOfMonthOrYears: 1,
            subscriptionFrequency: SubscriptionFrequency.monthly,
            paymentPeriod: SubscriptionFrequency.monthly,
            numberOfUsers: this.subscription?.numberOfUsers,
            amount: this.pricing?.amount,
            isFreeTrial: true,
            userId: '',
            autoRenew: false,
          },
          subscriber: {
            subscriberType:
              this.registrationType === 'individual'
                ? SubscriberTypeEnum.individual
                : SubscriberTypeEnum.corporate,
            contactEmail: (this.registrationForm.value.email as string)
              .toLowerCase()
              .trim(),
            subscriberCode: '',
            email: (this.registrationForm.value.email as string)
              .toLowerCase()
              .trim(),
            thirdPartyType: null,
            deviceId: '',
            ipAddress: this.ipAddress,
            deviceName: '',
            browser: `${this.helperService.getBrowserName()}${deviceGuid}`,
            deviceLocation: this.location,
            deviceType: 0,
            updateSubscriber: true,
          },
        },
      })
    );
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  goToPage(page: number) {
    this.page = page;
  }

  // Third Party Sign Up
  onSignUpWithGoogle() {
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((userData) => {
        if (userData.email) {
          this.helperService.manageDeveloperTokenAndCallFunction(
            this.signUpWithGoogle.bind(this, userData)
          );
        } else {
          this.onThirdPartySignUpError('Google');
        }
      });
  }

  signUpWithGoogle(userData: SocialUser) {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.registrationType === 'individual';

    this.thirdPartyDispatch(userData, ThirdPartyTypeEnum.Google);
  }

  onSignUpWithLinkedIn() {
    this.helperService.manageDeveloperTokenAndCallFunction(
      this.signUpWithLinkedIn.bind(this)
    );
  }

  signUpWithLinkedIn() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.registrationType === 'individual';

    this.store.dispatch(AuthActions.LinkedInSignUp());
  }

  onSignUpWithMicrosoft() {
    this.authService.initializeLoginWithAzureAD();
  }

  manageAzureADLoginAfterRedirect() {
    this._subscription.add(
      this.msalBroadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          )
        )
        .subscribe(() => {
          const userData = this.msalService.instance.getActiveAccount();

          if (userData && this.subscription && this.pricing) {
            this.store.dispatch(AuthActions.IsLoading({ payload: true }));

            this.registrationType === 'individual';

            this.initiateCheckUpDomainName({
              isThirdParty: true,
              thirdPartyType: ThirdPartyTypeEnum.Microsoft,
              firstName: userData?.name?.split(' ')[0]!,
              lastName: userData?.name?.split(' ')[1]!,
              email: userData?.username,
              photoUrl: '',
            });
          }
        })
    );
  }

  onThirdPartySignUpError(thirdPartyType: string) {
    this.dialog.open(ThirdPartyErrorComponent, {
      data: thirdPartyType,
      disableClose: true,
      panelClass: 'flwmn-dialog',
      backdropClass: 'flwmn-dialog-backdrop',
    });
  }

  thirdPartyDispatch(userData: any, thirdPartyType: ThirdPartyTypeEnum) {
    let deviceGuid = localStorage.getItem('deviceGuid');

    if (deviceGuid === undefined || deviceGuid === null) {
      deviceGuid = this.helperService.generateGUID();

      localStorage.setItem('deviceGuid', deviceGuid);
    }

    this.store.dispatch(
      AuthActions.StartRegistration({
        payload: {
          subscription: {
            subscriberId: this.subscription?.subscriberId,
            productId: this.subscription?.productId,
            subscriptionPlanPricingId: this.pricing?.id,
            subscriberName: `${userData.firstName} ${userData.lastName}`,
            subscriptionPlanId: this.subscription?.id,
            initialSubscriptionPlanId: 0,
            renewedSubscriptionPlanId: 0,
            subscriptionType:
              this.subscription?.subscriptionType ??
              SubscriptionPlanTypeEnum.Standard,
            currencyId: this.pricing?.currencyId,
            paymentChannelId: 0,
            currencyCode: this.pricing?.currencyCode,
            noOfMonthOrYears: 1,
            subscriptionFrequency: SubscriptionFrequency.monthly,
            paymentPeriod: SubscriptionFrequency.monthly,
            numberOfUsers: this.subscription?.numberOfUsers,
            amount: this.pricing?.amount,
            isFreeTrial: true,
            userId: '',
            autoRenew: false,
          },
          subscriber: {
            subscriberType: SubscriberTypeEnum.individual,
            contactEmail: userData?.email,
            subscriberCode: '',
            email: userData?.email,
            thirdPartyType: thirdPartyType,
            deviceId: '',
            ipAddress: this.ipAddress,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            deviceName: '',
            browser: `${this.helperService.getBrowserName()}${deviceGuid}`,
            deviceLocation: this.location,
            deviceType: 0,
            updateSubscriber: true,
            profilePicture: userData?.photoUrl,
            referrer: 5,
            latitude: `${this.latitude}`,
            longitude: `${this.longitude}`,
          },
        },
      })
    );
  }

  listenToAuthRegisterEffects() {
    this._subscription.add(
      this.authEffects.authStartRegister$.subscribe((resData: any) => {
        if (resData !== null) {
          if (resData.succeeded === true) {
            const email = resData?.entity?.user?.email;

            if (email) {
              this.router.navigate([`register/welcome-onboard/${email}`]);
            }
          }
        }
      })
    );
  }

  getErrorMessage(instance: string) {
    if (
      instance === 'email' &&
      this.registrationFormControls.email.hasError('required')
    ) {
      return 'Please enter your email';
    } else if (
      instance === 'email' &&
      this.registrationFormControls.email.hasError('email')
    ) {
      return 'Sorry, this is not a valid email';
    }
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
