import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Archive configuration ] Reset Store');

export const IsLoading = createAction(
  '[Archive Configuration ] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetArchiveConfigurations = createAction(
  '[Archive Configuration ] Get Archive Configuration ',
  props<{
    payload: {
      skip: number;
      take: number;
      subscriberId?: number;
      userId?: string;
    };
  }>()
);

export const SaveArchiveConfiguration = createAction(
  '[Archive configuration ] Save Archive Configuration',
  props<{
    payload: any;
  }>()
);

export const activateConfiguration = createAction(
  'Archive configuration] Activate Archive configuration',
  props<{
    payload: {
      guid?: string;
      subscriberId?: number;
      userId?: string;
    };
  }>()
);

export const deactivateConfiguration = createAction(
  '[Archive configuration] Deactivate Archive configuration',
  props<{
    payload: {
      guid?: string;
      subscriberId?: number;
      userId?: string;
    };
  }>()
);

export const deleteConfiguration = createAction(
  '[Archive configuration] Delete Archive configuration',
  props<{
    payload: {
      guid?: string;
      subscriberId?: number;
      userId?: string;
    };
  }>()
);

export const CreateArchiveConfiguration = createAction(
  '[Archive configuration ] Create Archive configuration ',
  props<{
    payload: any;
  }>()
);

export const UpdateArchiveConfiguration = createAction(
  '[Archive configuration ] Update Archive configuration ',
  props<{
    payload: any;
  }>()
);
