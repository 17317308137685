import { createAction, props } from '@ngrx/store';
import { User, EditUserPayload } from '../../interfaces/index';
import { userReducerState } from './user.reducer';

export const ResetStore = createAction('[User] Reset Store');

export const IsLoading = createAction(
  '[User] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetAllUsers = createAction(
  '[User] Get All Users',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string;
    };
  }>()
);

export const SaveUsers = createAction(
  '[User] Save Users',
  props<{ payload: userReducerState['users'] }>()
);

export const GetAllUsersWithRequest = createAction(
  '[User] Get All Users With Request',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string | null;
      filterValue?: string[];
    };
  }>()
);

export const SaveUsersWithRequest = createAction(
  '[User] Save Users With Request',
  props<{ payload: userReducerState['usersWithRequest'] }>()
);

export const GetActiveUsersCount = createAction('[User] Get Users Count');

export const GetActiveUsers = createAction(
  '[User ] Get Active Users',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue: string | null;
      filterValue: string[];
      userId?: string;
      subscriberId?: number;
    };
  }>()
);

export const SaveActiveUsers = createAction(
  '[User] Save Active Users',
  props<{ payload: userReducerState['activeUsers'] }>()
);

export const GetInactiveUsers = createAction(
  '[User] Get Inactive User',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue: string | null;
      filterValue: string[];
      userId?: string;
      subscriberId?: number;
    };
  }>()
);

export const SaveInactiveUsers = createAction(
  '[User] Save Inactive Users',
  props<{ payload: userReducerState['inactiveUsers'] }>()
);

export const GetInvitedUsers = createAction(
  '[User] Get Invited Users',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string | null;
      filterValue?: string[];
    };
  }>()
);

export const SaveInvitedUsers = createAction(
  '[User] Save Invited Users',
  props<{ payload: userReducerState['invitedUsers'] }>()
);

export const GetRejectedUsers = createAction(
  '[User] Get Rejected Users',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string | null;
      filterValue?: string[];
    };
  }>()
);

export const SaveRejectedUsers = createAction(
  '[User] Save Rejected Users',
  props<{ payload: userReducerState['rejectedUsers'] }>()
);

export const GetLicensedUsers = createAction(
  '[User] Get Licensed Users',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string | null;
      filterValue?: string[];
    };
  }>()
);

export const SaveLicensedUsers = createAction(
  '[User] Save Licensed Users',
  props<{ payload: userReducerState['licensedUsers'] }>()
);

export const GetUnLicensedUsers = createAction(
  '[User] Get UnLicensed Users',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string | null;
      filterValue?: string[];
    };
  }>()
);

export const SaveUnLicensedUsers = createAction(
  '[User] Save UnLicensed Users',
  props<{ payload: userReducerState['unlicensedUsers'] }>()
);

export const GetUserById = createAction(
  '[User] Get a User',
  props<{
    payload: {
      id: string;
      isSubscriberNewlyCreated?: boolean;
      userId?: string;
      subscriberId?: number;
    };
  }>()
);

export const GetAllUsersBySubsidiaryId = createAction(
  '[User] Get All Users By subsidiaryId',
  props<{
    payload: {
      subsidiaryGuid: string;
    };
  }>()
);

export const SaveAUser = createAction(
  '[User] Save A User',
  props<{ payload: User }>()
);

export const EditUser = createAction(
  '[User] Edit a User',
  props<{
    payload: EditUserPayload;
    instance: 'my profile' | 'default';
  }>()
);

export const ChangeUserStatus = createAction(
  '[User] Change User Status',
  props<{
    payload: {
      userId: number;
    };
    instance:
      | 'allUsersWithRequest'
      | 'activeUsers'
      | 'inactiveUsers'
      | 'invitedUsers'
      | 'rejectedUsers'
      | 'licensedUsers'
      | 'unlicensedUsers';
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const DeactivateUsersStatus = createAction(
  '[User] Deactivate Users Status',
  props<{
    payload: {
      usersId: any[];
    };
  }>()
);

export const InviteUserByLink = createAction(
  '[User] Invite User Via Link',
  props<{
    payload: {
      roleId: number;
      recipientEmail: string;
      userId?: string;
      subscriberId?: number;
      subsidiaryId: string | null;
    };
  }>()
);

export const InviteMultipleUsers = createAction(
  '[User] Invite Multiple Users',
  props<{
    payload: {
      roleId: number[];
      invitedRecipients: any;
      userId?: string;
      subscriberId?: number;
      subsidiaryId?: number;
    };
  }>()
);

export const UploadBulkInvite = createAction(
  '[User] Upload Bulk Invite Users',
  props<{
    payload: {
      userId: string;
      csvFile: string;
      subscriberId: number;
      subsidiaryId: string | null;
    };
  }>()
);

export const UploadBulkLicense = createAction(
  '[User] Upload Bulk Invite Users',
  props<{
    payload: {
      subscriptionId: number;
      csvFile: string;
    };
  }>()
);

export const ConfirmInviteLink = createAction(
  '[User] Confirm Validity of an Email Invite Link',
  props<{
    payload: {
      link: string;
    };
  }>()
);

export const ApproveOrRejectMembershipRequest = createAction(
  '[User] Approve or Reject Request To Join Company',
  props<{
    payload: {
      response: boolean;
      email: string;
      roleId: number;
    };
  }>()
);

export const GetByEmail = createAction(
  '[User] Get User By Email',
  props<{
    payload: {
      email: string;
    };
  }>()
);

export const SaveByEmail = createAction(
  '[User] Save User By Email',
  props<{
    payload: userReducerState['email'];
  }>()
);
