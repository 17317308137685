import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  map,
  share,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromApp from '../app/app.reducer';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import * as UserActions from './user.actions';
import { environment } from 'src/environments/environment';
import { Notification } from '../../interfaces';
import { NotificationService } from '../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import {
  USE_DEVELOPER_TOKEN,
  USE_ACCESS_TOKEN,
} from '../../interceptors/app.interceptor.service';
import { HelperService } from '../../services/helper.service';
import { UserCreationStatus } from '../../enums';

@Injectable()
export class UserEffects {
  defaultUserPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(UserActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.GetAllUsers),
        withLatestFrom(this.store.select('auth')),
        switchMap(([userData, authState]) => {
          let params = new HttpParams();

          if (userData.payload.searchValue) {
            params = params.set('searchValue', userData.payload.searchValue);
          }

          return this.http
            .get(
              `${environment.onyxDocAuthUrl}/Users/getbysubscriberId/${authState.user.SubscriberId}/${authState.user.UserId}/${userData.payload.skip}/${userData.payload.take}`,
              {
                params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(UserActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    UserActions.SaveUsers({
                      payload: resData.entity,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch(
                    UserActions.SaveUsers({
                      payload: {
                        users: [],
                        total: 0,
                      },
                    })
                  );
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[User][CatchError] Failed To Get All Users ${errorRes.message}`
                );
              }),
              share()
            );
        })
      ),
    { dispatch: false }
  );

  getAllUsersWithRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetAllUsersWithRequest),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http

          .post(
            `${environment.onyxDocAuthUrl}/Users/getusersbyusercreationstatus`,
            {
              subscriberId: authState.user.SubscriberId,
              userCreationStatus: UserCreationStatus.AccessRequest,
              userId: authState.user.UserId,
              ...userData.payload,
            }
          )

          .pipe(
            map((resData: any) => {
              this.store.dispatch(UserActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                return UserActions.SaveUsersWithRequest({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return UserActions.SaveUsersWithRequest({
                  payload: {
                    users: [],
                    total: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Get All Users With Request ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getActiveUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetActiveUsers),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http

          .post(
            `${environment.onyxDocAuthUrl}/Users/getallactiveusers`,
            {
              userId: authState?.user?.UserId,
              subscriberId: authState.user.SubscriberId,
              ...userData.payload,
            },

            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(UserActions.IsLoading({ payload: false }));
              if (resData.succeeded === true) {
                return UserActions.SaveActiveUsers({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return UserActions.SaveActiveUsers({
                  payload: {
                    users: [],
                    total: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Get Active User  ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getInactiveUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetInactiveUsers),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Users/getallinactiveusers`,
            {
              userId: authState?.user?.UserId,
              subscriberId: authState.user.SubscriberId,
              ...userData.payload,
            },

            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(UserActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                return UserActions.SaveInactiveUsers({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return UserActions.SaveInactiveUsers({
                  payload: {
                    users: [],
                    total: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Get Inactive Users ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getActiveUsersCount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.GetActiveUsersCount),
        withLatestFrom(this.store.select('auth')),
        switchMap(([userData, authState]) => {
          return this.http
            .get(
              `${environment.onyxDocAuthUrl}/Users/getactiveuserscount/${authState.user.SubscriberId}/${authState.user.UserId}`
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(UserActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return { type: '[User] Failed To Get Active User Count' };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[User][CatchError] Failed To Get Active User Count ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getInvitedUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetInvitedUsers),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http

          .post(
            `${environment.onyxDocAuthUrl}/Users/getusersbyusercreationstatus`,
            {
              subscriberId: authState.user.SubscriberId,
              userCreationStatus: UserCreationStatus.Invited,
              userId: authState.user.UserId,
              ...userData.payload,
            }
          )

          .pipe(
            map((resData: any) => {
              this.store.dispatch(UserActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                return UserActions.SaveInvitedUsers({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return UserActions.SaveInvitedUsers({
                  payload: {
                    users: [],
                    total: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Get Invited Users ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getRejectedUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetRejectedUsers),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http

          .post(
            `${environment.onyxDocAuthUrl}/Users/getusersbyusercreationstatus`,
            {
              subscriberId: authState.user.SubscriberId,
              userCreationStatus: UserCreationStatus.Rejected,
              userId: authState.user.UserId,
              ...userData.payload,
            }
          )

          .pipe(
            map((resData: any) => {
              this.store.dispatch(UserActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                return UserActions.SaveRejectedUsers({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return UserActions.SaveRejectedUsers({
                  payload: {
                    users: [],
                    total: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Get Rejected Users ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getLicensedUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetLicensedUsers),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Users/getlicensedusersbysubscriberid`,
            {
              subscriberId: authState?.user?.SubscriberId,
              userId: authState?.user?.UserId,
              ...userData.payload,
            }
          )

          .pipe(
            map((resData: any) => {
              this.store.dispatch(UserActions.IsLoading({ payload: false }));

              if (resData.succeeded === true && resData.entity) {
                return UserActions.SaveLicensedUsers({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return UserActions.SaveLicensedUsers({
                  payload: {
                    users: [],
                    total: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Get Rejected Users ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getUnLicensedUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetUnLicensedUsers),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Users/getunlicensedusersbysubscriberid`,
            {
              subscriberId: authState?.user?.SubscriberId,
              userId: authState?.user?.UserId,
              ...userData.payload,
            }
          )

          .pipe(
            map((resData: any) => {
              this.store.dispatch(UserActions.IsLoading({ payload: false }));

              if (resData.succeeded === true && resData.entity) {
                return UserActions.SaveUnLicensedUsers({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return UserActions.SaveUnLicensedUsers({
                  payload: {
                    users: [],
                    total: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Get unlicensed Users ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  allUsersBySubsidiaryId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.GetAllUsersBySubsidiaryId),
        withLatestFrom(this.store.select('auth')),
        switchMap(([userData, authState]) => {
          return this.http
            .get(
              `${environment.onyxDocAuthUrl}/Users/usersbysubsidiary/${userData.payload.subsidiaryGuid}/${authState.user.SubscriberId}`,

              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(UserActions.IsLoading({ payload: false }));
                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[User] Get User By Subsidiary Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[User] Failed to get users By Subsidiary',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[User][CatchError] Failed To Get All User By Subsidiary  ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getUserById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.GetUserById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([userData, authState]) => {
          const params = new HttpParams().set(
            'isSubscriberNewlyCreated',
            userData?.payload?.isSubscriberNewlyCreated ?? false
          );

          return this.http
            .get(
              `${environment.onyxDocAuthUrl}/Users/getbyid/${
                userData?.payload?.id
              }/${
                userData?.payload?.subscriberId ?? authState?.user?.SubscriberId
              }/${userData?.payload?.userId ?? authState?.user?.UserId}`,
              {
                context: new HttpContext()
                  .set(
                    USE_DEVELOPER_TOKEN,
                    userData.payload.isSubscriberNewlyCreated ? true : false
                  )
                  .set(
                    USE_ACCESS_TOKEN,
                    userData.payload.isSubscriberNewlyCreated ? false : true
                  ),

                params,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(UserActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[User] Get User By Id Was Succesful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[User] Failed To Get User By Id',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[User][CatchError] Failed To Get User By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  editAUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.EditUser),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Users/update/${authState.user.UserId}`,
            {
              ...userData.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(UserActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                this.store.dispatch(AuthActions.GetUserProfileDetailsById());

                const notification: Notification = {
                  state: 'success',
                  message: 'User update was successful',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                // this.router.navigate(['app/account-settings/team']);

                if (userData.instance === 'my profile') {
                  this.store.dispatch(
                    UserActions.GetUserById({
                      payload: {
                        id: authState.user.UserId,
                        isSubscriberNewlyCreated: false,
                      },
                    })
                  );
                } else if (userData.instance === 'default') {
                  this.store.dispatch(
                    UserActions.GetAllUsers(this.defaultUserPaginationPayload)
                  );
                }

                return { type: '[User] Edit User Was Succesful' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[User] Failed To Edit A User' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Edit A Usrt ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  ChangeUserStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.ChangeUserStatus),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(`${environment.onyxDocAuthUrl}/Users/changeuserstatus`, {
            subscriberId: authState.user.SubscriberId,
            userId: userData.payload.userId,
          })
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'User status was updated successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(UserActions.GetActiveUsersCount());

                if (userData.instance === 'allUsersWithRequest') {
                  this.store.dispatch(
                    UserActions.GetAllUsersWithRequest({
                      payload: {
                        skip: userData.paginationPayload.skip,
                        take: userData.paginationPayload.take,
                      },
                    })
                  );
                } else if (userData.instance === 'activeUsers') {
                  this.store.dispatch(
                    UserActions.GetActiveUsers({
                      payload: {
                        skip: userData.paginationPayload.skip,
                        take: userData.paginationPayload.take,
                        searchValue: null,
                        filterValue: [],
                      },
                    })
                  );
                } else if (userData.instance === 'inactiveUsers') {
                  this.store.dispatch(
                    UserActions.GetInactiveUsers({
                      payload: {
                        skip: userData.paginationPayload.skip,
                        take: userData.paginationPayload.take,
                        searchValue: null,
                        filterValue: [],
                      },
                    })
                  );
                } else if (userData.instance === 'invitedUsers') {
                  this.store.dispatch(
                    UserActions.GetInvitedUsers({
                      payload: {
                        skip: userData.paginationPayload.skip,
                        take: userData.paginationPayload.take,
                        searchValue: null,
                        filterValue: [],
                      },
                    })
                  );
                } else if (userData.instance === 'rejectedUsers') {
                  this.store.dispatch(
                    UserActions.GetRejectedUsers({
                      payload: {
                        skip: userData.paginationPayload.skip,
                        take: userData.paginationPayload.take,
                      },
                    })
                  );
                }

                return {
                  type: '[Auth] Change User Status Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Auth] Failed To Change User Status',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Get User Status ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deactivateUsersStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.DeactivateUsersStatus),
        withLatestFrom(this.store.select('auth')),
        switchMap(([userData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/Users/changeusersstatus`,
              userData.payload
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'User deactivated successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[User][CatchError] Failed To Deactivate User Status ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  inviteMultiplesUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.InviteMultipleUsers),
        withLatestFrom(this.store.select('auth')),
        switchMap(([userData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/UserInviteLink/sendinvitetomultipleusers`,
              {
                ...userData.payload,
                userId: userData?.payload?.userId ?? authState.user.UserId,
                subscriberId:
                  userData?.payload?.subscriberId ??
                  authState.user.SubscriberId,
              },
              {
                context: new HttpContext().set(
                  USE_DEVELOPER_TOKEN,
                  userData.payload.userId ? true : false
                ),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(UserActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  this.store.dispatch(
                    UserActions.GetAllUsers({ payload: { skip: 0, take: 10 } })
                  );

                  this.store.dispatch({
                    type: '[User] Invite Multiple Users Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[User] Failed To Invite Multiple Users',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[User][CatchError] Failed To Invite Multiple Users ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  uploadBulkInviteUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UploadBulkInvite),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/UserInviteLink/sendinvitebulkupload`,
            {
              ...userData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            },
            {
              context: new HttpContext().set(
                USE_DEVELOPER_TOKEN,
                userData.payload.userId ? true : false
              ),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(UserActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                this.store.dispatch(
                  UserActions.GetAllUsers({ payload: { skip: 0, take: 10 } })
                );

                return {
                  type: '[User]  Upload Bulk Invite Users Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User] Failed To  Upload Bulk Invite Users',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To  Upload Bulk Invite Users ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  confirmInviteLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.ConfirmInviteLink),
      switchMap((userData) => {
        return this.http
          .post(`${environment.onyxDocAuthUrl}/UserInviteLink/confirmlink`, {
            link: userData.payload.link,
          })
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Confirm invite link was successful',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch({
                  type: '[User] Confirm Invite Link Was Successful',
                });

                return resData;
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[User] Failed To Confirm Invite Link' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Confirm Invite Link ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  approveOrRejectMembershipRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.ApproveOrRejectMembershipRequest),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Users/approveorrejectuserrequest`,
            {
              ...userData.payload,
              userId: authState.user.UserId,
              subsriberId: authState.user.SubscriberId,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(UserActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success',
                  true
                );

                this.dialog.closeAll();

                this.store.dispatch(
                  UserActions.GetAllUsers(this.defaultUserPaginationPayload)
                );

                this.store.dispatch(
                  UserActions.GetAllUsersWithRequest(
                    this.defaultUserPaginationPayload
                  )
                );

                return {
                  type: '[User] Approve Or Reject Membership Request Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );

                return {
                  type: '[User] Failed To Approve Or Reject Membership Request',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Approve Or Reject Membership Request ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  inviteUserByLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.InviteUserByLink),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .post(
            `{environment.onyxDocAuthUrl}/UserInviteLink/generatelink`,
            {
              ...userData.payload,
              userId: userData?.payload?.userId ?? authState.user.UserId,
              subscriberId:
                userData?.payload?.subscriberId ?? authState.user.SubscriberId,
              isLinkCopied: true,
            },
            {
              context: new HttpContext().set(
                USE_DEVELOPER_TOKEN,
                userData.payload.userId ? true : false
              ),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(UserActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                navigator.clipboard.writeText(resData.entity);

                const notification: Notification = {
                  state: 'success',
                  message: 'Link copied successfully',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success',
                  true
                );

                this.dialog.closeAll();

                this.store.dispatch(
                  UserActions.GetAllUsers({ payload: { skip: 0, take: 0 } })
                );

                return {
                  type: '[User] Invite User By Link was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User] Failed To Invite User By Link ',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Invite User by Link ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getUserEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetByEmail),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Users/getbyemail/${userData.payload.email}/0`,
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(UserActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                return UserActions.SaveByEmail({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return UserActions.SaveByEmail({
                  payload: null,
                });

                // return {
                //   type: '[User] Failed To Get User By Email ',
                // };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Get User Email ${errorRes.message}`
              );
            })
          );
      })
    )
  );
}
