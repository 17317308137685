import { createReducer, on, Action } from '@ngrx/store';
import * as archiveConfigurationActions from './archive-configuration.actions';

export interface archiveConfigurationReducerState {
  isLoading: boolean;
  allArchiveConfiguration: any;
}

const initialState: archiveConfigurationReducerState = {
  isLoading: false,
  allArchiveConfiguration: null,
};

const archiveConfigurationReducerInternal = createReducer(
  initialState,
  on(archiveConfigurationActions.ResetStore, (state) => ({
    ...initialState,
  })),

  on(archiveConfigurationActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(
    archiveConfigurationActions.SaveArchiveConfiguration,
    (state, { payload }) => ({
      ...state,
      allArchiveConfiguration: payload,
    })
  )
);

export function archiveConfigurationReducer(
  state: archiveConfigurationReducerState | undefined,
  action: Action
) {
  return archiveConfigurationReducerInternal(state, action);
}
