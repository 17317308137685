import { createReducer, Action, on } from '@ngrx/store';
import * as FlowmonoAssistantActions from './flowmono-assistant.actions';

export interface flowmonoAssistanceReducerState {
  isLoading: boolean;
  fileLoading: boolean;
  chatLoading: boolean;
  documentInsights: any;
  documentSummary: any;
  chatHistory: any[] | null;
  questionAndAnswer: any;
  taskId: any;
}

const initialState: flowmonoAssistanceReducerState = {
  isLoading: false,
  fileLoading: false,
  chatLoading: false,
  documentInsights: null,
  documentSummary: null,
  chatHistory: null,
  questionAndAnswer: null,
  taskId: null,
};

const flowmonoAssistanceReducerInternal = createReducer(
  initialState,
  on(FlowmonoAssistantActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(FlowmonoAssistantActions.FileLoading, (state, { payload }) => ({
    ...state,
    fileLoading: payload,
  })),

  on(FlowmonoAssistantActions.ChatLoading, (state, { payload }) => ({
    ...state,
    chatLoading: payload,
  })),

  on(FlowmonoAssistantActions.SaveDocumentInsight, (state, { payload }) => ({
    ...state,
    documentInsights: payload,
  })),

  on(FlowmonoAssistantActions.SaveDocumentSummary, (state, { payload }) => ({
    ...state,
    documentSummary: payload,
  })),

  on(FlowmonoAssistantActions.SaveChatHistory, (state, { payload }) => ({
    ...state,
    chatHistory: payload,
  })),

  on(FlowmonoAssistantActions.SaveQuestion, (state, { payload }) => ({
    ...state,
    questionAndAnswer: payload,
  })),

  on(FlowmonoAssistantActions.SendTaskId, (state, { payload }) => ({
    ...state,
    taskId: payload.taskId,
  }))
);

export function flowmonoAssistanceReducer(
  state: flowmonoAssistanceReducerState | undefined,
  action: Action
) {
  return flowmonoAssistanceReducerInternal(state, action);
}
