import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
  distinctUntilChanged,
} from 'rxjs/operators';
import * as DocumentActions from './document.actions';
import * as UserActions from '../user/user.actions';
import { environment } from 'src/environments/environment';
import {
  DocumentItem,
  GenericResponse,
  ItemWithParent,
  Notification,
} from 'src/app/@core/interfaces/index';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification.service';
import { Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { convertToFormData } from '../../utils/helpers';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { SecurityTypeEnum } from '../../enums';

@Injectable()
export class DocumentEffects {
  defaultUserPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private router: Router,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(DocumentActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllFolders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.GetAllFolders),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/getfoldersbyuserid/${authState.user.Role.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                return DocumentActions.SaveAllFolders({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  DocumentActions.SaveAllFolders({
                    payload: [],
                  })
                );

                return {
                  type: '[Document] Failed To Get All Folders',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Get All Folders ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getAllFoldersJson$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.GetAllFoldersJSON),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http.get<GenericResponse>(`assets/json/folders.json`).pipe(
          map((resData) => {
            if (resData.succeeded === true) {
              return DocumentActions.SaveAllFoldersJSON({
                payload: resData.entity,
              });
            } else {
              const notification: Notification = {
                state: 'error',
                message: resData.message || resData.messages[0],
              };

              this.notificationService.openNotification(
                notification,
                'flwmn-notification-error'
              );

              this.store.dispatch(
                DocumentActions.SaveAllFoldersJSON({
                  payload: [],
                })
              );

              return {
                type: '[Document] Failed To Get All Folders JSON',
              };
            }
          }),
          catchError((errorRes: any) => {
            return this.handleCatchError(
              errorRes,
              `[Document][CatchError] Failed To Get All Folders JSON ${errorRes.message}`
            );
          })
        );
      })
    );
  });
  getAllDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.GetAllDocuments),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/getdocumentlist/${authState.user.Role.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                return DocumentActions.SaveAllDocuments({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  DocumentActions.SaveAllDocuments({
                    payload: [],
                  })
                );

                return {
                  type: '[Document] Failed To Get All Documents',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Get All Documents ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.CreateFolder),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/createfolder`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              ...documentData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.name,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Document] Create Folder Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Create Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Create Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  updateFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.UpdateFolder),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/editfolder`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              ...documentData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.id,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Document] Update Folder Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Update Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Update Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  duplicateFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.DuplicateFolder),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/duplicatefolder`,
            {
              id: documentData.payload.folderId,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.folderId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );
                return {
                  type: '[Document] Folder Was Duplicated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Duplicate Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Duplicate Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  duplicateDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.DuplicateDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/duplicatedocument`,
            {
              ...documentData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Document] Document Was Duplicated Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Duplicate Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Duplicate Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  shareFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.ShareFolder),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/sharefolder`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              ...documentData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.folderId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Document] Folder Was Shared Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Share Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Share Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  shareDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.ShareDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        const encryptedDocumentData =
          this.helperService.AES_Encryption_Decryption(
            'encrypt',
            JSON.stringify({
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            })
          );
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/sharedocument`,
            {
              shareDocumentRequest: encryptedDocumentData,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Document] Document Was Shared Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Share Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Share Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  moveFiles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.MoveFiles),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/movefiles`,
            {
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.destinationFolderId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Document] Files Were Moved Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Move Files',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Move Files ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  archiveFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.ArchiveFolder),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/archivefolder`,
            {
              id: documentData.payload.folderId,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.folderId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Document] Folder Was Archived Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Archive Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Archive Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  archiveDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.ArchiveDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        const encryptedDocumentData =
          this.helperService.AES_Encryption_Decryption(
            'encrypt',
            JSON.stringify({
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            })
          );
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/archivedocument`,
            {
              archiveDocumentRequest: encryptedDocumentData,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Document] Document Was Archived Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Archive Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Archive Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  unarchiveFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.UnarchiveFolder),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/unarchivefolder`,
            {
              id: documentData.payload.folderId,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.folderId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Document] Folder Was Unarchived Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Unarchive Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Unarchive Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  unarchiveDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.UnarchiveDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/unarchivedocument`,
            {
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Document] Document Was Unarchived Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Unarchive Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Unarchive Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deleteFolder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.DeleteFolder),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/deletefolder`,
            {
              id: documentData.payload.folderId,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              moveToTrash: documentData.payload.moveToTrash,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.folderId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return { type: '[Document] Document Was Deleted Successfully' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Delete Folder',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Delete Folder ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getFolderContentByFolderId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.getFolderContentById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Folders/getchildrenfiles/${documentData.payload.folderId}/${authState.user?.SubscriberId}/${authState.user?.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return DocumentActions.SaveFolderContent({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  DocumentActions.SaveFolderContent({
                    payload: null,
                  })
                );

                return {
                  type: '[Document] Failed To Get Folder Content By Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Get Folder Content By Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  uploadDocument$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DocumentActions.UploadDocument),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          const encryptedDocumentData =
            this.helperService.AES_Encryption_Decryption(
              'encrypt',
              JSON.stringify({
                ...documentData.payload,
                userId: authState.user.UserId,
                email: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              })
            );
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/uploaddocument`,
              {
                uploadDocumentRequest: encryptedDocumentData,
              },
              {
                reportProgress: true,
                observe: 'events',
              }
            )
            .pipe(
              map((event: any) => {
                this.store.dispatch(
                  DocumentActions.IsLoading({ payload: false })
                );

                if (event instanceof HttpResponse) {
                  if (event.body.succeeded) {
                    const notification: Notification = {
                      state: 'success',
                      message: 'Document was successfully uploaded',
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-success'
                    );

                    this.store.dispatch(
                      DocumentActions.ActionSuccess({
                        payload: {
                          action: documentData.payload.name,
                          uniqueId: this.generateRandomNumbers(),
                        },
                      })
                    );

                    this.store.dispatch({
                      type: '[Document] Upload Document Was Successful ',
                    });
                  } else {
                    const notification: Notification = {
                      state: 'error',
                      message: 'Failed To Upload Document. Please try again.',
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-error'
                    );

                    this.store.dispatch({
                      type: '[Document] Failed To Upload Document',
                    });
                  }
                }
                // if (resData.succeeded === true) {
                //   this.store.dispatch(
                //     DocumentActions.ActionSuccess({ payload: 'resData.message' })
                //   );

                //   const notification: Notification = {
                //     state: 'success',
                //     message: 'Document was successfully uploaded',
                //   };

                //   this.notificationService.openSnackBar(
                //     notification,
                //     'flwmn-notification-success'
                //   );

                //   return {
                //     type: '[Document] Upload Document Was Successful ',
                //   };
                // } else {
                //   this.store.dispatch(
                //     DocumentActions.IsLoading({ payload: false })
                //   );

                //   const notification: Notification = {
                //     state: 'error',
                //     message: 'Failed To Upload Document. Please try again.',
                //   };

                //   this.notificationService.openSnackBar(
                //     notification,
                //     'flwmn-notification-error'
                //   );

                //   return {
                //     type: '[Document] Failed To Upload Document',
                //   };
                // }

                return event;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Document][CatchError] Failed To Upload Document ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  uploadDocumentsUsingIformFile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DocumentActions.UploadDocumentsUsingIformFile),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAlternateDocumentUrl}/Documents/uploaddocumentsusingiformfile`,
              convertToFormData({
                ...documentData.payload,
                userId: authState.user.UserId,
                email: authState.user.Email,
                subscriberId: authState.user.SubscriberId,
              }),
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
                reportProgress: true,
                observe: 'events',
              }
            )
            .pipe(
              map((event) => {
                this.store.dispatch(
                  DocumentActions.IsLoading({ payload: false })
                );

                if (event instanceof HttpResponse) {
                  if (event.body.succeeded) {
                    const notification: Notification = {
                      state: 'success',
                      message: 'Document was successfully uploaded',
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-success'
                    );

                    this.store.dispatch(
                      DocumentActions.ActionSuccess({
                        payload: {
                          action: documentData.payload.destinationFolderId,
                          uniqueId: this.generateRandomNumbers(),
                        },
                      })
                    );

                    this.store.dispatch({
                      type: '[Document] Upload Documents Using Iform File Was Successful ',
                    });
                  } else {
                    const notification: Notification = {
                      state: 'error',
                      message: 'Failed to upload document. Please try again.',
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-error'
                    );

                    this.store.dispatch({
                      type: '[Document] Failed To Upload Documents Using Iform File',
                    });
                  }
                }

                return event;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Editor][CatchError] Failed To Upload Documents Using Iform File ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateDocumentName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.UpdateDocumentName),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/updatedocumentname`,
            {
              ...documentData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();
                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Document] Update Document Name Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Update Document Name',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Update Document Name ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deleteDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.DeleteDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/deletedocument`,
            {
              ...documentData.payload,
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Document] Delete Document Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Delete Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Delete Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getAuditParameter$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DocumentActions.GetAuditParameter),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/AuditTrails/getauditparameters/${authState.user.UserId}/${authState.user.SubscriberId}`
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  DocumentActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Document] Audit paramter retrived successfully',
                  });

                  return resData.entity;
                } else {
                  // this.store.dispatch(
                  //   GeneralActions.SetNotification({
                  //     payload: {
                  //       isSuccess: false,
                  //       message: 'Failed to Change subscription',
                  //     },
                  //   })
                  // );

                  this.store.dispatch({
                    type: '[Document] Failed To Retrieve Audit Paramater',
                  });

                  return resData.entity;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Document][CatchError] Failed To Retrieve Audit Parameter ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createFeedback$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.CreateFeedback),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/DocumentFeedback/createfeedback`,
            {
              ...documentData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                return { type: '[Document] Feedback Was Created Successfully' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Create Feedback',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Create Feedback ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getCommentOnDocumentById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.GetCommentOnDocumentById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Comments/getcommentsbydocumentid/${authState.user?.SubscriberId}/${authState.user?.UserId}/${documentData.payload.documentId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return DocumentActions.SaveCommentOnDocument({
                  payload: resData.entity,
                });
              } else {
                // this.store.dispatch(
                //   GeneralActions.SetNotification({
                //     payload: {
                //       isSuccess: false,
                //       message: resData.message || resData.messages[0],
                //     },
                //   })
                // );

                this.store.dispatch(
                  DocumentActions.SaveCommentOnDocument({
                    payload: null,
                  })
                );

                return {
                  type: '[Document] Failed To Get Comment On Document By Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Get Comment On Document By Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  // Address Book Actions

  createAddressBook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.CreateAddressBook),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/AddressBooks/create`,
            {
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                DocumentActions.IsLoading({
                  payload: false,
                })
              );

              if (resData.succeeded === true) {
                this.store.dispatch({
                  type: '[Document] Address Book Creation Successful',
                });

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.GetAddressBookBySubscriberId()
                );

                this.router.navigate(['/app/contact']);

                return {
                  type: '[Document] Create Address Book Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Create Address Book',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Create Address Book ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  updateAddressBook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.UpdateAddressBook),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/AddressBooks/update`,
            {
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                DocumentActions.IsLoading({
                  payload: false,
                })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.router.navigate(['/app/contact']);

                this.store.dispatch(
                  DocumentActions.GetAddressBookBySubscriberId()
                );

                return {
                  type: '[Document] Update Address Book Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Update Address Book',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Update Address Book ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getAddressBookBySubscriberId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentActions.GetAddressBookBySubscriberId),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/AddressBooks/getaddressbooksbysubscriberid/${authState.user.SubscriberId}/0/1000`
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    DocumentActions.IsLoading({
                      payload: false,
                    })
                  );

                  return this.store.dispatch(
                    DocumentActions.SaveAddressBookBySubscriberId({
                      payload: resData.entity.entities.reverse(),
                    })
                  );
                } else {
                  this.store.dispatch({
                    type: '[Document] Failed To Update Address Book',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Document][CatchError] Failed To Update Address Book ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getContactById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentActions.GetContactById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/AddressBooks/getcontactbyid/${authState.user.SubscriberId}/${authState.user.UserId}/${documentData.payload.id}`
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DocumentActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Document] Get Contact By Id Was Successful',
                  });

                  return resData;
                } else {
                  this.store.dispatch({
                    type: '[Document] Failed To Get Contact By Id',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Document][CatchError] Failed To Get Address Book By Contact Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),

    { dispatch: false }
  );

  updateAddressBooksStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.UpdateAddressBookStatus),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/AddressBooks/updateaddressbookstatus`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              id: documentData.payload.id,
              status: documentData.payload.status,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                this.store.dispatch(
                  DocumentActions.GetAddressBookBySubscriberId()
                );

                return {
                  type: '[Document] Update Address Book Status Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Update Address Book Status',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Update Address Book Status ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getAllCompanyDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.GetAllCompanyDetails),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/AddressBooks/getcompanydetails`
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                return DocumentActions.SaveAllCompanyDetails({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Get All Company Details',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Get All Company Details ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createDocumentPin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DocumentActions.CreateDocumentPin),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocAuthUrl}/Pin/createsecuritypin`,
              {
                subscriberId: authState.user.Role.SubscriberId,
                // documentPin: documentData.payload.documentPin,
                securityCode: documentData.payload.documentPin,
                userId: authState.user.UserId,
                securityType: SecurityTypeEnum.Pin,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  DocumentActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  if (documentData.payload.instance === 'normal') {
                    this.store.dispatch(
                      UserActions.IsLoading({ payload: true })
                    );

                    this.store.dispatch(
                      UserActions.GetUserById({
                        payload: {
                          id: authState.user.UserId,
                        },
                      })
                    );
                  }

                  this.store.dispatch({
                    type: '[Document] Create Document Pin Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Document] Failed To Create Document Pin',
                  });
                }

                return {
                  response: resData,
                  instance: documentData.payload.instance,
                  document: documentData?.additionalPayload?.document,
                };
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Document][CatchError] Failed To Create Document Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateDocumentPin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.UpdateDocumentPin),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/Pin/updatesecuritypin`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              currentPin: documentData.payload.currentDocumentPin,
              newPin: documentData.payload.newDocumentPin,
              userId: authState.user.UserId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                this.store.dispatch(UserActions.IsLoading({ payload: true }));

                this.store.dispatch(
                  UserActions.GetUserById({
                    payload: {
                      id: authState.user.UserId,
                    },
                  })
                );

                return {
                  type: '[Document] Update Document Pin Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Update Document Pin',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Update Document Pin ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  forgotDocumentPin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.ForgotDocumentPin),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/Pin/forgotsecuritypin`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Document] Forgot Document Pin Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Forgot Document Pin Failed',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Forgot Pin FailedFailed ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  resetDocumentPin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.ResetDocumentPin),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/Pin/resetsecuritypin`,
            {
              ...documentData.payload,
              newPin: documentData.payload.newDocumentPin,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                this.router.navigate(['/app/account-settings/profile']);

                // this.store.dispatch(UserActions.IsLoading({ payload: true }));

                // this.store.dispatch(
                //   UserActions.GetUserById({
                //     payload: {
                //       id: authState.user.UserId,
                //     },
                //   })
                // );

                return {
                  type: '[Document] Reset Document Pin Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Reset Document Pin',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Reset Document Pin ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  verifyDocumentPin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DocumentActions.VerifyDocumentPin),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocAuthUrl}/Pin/verifysecuritypin`,
              {
                subscriberId: authState.user.SubscriberId,
                // documentPin: documentData.payload.documentPin,

                securityCode: documentData.payload.documentPin,
                userId: authState.user.UserId,
                securityType: SecurityTypeEnum.Pin,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  DocumentActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Document] Verify Document Pin Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Document] Failed To Verify Document Pin',
                  });
                }

                return resData;

                // return {
                //   response: resData,
                //   document: documentData.payload.document,
                //   nextAction: documentData.payload.nextAction,
                // };
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Document][CatchError] Failed To Verify Document Pin ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  lockDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.LockDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        const encryptedDocumentData =
          this.helperService.AES_Encryption_Decryption(
            'encrypt',
            JSON.stringify({
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            })
          );
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/lockdocument`,
            {
              lockDocumentRequest: encryptedDocumentData,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                return {
                  type: '[Document] Lock Document Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Lock Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Lock Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  unlockDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.UnlockDocument),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        const encryptedDocumentData =
          this.helperService.AES_Encryption_Decryption(
            'encrypt',
            JSON.stringify({
              ...documentData.payload,
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
            })
          );
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/unlockdocument`,
            {
              unlockDocumentRequest: encryptedDocumentData,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.ActionSuccess({
                    payload: {
                      action: documentData.payload.documentId,
                      uniqueId: this.generateRandomNumbers(),
                    },
                  })
                );

                this.dialog.closeAll();

                return {
                  type: '[Document] Unlock Document Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Unlock Document',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To Unlock Document ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  generateRandomNumbers() {
    return Math.floor(Math.random());
  }

  // getAllUserAndSubscribersContact$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(DocumentActions.GetAllUserAndSubscribersContact),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([documentData, authState]) => {
  //       return this.http
  //         .get<GenericResponse>(
  //           `${environment.onyxDocDocumentUrl}/api/AddressBooks/getusersandindividualcontacts/${authState.user.Role.SubscriberId}/${authState.user.UserId}`
  //         )
  //         .pipe(
  //           map((resData) => {
  //             if (resData.succeeded === true) {
  //               return DocumentActions.GetAllUserAndSubscribersContact({
  //                 payload: resData.entity,
  //               });
  //             } else {
  //               this.store.dispatch(
  //                 GeneralActions.SetNotification({
  //                   payload: {
  //                     isSuccess: false,
  //                     message: resData.message || resData.messages[0],
  //                   },
  //                 })
  //               );

  //               this.store.dispatch(
  //                 DocumentActions.GetAllUserAndSubscribersContact({
  //                   payload: [],
  //                 })
  //               );

  //               return {
  //                 type: '[Document] Failed To Get All User and Subscribers ',
  //               };
  //             }
  //           }),
  //           catchError((errorRes: any) => {
  //             return this.handleError(errorRes);
  //           })
  //         );
  //     })
  //   );
  // });

  getUsersAndIndividualContacts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentActions.GetAllUserAndSubscribersContact),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/AddressBooks/getusersandindividualcontacts/${authState.user.Role.SubscriberId}/${authState.user.UserId}`
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    DocumentActions.IsLoading({
                      payload: false,
                    })
                  );

                  return this.store.dispatch(
                    DocumentActions.SaveAllUserAndSubscribersContact({
                      payload: resData.entity,
                    })
                  );
                } else {
                  this.store.dispatch({
                    type: '[Document] Failed To Get User And Individual Contacts',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return resData;
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Document][CatchError] Failed To Get User And Individual Contacts ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  // Document Viewer

  private newFolderData(folders: any[]): DocumentItem[] {
    const mappedData = folders.map((f: any) => {
      return {
        ...f,
        // openState: false,
        // loadingChildren: false,
      };
    });
    return mappedData;
  }

  private mapBaseDocumentItem(folders: any[]): void {
    folders.forEach((documentItem: DocumentItem) => {
      const itemToMap: ItemWithParent = {
        item: {
          ...documentItem,
          //  openState: false
        },
        // parentId: null,
        // parentName: null,
      };

      this.store.dispatch(
        DocumentActions.SetDocumentItemMap({
          payload: { itemId: itemToMap.item.id, itemToMap: itemToMap },
        })
      );
    });
  }

  private mapNonBaseDocumentItem(
    items: any[],
    rootFolder: any,
    parentId?: any,
    parentName?: string
  ): void {
    items.forEach((item: DocumentItem) => {
      const itemToMap: ItemWithParent = {
        item: {
          ...item,
          //  openState: false
        },
        rootFolder,
      };

      this.store.dispatch(
        DocumentActions.SetDocumentItemMap({
          payload: {
            itemId: itemToMap.item.id,
            itemToMap: itemToMap,
          },
        })
      );
    });
  }

  private mapStatusItems(items: any[], rootFolder: string): void {
    items.forEach((item: DocumentItem) => {
      const itemToMap: ItemWithParent = {
        item: {
          ...item,
          id: item.name + environment.documentNameGUID,
          //  openState: false
        },
        rootFolder,
      };

      this.store.dispatch(
        DocumentActions.SetDocumentItemMap({
          payload: { itemId: itemToMap.item.id, itemToMap: itemToMap },
        })
      );
    });
  }

  getBaseFolders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.GetBaseFolders),
      withLatestFrom(this.store.select('document')),
      switchMap(([documentData, doc]) => {
        return this.http.get<GenericResponse>(`assets/json/folders.json`).pipe(
          map((resData) => {
            this.store.dispatch(
              DocumentActions.IsLoading({
                payload: false,
              })
            );

            if (resData.succeeded === true) {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (doc.items.length === 0) {
                this.store.dispatch(
                  DocumentActions.SaveFolders({
                    payload: this.newFolderData(resData.entity),
                  })
                );

                this.mapBaseDocumentItem(resData.entity);
              }

              if (documentData.payload) {
                this.store.dispatch(
                  DocumentActions.IsFetching({
                    payload: true,
                  })
                );

                this.store.dispatch(
                  DocumentActions.GetDocumentStatuses({
                    payload: {
                      documentItem: { id: 'Document', name: 'Document' },
                      defaultStatus: documentData.payload,
                    },
                  })
                );
              }

              return {
                type: '[Document] Folders fetched successfully',
              };
            } else {
              this.store.dispatch(
                DocumentActions.IsLoading({
                  payload: false,
                })
              );

              const notification: Notification = {
                state: 'error',
                message: resData.message || resData.messages[0],
              };

              this.notificationService.openNotification(
                notification,
                'flwmn-notification-error'
              );

              this.store.dispatch(
                DocumentActions.SaveFolders({
                  payload: [],
                })
              );

              return {
                type: '[Document] Failed To Get Folders',
              };
            }
          }),
          catchError((errorRes: any) => {
            return this.handleCatchError(
              errorRes,
              `[Document][CatchError]  Failed To Get Folders ${errorRes.message}`
            );
          })
        );
      })
    );
  });

  getDocumentStatuses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.GetDocumentStatuses),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/getdocumentstatus/${authState.user.Role.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.ToggleItemLoader({
                  payload: {
                    itemId: documentData.payload.documentItem.id,
                    isLoading: false,
                  },
                })
              );

              if (resData.succeeded === true) {
                this.store.dispatch(
                  DocumentActions.SaveCurrentlyActiveIndex({
                    payload: documentData.payload.documentItem.id!,
                  })
                );

                this.mapStatusItems(
                  resData.entity.documents,
                  documentData.payload.documentItem.name!
                );

                const statusWithUniqueId = resData.entity.documents.map(
                  (status: any) => {
                    return {
                      ...status,
                      id: status.name + environment.documentNameGUID,
                      rootFolder: documentData.payload.documentItem.id,
                    };
                  }
                );

                this.store.dispatch(
                  DocumentActions.SaveChildrenItemsInParent({
                    payload: {
                      parentId: documentData.payload.documentItem.id,
                      children: statusWithUniqueId,
                    },
                  })
                );

                // if (documentData.payload.defaultStatus) {
                //   this.store.dispatch(
                //     DocumentActions.GetDocumentByStatus({
                //       payload: { documentItem: documentData.payload.DocumentItem },
                //     })
                //   );
                // }

                // const foundStatusObject = resData.entity.documents.find(
                //   (documentStatus: any) =>
                //     documentStatus.status === documentData.payload.defaultStatus
                // );

                // return DocumentActions.GetDocumentByStatusView({
                //   payload: {
                //     documentItem:
                //       foundStatusObject ?? resData.entity.documents[0],
                //     skip: 0,
                //     take: 10,
                //     searchValue: null,
                //     filterValue: [],
                //   },
                // });

                return {
                  type: '[Document] Get Document Statuses Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Get Document Statuses',
                };
              }
            }),
            catchError((errorRes: any) => {
              this.store.dispatch(
                DocumentActions.ToggleItemLoader({
                  payload: {
                    itemId: documentData.payload.documentItem.id,
                    isLoading: false,
                  },
                })
              );

              return this.handleCatchError(
                errorRes,
                `[Document][CatchError]  Failed To Get Document Statuses ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getDocumentByStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.GetDocumentByStatus),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/getdocumentsbydocumentstatus`,
            {
              userId: authState.user?.UserId,
              subscriberId: authState.user?.SubscriberId,
              documentStatus: documentData?.payload.documentItem?.status,
              skip: documentData?.payload?.skip,
              take: documentData?.payload?.take,
              searchValue: documentData?.payload?.searchValue,
              filterValue: documentData?.payload?.filterValue,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.ToggleItemLoader({
                  payload: {
                    itemId: documentData.payload.documentItem?.id,
                    isLoading: false,
                  },
                })
              );

              if (resData.succeeded === true) {
                this.store.dispatch(
                  DocumentActions.SaveCurrentlyActiveIndex({
                    payload: documentData.payload.documentItem?.id!,
                  })
                );

                // this.store.dispatch(
                //   DocumentActions.SaveCurrentItem({
                //     payload: resData.entity,
                //   })
                // );

                this.mapNonBaseDocumentItem(
                  resData.entity.documents,
                  documentData.payload.documentItem?.id!
                );

                this.store.dispatch(
                  DocumentActions.SaveChildrenItemsInParent({
                    payload: {
                      parentId: documentData.payload.documentItem?.id,
                      children: resData.entity.documents,
                    },
                  })
                );

                return {
                  type: '[Document] Get Document By Status Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  DocumentActions.SaveCurrentItem({
                    payload: null,
                  })
                );

                return {
                  type: '[Document] Failed To  Get Document By Status',
                };
              }
            }),
            catchError((errorRes: any) => {
              DocumentActions.ToggleItemLoader({
                payload: {
                  itemId: documentData.payload.documentItem?.id,
                  isLoading: false,
                },
              });
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To  Get Document By Status ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getRootFoldersByUserId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.GetRootFoldersByUserId),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/getrootfoldersbyuserid/${authState.user.Role.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.ToggleItemLoader({
                  payload: {
                    itemId: documentData.payload.id,
                    isLoading: false,
                  },
                })
              );

              if (resData.succeeded === true) {
                this.store.dispatch(
                  DocumentActions.SaveCurrentlyActiveIndex({
                    payload: documentData.payload.id!,
                  })
                );

                // this.store.dispatch(
                //   DocumentActions.SaveCurrentItem({
                //     payload: resData.entity,
                //   })
                // );

                this.mapNonBaseDocumentItem(
                  resData.entity.folders,
                  documentData.payload.id
                );

                const withRootFolders = resData.entity.folders.map(
                  (status: any) => {
                    return {
                      ...status,
                      rootFolder: documentData.payload.id,
                    };
                  }
                );

                this.store.dispatch(
                  DocumentActions.SaveChildrenItemsInParent({
                    payload: {
                      parentId: documentData.payload.id,
                      children: withRootFolders,
                    },
                  })
                );

                return {
                  type: '[Document] Get Root Folders Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Get Root Folders',
                };
              }
            }),
            catchError((errorRes: any) => {
              this.store.dispatch(
                DocumentActions.ToggleItemLoader({
                  payload: {
                    itemId: documentData.payload.id,
                    isLoading: false,
                  },
                })
              );

              return this.handleCatchError(
                errorRes,
                `[Document][CatchError]  Failed To Get Root Folders ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getFilesByParentFolderId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.GetFilesByParentFolderId),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${
              environment.onyxDocDocumentUrl
            }/Documents/getfilesbyparentfolderid/${
              authState.user?.SubscriberId
            }/${authState.user?.UserId}/${
              documentData?.payload.documentItem?.id
            }/${documentData.payload.skip ?? 0}/${
              documentData.payload.take ?? 0
            }`
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                this.store.dispatch(
                  DocumentActions.ToggleItemLoader({
                    payload: {
                      itemId: documentData.payload.documentItem?.id,
                      isLoading: false,
                    },
                  })
                );

                this.store.dispatch(
                  DocumentActions.SaveCurrentlyActiveIndex({
                    payload: documentData.payload.documentItem?.id!,
                  })
                );

                // this.store.dispatch(
                //   DocumentActions.SaveCurrentItem({
                //     payload: resData.entity,
                //   })
                // );

                this.mapNonBaseDocumentItem(
                  resData.entity.folders,
                  documentData.payload.documentItem?.id!
                );

                // if (resData.entity.files.length === 0) {
                //   this.store.dispatch(
                //     DocumentActions.ToggleItemCaret({
                //       payload: {
                //         itemId: documentData.payload.documentItem?.id,
                //       },
                //     })
                //   );
                // }

                const withRootFolder = resData.entity.folders.map(
                  (folder: any) => {
                    return {
                      ...folder,
                      rootFolder: documentData.payload.documentItem?.rootFolder,
                    };
                  }
                );

                this.store.dispatch(
                  DocumentActions.SaveChildrenItemsInParent({
                    payload: {
                      parentId: documentData.payload.documentItem?.id,
                      children: withRootFolder,
                    },
                  })
                );

                return {
                  type: '[Document] Get Files By Parent Folder Id Was Successful',
                };
              } else {
                this.store.dispatch(
                  DocumentActions.SaveCurrentItem({
                    payload: null,
                  })
                );

                this.store.dispatch({
                  type: '[Document] Failed To Get Files By Parent Folder Id',
                });

                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return resData;
              }
            }),
            catchError((errorRes: any) => {
              this.store.dispatch(
                DocumentActions.ToggleItemLoader({
                  payload: {
                    itemId: documentData.payload.documentItem?.id,
                    isLoading: false,
                  },
                })
              );

              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] To  Get Files By Parent Folder Id ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  //**Get Page Content */

  getDocumentStatusesView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.GetDocumentStatusesView),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/getdocumentstatus/${authState.user.Role.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsFetching({ payload: true })
              );

              if (resData.succeeded === true) {
                return DocumentActions.GetDocumentByStatusView({
                  payload: {
                    documentItem: resData.entity.documents[0],
                    skip: 0,
                    take: 10,
                    searchValue:
                      documentData?.additionalPayload?.searchValue ?? null,
                    filterValue: [],
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Get Document Statuses',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError]  Failed To Get Document Statuses ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getDocumentByStatusView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.GetDocumentByStatusView),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/getdocumentsbydocumentstatus`,
            {
              userId: authState.user?.UserId,
              subscriberId: authState.user?.SubscriberId,
              documentStatus: documentData?.payload.documentItem?.status,
              skip: documentData?.payload?.skip,
              take: documentData?.payload?.take,
              searchValue: documentData?.payload?.searchValue,
              filterValue: documentData?.payload?.filterValue,
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                this.store.dispatch(
                  DocumentActions.SaveCurrentlyActiveIndex({
                    payload: documentData.payload.documentItem?.id!,
                  })
                );

                this.store.dispatch(
                  DocumentActions.SaveCurrentItem({
                    payload: {
                      ...resData.entity,
                      rootFolderId: documentData.payload.documentItem?.id,
                    },
                  })
                );

                this.store.dispatch(
                  DocumentActions.IsFetching({ payload: false })
                );

                return {
                  type: '[Document] Get Document By Status View Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Get Document By Status View',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] Failed To  Get Document By Status View ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getRootFoldersByUserIDView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.GetRootFoldersByUserIdView),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/Documents/getrootfoldersbyuserid/${authState.user.Role.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                DocumentActions.IsFetching({ payload: false })
              );
              if (resData.succeeded === true) {
                this.store.dispatch(
                  DocumentActions.SaveCurrentlyActiveIndex({
                    payload: documentData.payload.id!,
                  })
                );

                this.store.dispatch(
                  DocumentActions.SaveCurrentItem({
                    payload: resData.entity,
                  })
                );

                return {
                  type: '[Document] Get Root Folders Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Get Root Folders',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError]  Failed To Get Root Folders ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getFilesByParentFolderIdView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.GetFilesByParentFolderIdView),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${
              environment.onyxDocDocumentUrl
            }/Documents/getfilesbyparentfolderid/${
              authState.user?.SubscriberId
            }/${authState.user?.UserId}/${
              documentData?.payload.documentItem?.id
            }/${documentData.payload.skip ?? 0}/${
              documentData.payload.take ?? 0
            }`
          )
          .pipe(
            map((resData: any) => {
              if (resData.succeeded === true) {
                this.store.dispatch(
                  DocumentActions.SaveCurrentlyActiveIndex({
                    payload: documentData.payload.documentItem?.id!,
                  })
                );

                this.store.dispatch(
                  DocumentActions.SaveCurrentItem({
                    payload: {
                      ...resData.entity,
                      rootFolderId: documentData.payload.documentItem?.id,
                    },
                  })
                );

                this.store.dispatch(
                  DocumentActions.IsFetching({ payload: false })
                );

                return {
                  type: '[Document] Get Files By Parent Folder Id Was Successful',
                };
              } else {
                this.store.dispatch({
                  type: '[Document] Failed To Get Files By Parent Folder Id',
                });

                this.store.dispatch(
                  DocumentActions.IsFetching({ payload: false })
                );

                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return resData;
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Document][CatchError] To  Get Files By Parent Folder Id ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  //** Get Raw Folders Without Any Manipulation */

  getRootFoldersByUserIDRaw$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentActions.GetRootFoldersByUserIdRaw),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/getrootfoldersbyuserid/${authState.user.Role.SubscriberId}/${authState.user.UserId}`
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  DocumentActions.IsFetching({ payload: false })
                );
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    DocumentActions.SaveRootFolders({
                      payload: resData.entity.folders,
                    })
                  );
                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Document] Failed To Get Root Folders',
                  };
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Document][CatchError]  Failed To Get Root Folders ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getFilesByParentFolderIdRaw$ = createEffect(
    () =>
      this.actions$.pipe(
        distinctUntilChanged(),
        ofType(DocumentActions.GetFilesByParentFolderIdRaw),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${
                environment.onyxDocDocumentUrl
              }/Documents/getfilesbyparentfolderid/${
                authState.user?.SubscriberId
              }/${authState.user?.UserId}/${documentData?.payload.id}/${
                documentData.payload.skip ?? 0
              }/${documentData.payload.take ?? 0}`
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  DocumentActions.IsFetching({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Document] Get Files By Parent Folder Id Was Successful',
                  });
                } else {
                  this.store.dispatch({
                    type: '[Document] Failed To Get Files By Parent Folder Id',
                  });

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Document][CatchError] To Get Files By Parent Folder Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  activateContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.ActivateContacts),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocDocumentUrl}/AddressBooks/activate`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...documentData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.entity,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                // this.store.dispatch({
                //   type: '[Contacts] Activate Contacts Was Successful',
                // });
                this.store.dispatch(
                  DocumentActions.GetAddressBookBySubscriberId()
                );

                return {
                  type: '[Contacts ] Activate Contacts Failed',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Contacts ] Activate Contacts Failed',
                };
              }

              // return resData;
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Contacts Creation][CatchError] Failed to Activate Created Contacts ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deactivateContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.DeactivateContacts),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocDocumentUrl}/AddressBooks/deactivate`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...documentData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.entity,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.GetAddressBookBySubscriberId()
                );

                return {
                  type: '[Contacts] Deactivate Contacts Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Contacts ] Deactivate Contacts Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Contacts Creation][CatchError] Failed to Deactivate Created Contacts ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deleteContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.DeleteContacts),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocDocumentUrl}/AddressBooks/delete`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...documentData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                DocumentActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.entity,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  DocumentActions.GetAddressBookBySubscriberId()
                );

                return {
                  type: '[Contacts] Delete Contacts Failed',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Contacts] Delete Contacts Failed',
                };
              }

              // return resData;
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Contacts Creation][CatchError] Failed to Deactivate Created Contacts ${errorRes.message}`
              );
            })
          );
      })
    )
  );
}
