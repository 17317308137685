import { createAction, props } from '@ngrx/store';
import {
  StartSignUpPayload,
  SignupAndSubscribePayload,
  SignUpWithEmailPayload,
  AdditionalInfoPayload,
} from '../../interfaces';
import { ThirdPartyTypeEnum } from '../../enums';
import { authReducerState } from './auth.reducer';
// import { authReducerState } from './auth.reducer';

export const ResetStore = createAction('[Auth] Reset Store');

export const IsLoading = createAction(
  '[Auth] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const ToVerifyDevice = createAction(
  '[Auth] To Verify Device',
  props<{
    payload: {
      deviceGuid: string;
      verifyStatus: boolean;
    };
  }>()
);

export const InitializeApp_DeveloperToken = createAction(
  '[Auth] Initialize App - Developer Token'
);

export const InitializeApp_AccessToken = createAction(
  '[Auth] Initialize App - Access Token'
);

export const UpdateDeveloperTokenStatus = createAction(
  '[Auth] Update Developer Token Status',
  props<{
    payload: {
      status: boolean;
    };
  }>()
);

export const UpdateAccessTokenStatus = createAction(
  '[Auth] Update AccessToken Status',
  props<{
    payload: {
      status: boolean;
    };
  }>()
);

// export const OnyxDocStart = createAction('[Auth] Onyx Doc Start');

// export const OnyxDocAuth = createAction('[Auth] Onyx Doc Auth');

// export const UpdateBearerToken1Status = createAction(
//   '[Auth] Update Bearer Token 1 Status',
//   props<{
//     payload: {
//       status: boolean;
//     };
//   }>()
// );

// export const UpdateBearerToken2Status = createAction(
//   '[Auth] Update Bearer Token 2 Status',
//   props<{
//     payload: {
//       status: boolean;
//     };
//   }>()
// );

export const RefreshToken = createAction(
  '[Auth] Refresh Token',
  props<{
    payload: {
      refreshToken: string;
    };
  }>()
);

export const RefreshTokenSuccess = createAction(
  '[Auth] Refresh Token Success',
  props<{
    payload: {
      user: any;
      permissionsArray: string[] | null;
      instance?: 'quick-sign-login';
      branding?: any;
    };
  }>()
);

// export const RegisterStart = createAction(
//   '[Auth] Register Start',
//   props<{
//     payload: OrganizationSignUpPayload;
//   }>()
// );

export const RegisterStart = createAction(
  '[Auth] Register Start',
  props<{
    payload: SignupAndSubscribePayload;
  }>()
);

export const StartRegistration = createAction(
  '[Auth] start Registration',
  props<{
    payload: StartSignUpPayload;
  }>()
);

export const CompleteRegistration = createAction(
  '[Auth] Complete Registration',
  props<{
    payload: AdditionalInfoPayload;
  }>()
);

export const SignupWithEmail = createAction(
  '[Auth] Sign Up with Email Start',
  props<{
    payload: SignUpWithEmailPayload;
  }>()
);

export const RegisterWithInvite = createAction(
  '[Auth] Register With Invite',
  props<{
    payload: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      phoneNumber: string;
    };
  }>()
);

export const CheckUpDomainName = createAction(
  '[Auth] Check Up Domain Name',
  props<{
    payload: {
      email: string;
    };
    alternatePayload?: {
      isThirdParty: boolean;
      thirdPartyType: ThirdPartyTypeEnum;
      firstName: string;
      lastName: string;
      email: string;
      photoUrl: string;
    } | null;
  }>()
);

export const LoginStart = createAction(
  '[Auth] Login Start',
  props<{
    payload: {
      email: string;
      password: string;
      keep_me_logged_in: boolean;
      ipAddress: string;
      location: string;
      deviceName: string | null;
      deviceId: string | null;
      browser: string;
      instance?: 'quick-sign-login';
    };
  }>()
);

export const LoginWithThirdParty = createAction(
  '[Auth] Login With Third Party',
  props<{
    payload: {
      email: string;
      thirdPartyType: number;
      keep_me_logged_in: boolean;
      browser: string;
      deviceType: number;
    };
  }>()
);

export const LinkedInSignUp = createAction('[Auth] SignUp With LinkedIn');

export const LinkedInSignIn = createAction(
  '[Auth] LinkedIn Sign In',
  props<{
    payload: {
      state: string;
      code: string;
      keep_me_logged_in: boolean;
      browser: string;
      deviceType: 0;
    };
  }>()
);

export const LinkedInVerification = createAction(
  '[Auth] Fetch and verify user data',
  props<{
    payload: any;
  }>()
);

export const AutoLogin = createAction('[Auth] Auto Login');

export const AuthenticateSuccess = createAction(
  '[Auth] Authenticate Success',
  props<{
    payload: {
      user: any;
      permissionsArray: string[] | null;
      instance?: 'quick-sign-login';
      branding?: any;
    };
  }>()
);

// export const AuthenticateFail = createAction('[Auth] Authenticate Fail');

export const Logout = createAction(
  '[Auth] Logout',
  props<{
    payload: {
      // email?: string;
      isInstance2?: boolean;
    };
  }>()
);

// export const ClearUser = createAction('[Auth] Clear User');

export const ForgotPassword = createAction(
  '[Auth] Forgot Password',
  props<{
    payload: {
      email: string;
    };
  }>()
);

export const ResetPassword = createAction(
  '[Auth] Reset Password',
  props<{
    payload: {
      email: string;
      newPassword: string;
      token: string;
    };
  }>()
);

export const ChangePassword = createAction(
  '[Auth] Change Password',
  props<{
    payload: {
      email: string;
      oldPassword: string;
      newPassword: string;
    };
  }>()
);

export const ConfirmEmail = createAction(
  '[Auth] Confirm Email',
  props<{
    payload: {
      email: string;
      token: string;
    };
  }>()
);

export const JoinExistingOrganization = createAction(
  '[Auth] Join Existing Organization',
  props<{
    payload: {
      email: string;
      password: string;
      firstName: string;
      lastName: string;
      subscriberId: number;
    };
  }>()
);

export const CheckEmail = createAction(
  '[Auth] Check Email',
  props<{
    payload: {
      contactEmail: string;
    };
    defaultErrorMessage?: boolean;
  }>()
);

export const CheckOldRecipient = createAction(
  '[Auth] Check Old Recipient',
  props<{
    payload: {
      email: string;
    };
  }>()
);

export const DocumentBranding = createAction(
  '[Auth] Document branding',
  props<{
    payload: {
      logo: string;
      themeColor: string;
      footerInformation: string;
      hasFooterInformation: boolean;
      subscriberId: number;
      userId: string;
    };
  }>()
);

export const UpdateDocumentBranding = createAction(
  '[Auth] Update Document branding',
  props<{
    payload: {
      id: number;
      logo: string;
      themeColor: string;
      footerInformation: string;
      hasFooterInformation: boolean;
      subscriberId: number;
      userId: string;
    };
  }>()
);

export const GetDocumentBranding = createAction(
  '[Auth] Get Document Branding by Id'
);

export const SaveDocumentBranding = createAction(
  '[Auth] Save Document Branding',
  props<{
    payload: any;
  }>()
);

export const CreateSystemSetting = createAction(
  '[Auth] Create System Setting',
  props<{
    payload: {
      expirationReminder: [
        {
          expirationReminderInterval: number;
          expirationSettingsFrequency: number;
        }
      ];
      expirationSettingsFrequency: number;
      workflowReminder: number;
      workflowReminderSettingsFrequency: number;
      shouldSentDocumentsExpire: boolean;
      documentExpirationPeriod: string;
      currency: string;
      language: string;
      subscriberId: number;
      userId: string;
    };
  }>()
);

export const DeleteExpirationReminder = createAction(
  '[Auth] Delete Expriation Reminder',
  props<{
    payload: {
      expirationId: number;
      systemSettingId: number;
    };
  }>()
);

export const UpdateSystemSetting = createAction(
  '[Auth] Update System Setting',
  props<{
    payload: {
      id: number;
      expirationReminder: number;
      expirationSettingsFrequency: number;
      workflowReminder: number;
      workflowReminderSettingsFrequency: number;
      shouldSentDocumentsExpire: boolean;
      documentExpirationPeriod: string;
      currency: string;
      language: string;
      subscriberId: number;
      userId: string;
    };
  }>()
);

// export const GetSystenSetting = createAction('[Auth] Get System Setting');

// export const SaveSystenSetting = createAction(
//   '[Auth] Save System Setting',
//   props<{
//     payload: any;
//   }>()
// );

export const GetAuditParameter = createAction('[Auth] Get Audit Parameter');

export const GetRolePermissions = createAction(
  '[Auth] Get Role Permissions',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const SaveRolePermissions = createAction(
  '[Auth] Save Role Permissions',
  props<{
    payload: any;
  }>()
);

export const ContactUs = createAction(
  '[Document] Create Contact Us',
  props<{
    payload: {
      firstName: string;
      lastName: string;
      companyName: string;
      country: string;
      email: string;
      productInterest: number;
      industry: string;
      shouldReceiveUpdate: boolean;
      message: string;
    };
  }>()
);

export const JoinWaitlist = createAction(
  '[Document] Create Join Waitlist',
  props<{
    payload: {
      email: string;
      productType: number;
    };
  }>()
);

export const GetWaitlistProductType = createAction(
  '[Auth] Get Waitlist Product Type'
);

export const SaveWaitlistProductType = createAction(
  '[Auth] Save  Waitlist Product Type',
  props<{
    payload: any;
  }>()
);

export const GetUserProfileDetailsById = createAction(
  '[Auth] Get User Profile Details By Id'
);

export const SaveUserProfileDetailsById = createAction(
  '[Auth] Save User Profile Details By Id',
  props<{
    payload: any;
  }>()
);

export const SendVerificationEmail = createAction(
  '[Auth] Send Verification Email',
  props<{
    payload: {
      email: string;
    };
  }>()
);

export const SaveAPIClient = createAction(
  '[Auth] Save API Client',
  props<{
    payload: any;
  }>()
);

export const AcceptDevice = createAction(
  '[Auth] Accept Device',
  props<{
    payload: {
      guid: string | null;
      userId: string | null;
      time: string | null;
    };
  }>()
);

export const RejectDevice = createAction(
  '[Auth] Reject Device',
  props<{
    payload: {
      guid: string | null;
      userId: string | null;
      time: string | null;
    };
  }>()
);

export const ConfirmDeviceStatus = createAction(
  '[Auth] Confirm Device Status',
  props<{
    payload: {
      deviceGuid: string;
    };
  }>()
);

export const SaveConfirmDeviceStatus = createAction(
  '[Auth] Save Confirm Device Status',
  props<{
    payload: {
      verifyDeviceStatus: any;
    };
  }>()
);

export const GetDevicesById = createAction(
  '[Auth] Get Devices By Id',
  props<{
    payload: {
      userId: string | null;
      subscriberId: number | null;
    };
  }>()
);

export const SaveDevicesById = createAction(
  '[Auth] Save Devices By Id',
  props<{
    payload: any[];
  }>()
);

export const SignOutAllDevices = createAction(
  '[Auth] Sign Out All Devices',
  props<{
    payload: {
      userId: string | null;
      devicesGuids: string[] | null;
    };
  }>()
);

export const SignOutDevice = createAction(
  '[Auth] Sign Out Device',
  props<{
    payload: {
      guid: string | null;
      userId: string | null;
    };
  }>()
);

export const OtpLogin = createAction(
  '[Auth] Otp Login',
  props<{
    payload: {
      browser: string;
      ipAddress: string;
      deviceName: string | null;
      twoFAVerificationType: number;
      otp: string;
      email: string;
    };
  }>()
);

export const OTpCheckLogin = createAction(
  '[Auth] Otp Check Login ',
  props<{
    payload: {
      email: string;
      password: string;
      keep_me_logged_in: boolean;
      ipAddress: string;
      location: string;
      deviceName: string | null;
      deviceId: string | null;
      browser: string;
      instance?: 'quick-sign-login';
    };
  }>()
);

export const SendOtpToEmail = createAction(
  '[Auth]  Send Otp To Email',
  props<{
    payload: {
      email: string;
    };
  }>()
);

export const GetUserTwoFADetails = createAction(
  '[Auth]  Get User Two FA Details',
  props<{
    payload: {
      email: string;
    };
  }>()
);

export const SaveUserTwoFADetails = createAction(
  '[Auth]  Save User Two FA Details',
  props<{
    payload: authReducerState['email'];
  }>()
);
