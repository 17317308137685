import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { PricingPlanType } from 'src/app/@core/enums';
import {
  getActiveSubscriptionPlanForRegistration,
  regexValidator,
  validateFileType,
} from 'src/app/@core/utils/helpers';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as SubscriptionActions from 'src/app/@core/stores/subscription-plan/subscription-plan.actions';
import * as subscriptionSelectors from 'src/app/@core/stores/subscription-plan/subscription-plan.selectors';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import { Store } from '@ngrx/store';
import { HelperService } from 'src/app/@core/services/helper.service';
import * as UserRoleActions from 'src/app/@core/stores/user-role/user-role.actions';
import * as userRoleSelectors from 'src/app/@core/stores/user-role/user-role.selectors';
import { NotificationService } from 'src/app/@core/services/notification.service';
import {
  InvitedEmail,
  Notification,
  ReferralEnum,
} from 'src/app/@core/interfaces';

@Component({
  selector: 'app-addition-info',
  templateUrl: './addition-info.component.html',
  styleUrls: ['./addition-info.component.scss'],
})
export class AdditionInfoComponent implements OnInit, OnDestroy {
  isAuthLoading!: Observable<boolean>;
  isSubscriptionLoading!: Observable<boolean>;
  isFromDeveloperWebsite: boolean = false;
  page = 1;
  year!: number;
  show: boolean = false;

  latitude: number = 0;
  longitude: number = 0;

  email!: string;
  token!: string;
  subscriberId!: string;
  userId!: string;
  isUserInvited!: boolean;

  subscription!: any;
  pricing: any;
  country: 'Nigeria' | 'Others' = 'Others';

  // Password validations
  uppercasePattern = new RegExp('^(?=.*?[A-Z])');
  lowercasePattern = new RegExp('(?=.*?[a-z])');
  numberPattern = new RegExp('(?=.*?[0-9])');
  specialCharPattern = new RegExp('(?=.*?[#?!@$%^&*-])');

  registrationForm!: FormGroup;
  inviteForm!: FormGroup;
  allRoles: any[] | null = null;

  referralTypes = ReferralEnum;

  // Form Values
  usageType!: '0' | '1';
  organizationSize = '';
  creatorRole = '';
  howYouHeard: number = 0;

  // Theme
  selectedTheme: any = 'theme1';
  selectedThemeColor: any = '#4E33FF';
  allThemes!: any;

  // Profile Picture
  profilePicturePreview!: string;

  //Form Values
  toolsUsed: string[] = [];
  interestedFeatures: string[] = [];

  private _subscription = new Subscription();

  @ViewChild('profilePictureUpload', { static: false })
  profilePictureUpload: ElementRef;

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private helperService: HelperService,
    private notificationService: NotificationService
  ) {
    this._subscription.add(
      this.route.queryParams.subscribe((res) => {
        if (res?.developer) {
          this.isFromDeveloperWebsite = res?.developer;
        }
      })
    );
  }

  ngOnInit(): void {
    this.checkForParams();

    this.createRegistrationForm();

    this.isAuthLoading = this.store.select(authSelectors.getAuthIsLoading);

    this.createRegistrationForm();

    this.year = new Date().getFullYear();

    this.isSubscriptionLoading = this.store.select(
      subscriptionSelectors.getSubscriptionPlanIsLoading
    );

    this.getLocation();

    this.listenToGetAllActiveSubscriptionPlansForWebsite();

    this.getActiveSubscriptionPlans();

    this.createInvitesRegistrationForm();

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.getAllRoles.bind(this)
    );
  }

  checkForParams() {
    this.route.queryParams.subscribe((params) => {
      const email = params['email'];
      const token = params['token'];
      const subscriberId = params['subscriberId'];
      const userId = params['userId'];

      const isUserInvited = params['isUserInvited'];

      if (isUserInvited !== 'true' && isUserInvited !== 'false') {
        this.router.navigate(['/login']);
      }

      if (email && token && subscriberId && userId && isUserInvited) {
        this.email = email;
        this.token = token;
        this.userId = userId;
        this.subscriberId = subscriberId;
        this.isUserInvited = this.toBoolean(isUserInvited);
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  toBoolean(value: string): boolean {
    if (value === 'true') {
      return true;
    } else {
      return false;
    }
  }

  getAllRoles() {
    this.store.dispatch(
      UserRoleActions.GetAllUserRoles({
        payload: {
          skip: 0,
          take: 100,
          userId: this.userId,
          subscriberId: parseInt(this.subscriberId),
        },
      })
    );

    this._subscription.add(
      this.store
        .select(userRoleSelectors.getAllUserRoles)
        .subscribe((resData) => {
          if (resData) {
            this.allRoles = resData?.roles?.filter(
              (role: any) => role.status !== 2
            );
          }
        })
    );
  }

  createInvitesRegistrationForm() {
    this.inviteForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      invitedUsers: this.fb.array([]),
    });
  }

  createRegistrationForm() {
    this.registrationForm = this.fb.group({
      password: [
        null,
        [
          Validators.required,
          regexValidator(new RegExp('^(?=.*?[A-Z])'), {
            uppercaseLetter: true,
          }),
          regexValidator(new RegExp('(?=.*?[a-z])'), { lowercaseLetter: true }),
          regexValidator(new RegExp('(?=.*?[0-9])'), { number: true }),
          regexValidator(new RegExp('(?=.*?[#?!@$%^&*-])'), {
            specialCharacter: true,
          }),
          regexValidator(new RegExp('.{8,}$'), { minimum: true }),
        ],
      ],
      firstName: [
        null,
        [Validators.required, Validators.pattern('^[A-Za-z]+$')],
      ],
      lastName: [
        null,
        [Validators.required, Validators.pattern('^[A-Za-z]+$')],
      ],
      phone_number: [
        '',
        [Validators.required, Validators.pattern('^[+0-9]{7,15}$')],
      ],

      company_name: [null, [Validators.pattern('^[A-Za-z0-9\\s]+$')]],
      profile_picture: [null],
      themeColor: ['theme1', [Validators.required]],
    });
  }

  get registrationFormControls() {
    return this.registrationForm.controls;
  }

  get passwordValue() {
    return this.registrationForm.controls['password'].value;
  }

  get InvitesForm() {
    return this.inviteForm.controls;
  }

  get invitedUserArray() {
    return this.inviteForm.get('invitedUsers') as FormArray;
  }

  changePage(index: number) {
    this.page = index;
  }

  getErrorMessage(instance: string) {
    if (
      instance === 'password' &&
      this.registrationFormControls.password.hasError('required')
    ) {
      return 'Please enter your password';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('uppercaseLetter')
    ) {
      return 'Your password must have at least 1 uppercase letter';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('lowercaseLetter')
    ) {
      return 'Your password must have at least 1 lowercase letter.';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('number')
    ) {
      return 'Your password must have at least a digit (0-9)';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('specialCharacter')
    ) {
      return 'Your password must have at least a special character';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('minimum')
    ) {
      return 'Your password must have at least a minimum of 8 characters.';
    } else if (
      instance === 'firstName' &&
      this.registrationFormControls.firstName.hasError('required')
    ) {
      return 'Please enter your first name';
    } else if (
      instance === 'firstName' &&
      this.registrationFormControls.firstName.hasError('pattern')
    ) {
      return 'Incorrect name format';
    } else if (
      instance === 'lastName' &&
      this.registrationFormControls.lastName.hasError('required')
    ) {
      return 'Please enter your last name';
    } else if (
      instance === 'lastName' &&
      this.registrationFormControls.lastName.hasError('pattern')
    ) {
      return 'Incorrect name format';
    } else if (
      instance === 'phone_number' &&
      this.registrationFormControls.phone_number.hasError('required')
    ) {
      return 'Please enter your phone number';
    } else if (
      instance === 'phone_number' &&
      this.registrationFormControls.phone_number.hasError('pattern')
    ) {
      return 'Incorrect phone number format';
    } else if (
      instance === 'company_name' &&
      this.registrationFormControls.company_name.hasError('required')
    ) {
      return 'Please enter your company name';
    } else if (
      instance === 'company_name' &&
      this.registrationFormControls.company_name.hasError('pattern')
    ) {
      return 'Incorrect format';
    } else {
      return;
    }
  }

  createInvitedUser(user: any) {
    return this.fb.group({
      email: [user, [Validators.required, Validators.email]],
      role: [null, Validators.required],
    });
  }

  onEnterPress(event: any) {
    const value = event.target.value;
    if (!value) {
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(value)) {
      const notification: Notification = {
        state: 'error',
        message: 'Input a valid email.',
      };

      this.notificationService.openNotification(
        notification,
        'flwmn-notification-error',
        false,
        'top-right',
        2
      );

      return;
    }

    const recipient = value;

    if (this.invitedUserArray.value.length === 0) {
      this.invitedUserArray.push(this.createInvitedUser(recipient));
    } else {
      const userExist = !this.invitedUserArray.value.some((el: any) => {
        return el.email === recipient;
      });

      if (userExist) {
        this.invitedUserArray.push(this.createInvitedUser(recipient));
      }
    }

    const emailControl = this.inviteForm.get('email');

    emailControl?.reset();
    emailControl?.clearValidators();
    emailControl?.updateValueAndValidity();
  }

  listenToGetAllActiveSubscriptionPlansForWebsite() {
    this._subscription.add(
      this.store
        .select(subscriptionSelectors.getAllActiveSubscriptionPlansForWebsite)

        .subscribe((resData) => {
          if (resData !== null) {
            this.subscription = getActiveSubscriptionPlanForRegistration(
              resData
              // this.registrationType
            );
            this.subscription?.subscriptionPlanPricings?.forEach(
              (subscriptionPlanPricing: any) => {
                if (
                  this.country === 'Nigeria' &&
                  subscriptionPlanPricing.currencyCode === 'NGN' &&
                  subscriptionPlanPricing.pricingPlanType ===
                    PricingPlanType.monthly
                ) {
                  this.pricing = subscriptionPlanPricing;
                } else if (
                  this.country === 'Others' &&
                  subscriptionPlanPricing.currencyCode === 'USD' &&
                  subscriptionPlanPricing.pricingPlanType ===
                    PricingPlanType.monthly
                ) {
                  this.pricing = subscriptionPlanPricing;
                }
              }
            );
          }
        })
    );
  }

  getActiveSubscriptionPlans() {
    this.store.dispatch(SubscriptionActions.IsLoading({ payload: true }));

    this.store.dispatch(
      SubscriptionActions.GetActiveSubscriptionPlansForWebsite()
    );
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  onSubmit() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    let deviceGuid = localStorage.getItem('deviceGuid');
    if (deviceGuid === undefined || deviceGuid === null) {
      deviceGuid = this.helperService.generateGUID();
      localStorage.setItem('deviceGuid', deviceGuid);
    }

    this.store.dispatch(
      AuthActions.CompleteRegistration({
        payload: {
          allowUsersOutsideDomainToJoinOrganization: false,
          token: this.token!,
          phoneNumber: this.registrationForm.value.phone_number,
          contactEmail: this.email,
          address: '',
          city: '',
          state: this.registrationForm.value.state,
          sessionId: '',
          deviceType: 0,
          name: `${this.registrationForm.value.firstName} ${this.registrationForm.value.lastName}`,
          country: this.registrationForm.value.country,
          latitude: `${this.latitude}`,
          longitude: `${this.longitude}`,
          referrer: this.howYouHeard,
          profilePicture: this.registrationForm.value.profile_picture,
          thirdPartyType: null,
          staffSize: 0,
          firstName: this.registrationForm.value.firstName,
          lastName: this.registrationForm.value.lastName,
          password: this.registrationForm.value.password,
          email: this.email!,
          companyName: `${this.registrationForm.value.firstName} ${this.registrationForm.value.lastName}`,
          jobTitle: this.creatorRole,
          deviceId: '',
          deviceName: '',
          browser: `${this.helperService.getBrowserName()}${deviceGuid}`,
          invitedEmails: this.getInvitees(),
          themeColor: this.registrationForm.value.themeColor,
          themeColorCode: '',
          subscriberId: parseInt(this.subscriberId),
          userId: this.userId,
          featuresToAdd: this.interestedFeatures,
          productsWorkedWith: this.toolsUsed,
          flowmonoUsageType: this.usageType,
          isUserInvited: this.isUserInvited,
        },
      })
    );
  }

  getInvitees(): InvitedEmail[] {
    const { invitedUsers } = this.inviteForm.value;

    const invitedDictionary = invitedUsers.map((user: any) => ({
      email: user.email,
      role: user.role,
    }));

    return invitedDictionary;
  }

  removeUserFromSpecifiedUsersArray(index: number) {
    // Remove recipient
    this.invitedUserArray.removeAt(index);
  }

  onSelectTheme(event: any) {
    this.selectedTheme = event.target.getAttribute('data-theme');
    this.selectedThemeColor = event.target.getAttribute('data-theme-color');

    this.allThemes = document.querySelectorAll('.theme');

    this.allThemes.forEach((theme: HTMLElement) => {
      if (theme.classList.contains('selected')) {
        theme.classList.remove('selected');
      }
    });

    event.target.classList.add('selected');

    this.registrationForm.patchValue({ themeColor: this.selectedTheme });
    this.registrationForm.updateValueAndValidity();
  }

  onUploadProfilePicture(event: Event) {
    const _event: any = event.target as HTMLInputElement;

    const file = _event.files[0];

    if (file && file.size <= 1000000) {
      if (
        validateFileType(
          [file],
          ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']
        )
      ) {
        const reader = new FileReader();

        reader.onload = () => {
          const fullBase64String = reader.result!.toString();
          const base64String = fullBase64String.split(',');

          this.registrationForm.patchValue({
            profile_picture: base64String[1],
          });

          this.registrationForm
            .get('profile_picture')!
            .updateValueAndValidity();

          this.profilePicturePreview = fullBase64String;
        };

        reader.readAsDataURL(file);
      } else {
        this.profilePictureUpload.nativeElement.value = null;

        const notification: Notification = {
          state: 'warning',
          message: 'Unsupported file format: Please select an Image file',
        };

        this.notificationService.openNotification(
          notification,
          'flwmn-notification-warning'
        );
      }
    } else {
      this.profilePictureUpload.nativeElement.value = null;

      const notification: Notification = {
        state: 'warning',
        message: 'The maximum image size is 1MB',
      };

      this.notificationService.openNotification(
        notification,
        'flwmn-notification-warning'
      );
    }
  }

  setToolsUsed(value: string) {
    const index = this.toolsUsed.indexOf(value);

    if (index !== -1) {
      this.toolsUsed.splice(index, 1);
    } else {
      this.toolsUsed.push(value);
    }
  }

  setInterestedFeatures(value: string) {
    const index = this.interestedFeatures.indexOf(value);

    if (index !== -1) {
      this.interestedFeatures.splice(index, 1);
    } else {
      this.interestedFeatures.push(value);
    }
  }

  nextBaseOnUsageType() {
    if (this.usageType) {
      if (this.usageType == '1') {
        this.changePage(3.5);
      } else {
        this.changePage(2.5);
      }

      return;
    }

    this.changePage(2.5);
  }

  skipInvite() {
    if (this.isUserInvited === true) {
      this.changePage(4);
    } else {
      this.changePage(1.5);
    }
  }

  BackAfterHowLargeQuestion() {
    if (this.isUserInvited) {
      this.changePage(1);
    } else {
      this.changePage(2);
    }
  }

  backToFirst() {
    if (this.isUserInvited === true) {
      this.changePage(1);
    } else {
      this.changePage(1.5);
    }
  }

  backBasedOnUsageType() {
    if (this.usageType) {
      if (this.usageType === '1') {
        this.changePage(2);
      } else {
        this.changePage(3);
      }

      return;
    }

    this.changePage(3);
  }

  checkInviteStatusBeforeBacking() {
    if (this.isUserInvited === true) {
      this.changePage(1);
    } else {
      this.changePage(3.5);
    }
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
