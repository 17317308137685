import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { EditorModeEnum } from 'src/app/@core/enums';
import { EditorMode } from 'src/app/@core/types';
import { ScanQrCodeComponent } from './scan-qr-code/scan-qr-code.component';
import { Notification } from 'src/app/@core/interfaces';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { FormBuilderService } from 'src/app/@core/services/form-builder.service';

// import * as recipientSignatureSelectors from 'src/app/@core/stores/recipient-signature/recipient-signature.selectors';
import * as recipientSignatureActions from 'src/app/@core/stores/recipient-signature/recipient-signature.actions';
import * as editorSelector from 'src/app/@core/stores/editor/editor.selectors';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';
import * as UserActions from 'src/app/@core/stores/user/user.actions';
import * as EditorActions from 'src/app/@core/stores/editor/editor.actions';
import { EditorEffects } from 'src/app/@core/stores/editor/editor.effects';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'app-add-signature',
  templateUrl: './add-signature.component.html',
  styleUrls: ['./add-signature.component.scss'],
})
export class AddSignatureComponent implements OnInit, AfterViewInit, OnDestroy {
  signatureBase64!: string | null;
  option: 'type' | 'draw' | 'saved' | 'upload' | 'initial' = 'type';
  savedOption: 'default' | 'others' = 'default';
  logoPreview!: string | null;
  ctx: any;
  signatureCanvas: any;
  signatureCanvasContext: any;
  fontList = [
    { name: 'Comforter Brush', value: "'Comforter Brush', cursive" },
    { name: 'Italianno', value: "'Italianno', cursive" },
    { name: 'Edwardian Script ITC', value: 'Edwardian Script ITC' },
    { name: 'Brush Script MT', value: 'Brush Script MT' },
    { name: 'Freestyle Script', value: 'Freestyle Script' },
    { name: 'Lucida Handwriting', value: 'Lucida Handwriting' },
  ];
  allThemes!: any;
  selectedTheme: any = 'theme8';
  themeColors: any = {
    theme1: '#4e33ff',
    theme2: '#fb8500',
    theme3: '#be0424',
    theme4: '#906e18',
    theme5: '#208b3a',
    theme6: '#094cf4',
    theme7: '#6c27a7',
    theme8: '#222222',
  };
  state: any;
  points: any[] = [];
  beginPoint: any = null;
  lineWidth = 4.5;
  strokeStyle = this.themeColors[this.selectedTheme];
  shadowColor = this.themeColors[this.selectedTheme];
  shadowBlur = this.lineWidth / 4;
  lineJoin = 'round';
  lineCap = 'round';
  fontSize = '60px';
  font = "'Comforter Brush', cursive";

  userSelectorObservable: Observable<any>;
  loggedInUsersSignature: string | null = null;
  loggedInUsersSignatureIsLoading: boolean = false;
  textContext: CanvasRenderingContext2D | null;
  editorModeEnum = EditorModeEnum;
  showSavedSignature: boolean = true;
  showWaterMark: boolean = false;
  editorMode: string;
  signerFullName: string = '';
  subscriberId: number;
  userId: string | null = null;
  signatureCount: number = 0;
  signatureList: any[] = [];
  defaultSignature: any | null = null;
  // showToggle: boolean = true;
  isNonFlowmonoUser: boolean;
  userEmail: string;

  signatureNameTypeList: any[] = [];
  signatureInitialTypeList: any[] = [];

  loading$: Observable<boolean>;
  // isSaveSignature: boolean = true;

  currentTab: number = 0;
  skip: number = 0;

  signType: any;

  private subscription: Subscription = new Subscription();

  @ViewChild('input', { static: false }) input: ElementRef;
  @ViewChild('mediaContainer', { static: false }) mediaContainer: ElementRef;
  @ViewChild('documentUpload', { static: false }) documentUpload: ElementRef;
  // @ViewChild('signatureCanvas', { static: true })
  // signatureCanvas: ElementRef<HTMLCanvasElement>;

  mimeType: string;
  checked: boolean = false;

  defaultChecked: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      editorMode: EditorMode;
      showSavedSignature: boolean;
      recipientEmail: string;
      fullName: string;
      initials: string;
      email: string;
      signatureItem: any | null;
      currentTab: number;
      isEditor: boolean;
      fontSize: string;
    },
    public dialogRef: MatDialogRef<AddSignatureComponent>,
    private dialog: MatDialog,
    public store: Store<fromApp.AppState>,
    private editorEffects: EditorEffects,
    private notificationService: NotificationService,
    private formBuilderService: FormBuilderService
  ) {
    if (this.data.currentTab && this.data.currentTab === 1) {
      this.currentTab = this.data.currentTab;
    } else {
      this.currentTab = 0;
    }

    // this.fontSize = data.fontSize;
  }

  ngOnInit(): void {
    this.showSavedSignature = this.data?.showSavedSignature ?? true;

    this.editorMode = this.editorModeEnum.Internal;

    this.userEmail = this.data.email || this.data.recipientEmail;

    // this.getSignatureInitialsList();

    // this.getSignatureNameList();
    this.getSignatureNameListAsync();

    this.listenToDecodeHashForSubscriberId();

    this.getUserByEmail();

    this.listenToGetUserByEmailEffect();

    this.listenToGetExternalUserByEmailEffect();

    this.listenToGetLoggedInUserByEditor();

    // this.listenToGetActiveRecipientSignatures();
  }

  ngAfterViewInit() {
    if (this.option === 'draw' && this.currentTab === 1) {
      // this.eSignature();
      this.onSign();
    }

    // (this.input.nativeElement as HTMLElement).style.fontFamily = this.font;
  }

  onWindowResize(event: any) {
    if (this.signatureCanvas && this.mediaContainer) {
      this.signatureCanvas.width = (
        this.mediaContainer.nativeElement as HTMLElement
      ).getBoundingClientRect().width;
    }
  }

  getSavedSignature() {
    if (this.data?.editorMode === this.editorModeEnum.Internal) {
      this.onGetLoggedInUsersSignature();
    } else if (this.data?.editorMode === this.editorModeEnum.External) {
      this.getUserByEmail();
    }
  }

  onGetLoggedInUsersSignature() {
    this.loggedInUsersSignatureIsLoading = true;

    this.userSelectorObservable = this.store.pipe(
      select(authSelectors.getUser)
    );

    // this.getUserDetails(this.loggedInUser.UserId);

    this.listenToGetUserSelector();
  }

  listenToGetUserSelector() {
    this.subscription.add(
      this.store.pipe(select(authSelectors.getUser)).subscribe((resData) => {
        if (resData) {
          this.getUserDetails(resData.UserId);
        }
      })
    );
  }

  getUserDetails(id: string) {
    this.store.dispatch(UserActions.GetUserById({ payload: { id: id } }));
  }

  getUserByEmail() {
    if (this.data?.recipientEmail) {
      this.loggedInUsersSignatureIsLoading = true;

      this.store.dispatch(
        EditorActions.GetUserByEmail({
          payload: {
            email: this.data?.recipientEmail,
            userId: null,
          },
        })
      );
    } else {
      this.loggedInUsersSignatureIsLoading = false;
    }
  }

  listenToGetUserByEmailEffect() {
    this.subscription.add(
      this.editorEffects.getUserByEmail$.subscribe((res: any) => {
        if (
          res?.resData?.entity !== null &&
          res?.resData?.entity !== undefined &&
          res?.resData?.succeeded === true
        ) {
          this.loggedInUsersSignature = res?.resData?.entity?.signature;
          this.editorMode = this.editorModeEnum.Internal;
          this.signerFullName =
            res?.resData?.entity?.firstName[0].toUpperCase() +
            res?.resData?.entity?.firstName.slice(1) +
            ' ' +
            res?.resData?.entity?.lastName[0].toUpperCase() +
            res?.resData?.entity?.lastName.slice(1);

          this.data = {
            ...this.data,
            fullName: this.signerFullName,
            initials:
              res?.resData?.entity?.firstName[0].toUpperCase() +
              '.' +
              res?.resData?.entity?.lastName[0].toUpperCase(),
          };

          this.isNonFlowmonoUser = false;
          // this.signatureBase64 = this.loggedInUsersSignature;
        } else {
          this.getExternalUserByEmail();
          this.editorMode = this.editorModeEnum.External;
        }

        this.loggedInUsersSignatureIsLoading = false;
      })
    );
  }

  getExternalUserByEmail() {
    if (this.data?.recipientEmail) {
      this.store.dispatch(
        EditorActions.GetExternalUserByEmail({
          payload: {
            email: this.data?.recipientEmail,
          },
        })
      );
    }
  }

  listenToGetExternalUserByEmailEffect() {
    this.subscription.add(
      this.editorEffects.getExternalUserByEmail$.subscribe((res: any) => {
        if (
          res?.entity !== null &&
          res?.entity !== undefined &&
          res?.succeeded === true
        ) {
          this.loggedInUsersSignature = res.entity.signature;
          this.signerFullName =
            res?.entity?.firstName[0].toUpperCase() +
            res?.entity?.firstName.slice(1) +
            ' ' +
            res?.entity?.lastName[0].toUpperCase() +
            res?.entity?.lastName.slice(1);
          // this.signatureBase64 = this.loggedInUsersSignature;
          this.data = {
            ...this.data,
            fullName: this.signerFullName,
            initials:
              res?.entity?.firstName[0].toUpperCase() +
              '.' +
              res?.entity?.lastName[0].toUpperCase(),
          };

          this.isNonFlowmonoUser = true;
        }

        this.loggedInUsersSignatureIsLoading = false;
      })
    );
  }

  listenToDecodeHashForSubscriberId() {
    // decodeUrlHash

    this.subscription.add(
      this.store
        .pipe(select(editorSelector.getSavedDecodeHash))
        .subscribe((res) => {
          if (res !== null) {
            this.subscriberId = res.signingRequest.subscriberId;
            this.userId = res.signingRequest.userId;
            this.showWaterMark =
              res.signingRequest.documents[0].allowTimeStampOnSignatureControl;
            // this.showWaterMark = true;
          }
        })
    );
  }

  listenToGetLoggedInUserByEditor() {
    this.subscription.add(
      this.store
        .select(editorSelector.getDocumentsBySigningRequestId)
        .subscribe((resData: any) => {
          if (resData) {
            const document = resData[0]?.document;
            // const signType = this.route.snapshot.queryParams.signType;

            if (this.signType === 'self') {
              this.signerFullName = document.createdByName;
              this.showWaterMark = document.allowTimeStampOnSignatureControl;
              this.userEmail = document.email;
              this.data = {
                ...this.data,
                fullName: document.createdByName,
                initials:
                  this.signerFullName.split(' ')[0].charAt(0).toUpperCase() +
                  '.' +
                  this.signerFullName.split(' ')[1].charAt(0).toUpperCase(),
              };

              this.getRecipientActiveSignatures(this.userEmail);
            }
          }
        })
    );
  }

  // hasDrawing(canvas: any) {
  //   const context = canvas.getContext('2d');
  //   const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  //   const data = imageData.data;

  //   for (let i = 0; i < data.length; i += 4) {
  //     if (data[i + 3] !== 0) {
  //       // Check the alpha channel
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  hasDrawing(canvas: any): boolean {
    if (!canvas) {
      return false;
    }

    const context = canvas.getContext('2d');
    if (!context) {
      return false;
    }

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      if (data[i + 3] !== 0) {
        // Check the alpha channel
        return true;
      }
    }
    return false;
  }

  onSaveSignature(checked: boolean) {
    // return;
    if (
      checked &&
      (this.hasDrawing(this.signatureCanvas) || this.signatureBase64 !== null)
    ) {
      this.checked = checked;
      if (this.option === 'draw') {
        this.signatureBase64 = this.signatureCanvas.toDataURL('image/png', 1.0);
      }

      this.formBuilderService.saveSignatureAction.next({
        signature: this.signatureBase64?.split(',')[1] ?? '',
        mimeType: 'image/png',
      });
    }
  }

  createSignaturePh() {
    this.formBuilderService.createSignatureAction.next({
      signature: this.signatureBase64!.split(',')[1],
      isDefaultSignature: this.isNonFlowmonoUser ? true : false,
    });
  }

  updateSignaturePh() {
    const signatureItem = this.data.signatureItem;

    this.formBuilderService.updateSignatureAction.next({
      signatureItem,
      signature: this.signatureBase64!.split(',')[1],
    });
  }

  createSignature() {
    const payload = {
      email: this.data.email || this.data.recipientEmail,
      isDefaultSignature: this.isNonFlowmonoUser ? true : false,
      initials: this.data.initials,
      fullName: this.data.fullName,
      isFlowmonoUser: !this.isNonFlowmonoUser,
      signature: this.signatureBase64!.split(',')[1],
      subscriberId: this.subscriberId,
      userId: this.userId,
    };

    this.store.dispatch(
      recipientSignatureActions.CreateRecipientSignature({
        payload: payload,
      })
    );
  }

  updateSignature() {
    const signatureItem = this.data.signatureItem;
    const payload = {
      email: signatureItem.email,
      isDefaultSignature: signatureItem.isDefaultSignature,
      signatureGuId: signatureItem.guId,
      initials: signatureItem.initials,
      fullName: signatureItem.fullName,
      isFlowmonoUser: signatureItem.isFlowmonoUser,
      signature: this.signatureBase64!.split(',')[1],
      subscriberId: signatureItem.subscriberId,
      userId: this.userId,
    };

    this.store.dispatch(
      recipientSignatureActions.UpdateRecipientSignature({
        payload: payload,
      })
    );
  }

  getRecipientActiveSignatures(email?: string) {
    this.store.dispatch(recipientSignatureActions.IsLoading({ payload: true }));

    this.store.dispatch(
      recipientSignatureActions.GetRecipientActiveSignatures({
        payload: {
          email: this.userEmail,
          skip: this.skip,
          take: 10,
          subscriberId: this.subscriberId,
        },
      })
    );
  }

  // listenToGetActiveRecipientSignatures() {
  //   this.subscription.add(
  //     this.store
  //       .pipe(select(recipientSignatureSelectors.getRecipientActiveSignatures))
  //       .subscribe((res: any) => {
  //         if (res !== null) {
  //           if (res.recipientSignatures && res.recipientSignatures.length > 0) {
  //             this.signatureCount = res.count;
  //             // this.signatureList = res.recipientSignatures;
  //             this.signatureList.push(...res.recipientSignatures);

  //             if (this.signatureList.length < this.signatureCount) {
  //               this.skip = this.skip + 10;
  //               this.getRecipientActiveSignatures();
  //             } else {
  //               this.defaultSignature =
  //                 this.signatureList.find(
  //                   (item) => item.isDefaultSignature === true
  //                 ) ?? null;
  //             }
  //           }
  //         }
  //       })
  //   );
  // }

  onSign() {
    this.signatureCanvas = document.getElementById(
      'sig-canvas'
    ) as HTMLCanvasElement;

    this.signatureCanvas.width = (
      this.mediaContainer.nativeElement as HTMLElement
    ).getBoundingClientRect().width;

    this.signatureCanvas.height = (
      this.mediaContainer.nativeElement as HTMLElement
    ).getBoundingClientRect().height;

    this.signatureCanvasContext = this.signatureCanvas.getContext('2d');
    this.state = {
      mousedown: false,
    };

    this.signatureCanvas.addEventListener(
      'mousedown',
      this.handleWritingStart.bind(this)
    );
    this.signatureCanvas.addEventListener(
      'mousemove',
      this.handleWritingInProgress.bind(this)
    );
    this.signatureCanvas.addEventListener(
      'mouseup',
      this.handleDrawingEnd.bind(this)
    );
    this.signatureCanvas.addEventListener(
      'mouseout',
      this.handleDrawingEnd.bind(this)
    );

    this.signatureCanvas.addEventListener(
      'touchstart',
      this.handleWritingStart.bind(this)
    );
    this.signatureCanvas.addEventListener(
      'touchmove',
      this.handleWritingInProgress.bind(this)
    );
    this.signatureCanvas.addEventListener(
      'touchend',
      this.handleDrawingEnd.bind(this)
    );
  }

  drawLine(beginPoint: any, controlPoint: any, endPoint: any) {
    this.signatureCanvasContext.beginPath();
    this.signatureCanvasContext.moveTo(beginPoint.x, beginPoint.y);
    this.signatureCanvasContext.quadraticCurveTo(
      controlPoint.x,
      controlPoint.y,
      endPoint.x,
      endPoint.y
    );
    this.signatureCanvasContext.stroke();
    this.signatureCanvasContext.closePath();
  }

  handleWritingStart(event: any) {
    event.preventDefault();

    this.state.mousedown = true;

    const mousePos = this.getMousePositionOnCanvas(event);

    // this.signatureCanvasContext.beginPath();
    // this.signatureCanvasContext.moveTo(mousePos.x, mousePos.y);
    // this.points = [];

    this.points.push({ x: mousePos.x, y: mousePos.y, start: true });
    this.beginPoint = { x: mousePos.x, y: mousePos.y };
    this.signatureCanvasContext.lineWidth = this.lineWidth;
    this.signatureCanvasContext.strokeStyle = this.strokeStyle;
    this.signatureCanvasContext.lineJoin = this.lineJoin;
    this.signatureCanvasContext.lineCap = this.lineCap;
    this.signatureCanvasContext.shadowColor = null;
    this.signatureCanvasContext.shadowBlur = null;

    // this.signatureCanvasContext.fill();
  }

  handleWritingInProgress(event: any) {
    event.preventDefault();

    if (!this.state.mousedown) return;

    if (this.state.mousedown) {
      const mousePos = this.getMousePositionOnCanvas(event);
      this.points.push({ x: mousePos.x, y: mousePos.y });

      // this.signatureCanvasContext.lineTo(mousePos.x, mousePos.y);
      // this.signatureCanvasContext.stroke();

      if (this.points.length > 3) {
        const lastTwoPoints = this.points.slice(-2);
        const controlPoint = lastTwoPoints[0];
        const endPoint = {
          x: (lastTwoPoints[0].x + lastTwoPoints[1].x) / 2,
          y: (lastTwoPoints[0].y + lastTwoPoints[1].y) / 2,
        };

        this.drawLine(this.beginPoint, controlPoint, endPoint);
        this.beginPoint = endPoint;
      }
    }
  }

  handleDrawingEnd(event: any) {
    event.preventDefault();

    if (!this.state.mousedown) return;

    if (this.state.mousedown) {
      const mousePos = this.getMousePositionOnCanvas(event);
      this.points.push({ x: mousePos.x, y: mousePos.y });

      this.signatureCanvasContext.shadowColor = this.shadowColor;
      this.signatureCanvasContext.shadowBlur = this.shadowBlur;

      // this.signatureCanvasContext.stroke();

      if (this.points.length > 3) {
        const lastTwoPoints = this.points.slice(-2);
        const controlPoint = lastTwoPoints[0];
        const endPoint = lastTwoPoints[1];
        this.drawLine(this.beginPoint, controlPoint, endPoint);
      }
      this.beginPoint = null;
      // isDown = false;
      // this.createSvgFromCanvas(this.signatureCanvas);
    }

    this.state.mousedown = false;
  }

  handleClearButtonClick(event: any) {
    event.preventDefault();

    this.clearSignature();
  }

  // getMousePositionOnCanvas(event: any) {
  //   const clientX = event?.clientX || event?.touches[0]?.clientX;
  //   const clientY = event?.clientY || event?.touches[0]?.clientY;
  //   const { offsetLeft, offsetTop } = event.target;
  //   const canvasX = clientX - offsetLeft;
  //   const canvasY = clientY - offsetTop;
  //   // console.log(clientX, canvasX, clientY, canvasY);

  //   return { x: canvasX, y: canvasY };
  // }

  // getMousePositionOnCanvas(evt: any) {
  //   const rect = this.signatureCanvas.getBoundingClientRect();
  //   return {
  //     x: evt.clientX - rect.left,
  //     y: evt.clientY - rect.top,
  //   };
  // }
  getMousePositionOnCanvas(event: any) {
    const clientX =
      event.clientX || (event.touches && event.touches[0]?.clientX);
    const clientY =
      event.clientY || (event.touches && event.touches[0]?.clientY);

    const canvasRect = event.target.getBoundingClientRect();
    const canvasX = clientX - canvasRect.left;
    const canvasY = clientY - canvasRect.top;

    return { x: canvasX, y: canvasY };
  }

  onType(input: any, font?: string): string | null {
    let value = null;
    if (typeof input === 'string') {
      value = input;
    } else if (input && input.target && input.target.value) {
      value = input.target.value;
    } else {
      return null;
    }

    if (font) {
      this.font = font;
    }
    // let value = null;
    // value = event.target.value;

    this.textContext = null;
    this.textContext = (
      document.getElementById('textCanvas') as HTMLCanvasElement
    ).getContext('2d')!; //Hidden canvas

    this.textContext!.font = `${this.fontSize} ${this.font}`; /*This needs to be set first so the right width can be calculated*/

    this.textContext!.canvas.width =
      this.textContext!.measureText(value).width + 12;

    this.textContext!.canvas.height = 100;

    this.textContext!.font = `${this.fontSize} ${this.font}`; /*This is set again to ensure the right font is printed*/
    this.textContext!.fillStyle = this.themeColors[this.selectedTheme];

    this.textContext!.fillText(value, 4, 55);

    // this.signatureBase64 = this.textContext!.canvas.toDataURL('image/png', 1.0);

    // this.createSvgFromCanvas(this.textContext!.canvas, value);

    if (font) {
      // return this.signatureBase64;
      return this.textContext!.canvas.toDataURL('image/png', 1.0);
    } else {
      return null;
    }
  }

  async onTypeAsync(input: any, font?: string): Promise<string | null> {
    let value = null;
    if (typeof input === 'string') {
      value = input;
    } else if (input && input.target && input.target.value) {
      value = input.target.value;
    } else {
      return null;
    }

    if (font) {
      this.font = font;
    }

    // let value = null;
    // value = event.target.value;

    this.textContext = null;
    this.textContext = (
      document.getElementById('textCanvas') as HTMLCanvasElement
    ).getContext('2d')!; //Hidden canvas

    this.textContext!.font = `${this.fontSize} ${this.font}`; /*This needs to be set first so the right width can be calculated*/

    this.textContext!.canvas.width =
      this.textContext!.measureText(value).width + 12;

    this.textContext!.canvas.height = 100;

    this.textContext!.font = `${this.fontSize} ${this.font}`; /*This is set again to ensure the right font is printed*/
    this.textContext!.fillStyle = this.themeColors[this.selectedTheme];

    this.textContext!.fillText(value, 4, 55);

    // this.initialsBase64 = this.textContext!.canvas.toDataURL('image/png', 1.0);

    // this.createSvgFromCanvas(this.textContext!.canvas, value);

    if (font) {
      // return this.initialsBase64;
      return this.textContext!.canvas.toDataURL('image/png', 1.0);
    } else {
      return null;
    }
  }

  getSignatureNameList() {
    const tempNameArr: any = [];
    for (let i = 0; i < this.fontList.length; i++) {
      setTimeout(() => {
        if (this.onType(this.data.fullName, this.fontList[i].name) !== null) {
          tempNameArr.push({
            src: this.onType(this.data.fullName, this.fontList[i].name),
            fontName: this.fontList[i].value,
          });
        }
      }, 100);
    }

    this.signatureNameTypeList = tempNameArr;
  }

  getSignatureNameListAsync() {
    const tempNameArr: any = [];
    for (let i = 0; i < this.fontList.length; i++) {
      setTimeout(async () => {
        await document.fonts.load(`16px ${this.fontList[i].name}`);
        const src = await this.onTypeAsync(
          this.data.fullName,
          this.fontList[i].name
        );

        tempNameArr.push({
          src: src,
          fontName: this.fontList[i].name,
        });
      }, 100);
    }

    this.signatureNameTypeList = tempNameArr;
  }

  // getSignatureInitialsList() {
  //   const tempInitialArr: any = [];
  //   for (let i = 0; i < this.fontList.length; i++) {
  //     setTimeout(() => {
  //       if (this.onType(this.data.initials, this.fontList[i].name) !== null) {
  //         tempInitialArr.push({
  //           src: this.onType(this.data.initials, this.fontList[i].name),
  //           fontName: this.fontList[i].value,
  //         });
  //       }
  //     }, 300);
  //   }

  //   this.signatureInitialTypeList = tempInitialArr;
  // }

  onDropDocument(event: any) {
    this.onUploadDocument(event, 2);
  }

  onUploadDocument(event: any, useCase = 1) {
    const file =
      useCase === 2 ? event[0] : (event.target as HTMLInputElement).files![0];

    if (file && file.size <= 1000000) {
      const reader = new FileReader();

      reader.onload = () => {
        const fullBase64String = reader.result!.toString();
        // const base64String = fullBase64String.split(',');

        this.logoPreview = fullBase64String;

        this.signatureBase64 = this.logoPreview;
        this.option = 'upload';
      };

      reader.readAsDataURL(file);
    } else {
      this.documentUpload.nativeElement.value = null;

      const notification: Notification = {
        state: 'warning',
        message: "'Max file size is 1MB'",
      };

      this.notificationService.openNotification(
        notification,
        'flwmn-notification-warning'
      );
    }
  }

  removeLogo() {
    this.logoPreview = null;
    this.signatureBase64 = this.logoPreview;
    // this.registrationForm.patchValue({ logo: null });
    // this.registrationForm.updateValueAndValidity();
  }

  onSelectTheme(event: any) {
    if (this.option !== 'type' && this.option !== 'initial') {
      // this.clearSignature();
    }

    this.selectedTheme = event.target.getAttribute('data-theme');

    this.allThemes = document.querySelectorAll('.theme');

    this.allThemes.forEach((theme: HTMLElement) => {
      if (theme.classList.contains('selected')) {
        theme.classList.remove('selected');
      }
    });

    event.target.classList.add('selected');

    this.strokeStyle = this.shadowColor = this.themeColors[this.selectedTheme];
    this.signatureCanvasContext.clearRect(
      0,
      0,
      this.signatureCanvas.width,
      this.signatureCanvas.height
    );

    this.signatureCanvasContext.lineWidth = this.lineWidth;
    this.signatureCanvasContext.strokeStyle = this.strokeStyle;
    this.signatureCanvasContext.lineJoin = this.lineJoin;
    this.signatureCanvasContext.lineCap = this.lineCap;
    this.signatureCanvasContext.shadowColor = null;
    this.signatureCanvasContext.shadowBlur = null;

    for (const p of this.points) {
      if (p.start) {
        this.beginPoint = { x: p.x, y: p.y };
        this.signatureCanvasContext.beginPath();
        this.signatureCanvasContext.moveTo(p.x, p.y);
      }

      this.signatureCanvasContext.quadraticCurveTo(
        this.beginPoint.x,
        this.beginPoint.y,
        (this.beginPoint.x + p.x) / 2,
        (this.beginPoint.y + p.y) / 2
      );

      this.signatureCanvasContext.stroke();
      this.beginPoint = p;
    }

    this.getSignatureNameList();

    // this.getSignatureInitialsList();
  }

  closeDialog(signature?: any) {
    this.dialogRef.close(signature);
  }

  clearSignature() {
    /************************Signature************************/
    const context = this.signatureCanvas?.getContext('2d');
    context?.clearRect(
      0,
      0,
      this.signatureCanvas.width,
      this.signatureCanvas.height
    );
    context?.beginPath();
    this.points = [];
    /*********************************************************/

    this.input ? (this.input.nativeElement.value = null) : '';

    this.removeLogo();
  }

  // saveSignature() {
  //   if (this.option === 'draw' && this.hasDrawing(this.signatureCanvas)) {
  //     this.signatureBase64 = this.signatureCanvas.toDataURL('image/png', 1.0);
  //   } else if (this.option === 'saved' && this.loggedInUsersSignature) {
  //     this.signatureBase64 = this.loggedInUsersSignature;
  //   }
  //   this.onSaveSignature(this.checked);

  //   this.closeDialog(this.signatureBase64);
  // }

  onFullNameChange(input: any) {
    if (input && input.target && input.target.value) {
      const value = input.target.value;
      const initials =
        value.split(' ')[0].charAt(0).toUpperCase() +
        '.' +
        value.split(' ')[1].charAt(0).toUpperCase();

      this.data = { ...this.data, initials: initials, fullName: value };
      if (this.currentTab === 1) {
        if (this.option === 'type') {
          this.onType(this.data.fullName);
        } else if (this.option === 'initial') {
          this.onType(this.data.initials);
        }
      }

      this.getSignatureNameList();

      // this.getSignatureInitialsList();
    }
  }

  onInitialChange(input: any) {
    if (input && input.target && input.target.value) {
      const value = <string>input.target.value.toUpperCase();

      this.data = { ...this.data, initials: value };
      if (this.currentTab === 1) {
        if (this.option === 'type') {
          this.onType(this.data.fullName);
        } else if (this.option === 'initial') {
          this.onType(this.data.initials);
        }
      }

      // this.getSignatureInitialsList();
    }
  }

  addWaterMark() {
    // console.log(window.innerWidth);

    const canvas = document.getElementById('markedCanvas') as HTMLCanvasElement;
    // canvas.width = markedCanvas.width * 2;
    const spaceToWriteText = 150;
    canvas.width = canvas.width * 4 + 100;
    // if (window.innerWidth < 390) {
    //   canvas.width = canvas.width * 4 + 100;
    //   spaceToWriteText = 150;
    // } else if (window.innerWidth < 450) {
    //   canvas.width = canvas.width * 4 + 100;
    //   spaceToWriteText = 150;
    // } else if (window.innerWidth < 768) {
    //   canvas.width = canvas.width * 4 + 100;
    // } else if (window.innerWidth < 1000) {
    //   canvas.width = canvas.width * 4 + 100;
    // } else {
    //   canvas.width = canvas.width * 4 + 100;
    // }
    const ctx = canvas.getContext('2d')!;

    // Space between images and the line with text
    const space = 10;

    const img1 = new Image();
    img1.crossOrigin = 'anonymous';
    img1.src = this.signatureBase64 as string;

    const img2 = new Image();
    img2.src = '/assets/logo/flowmono-logo-only.svg';

    // Function to draw images and text
    const drawImages = () => {
      const halfHeight = (canvas.height - 2 * space) / 2;

      ctx.drawImage(img1, 200, 0, 1000, halfHeight);

      ctx.font = `${this.fontSize} Arial`;
      ctx.textBaseline = 'bottom';
      const text = 'Signature';
      const textWidth = ctx.measureText(text).width;
      const textX = 10;
      const textY = halfHeight + space + 8;

      ctx.lineWidth = 4;
      const lineX = textX + 10;
      ctx.beginPath();
      ctx.moveTo(lineX, textY);
      ctx.lineTo(canvas.width + 100, textY);
      ctx.stroke();

      ctx.drawImage(img2, 350 - textWidth / 2, textY + 16 + space, 100, 100);

      const smallText = `${new Date().toDateString()} ${new Date()
        .toTimeString()
        .slice(0, 5)}`;
      const smallTextWidth = ctx.measureText(smallText).width;
      const smallTextX = (canvas.width - smallTextWidth) / 2;
      const smallTextY = halfHeight + space + 16;
      ctx.font = `${this.fontSize} Arial`;
      ctx.fillStyle = `${this.themeColors['theme1']}`;
      ctx.fillText(
        smallText,
        smallTextX + spaceToWriteText - textWidth / 2,
        smallTextY + 100
      );

      const fullName = `${this.signerFullName}`;
      const fullNameWidth = ctx.measureText(fullName).width;
      const fullNameX = (canvas.width - fullNameWidth) / 2;
      const fullNameY = halfHeight + space + 16;
      ctx.font = `${this.fontSize} Arial`;
      ctx.fillStyle = `${this.themeColors['theme1']}`;
      ctx.fillText(fullName, fullNameX + 150 - textWidth / 2, fullNameY + 170);

      const dataURL = canvas.toDataURL('image/png', 1.0);
      this.signatureBase64 = dataURL;
      // this.createSvgFromCanvas(canvas);
      this.closeDialog(this.signatureBase64);
    };

    img1.onload = function () {
      if (img2.complete) {
        drawImages();
      }
    };

    img2.onload = function () {
      if (img1.complete) {
        drawImages();
      }
    };
  }

  saveSignature() {
    if (this.data.signatureItem) {
      this.updateSignature();
      this.updateSignaturePh();
    } else if (this.checked || this.data.email) {
      this.createSignature();
      this.createSignaturePh();
    }
  }

  saveSignatures() {
    if (this.option === 'draw' && this.hasDrawing(this.signatureCanvas)) {
      this.signatureBase64 = this.signatureCanvas.toDataURL('image/png', 1.0);
    } else if (this.option === 'saved' && this.loggedInUsersSignature) {
      this.signatureBase64 = this.loggedInUsersSignature;
    }
    // this.onSignatureSave();
    this.onSaveSignature(this.checked);

    if (
      (this.hasDrawing(this.signatureCanvas) || this.option !== 'draw') &&
      this.showSavedSignature &&
      this.showWaterMark
    ) {
      this.addWaterMark();
    } else if (!this.showWaterMark && this.signatureBase64) {
      this.closeDialog(this.signatureBase64);
    } else if (!this.showSavedSignature && this.signatureBase64) {
      this.closeDialog(this.signatureBase64);
    }
    // console.log(this.signatureBase64);

    // this.closeDialog(this.signatureBase64);
  }

  // onDoneSignaturePad() {
  //   if (this.currentTab === 1) {
  //     if (this.option === 'draw' && this.hasDrawing(this.signatureCanvas)) {
  //       this.signatureBase64 = this.signatureCanvas.toDataURL('image/png', 1.0);
  //     }

  //     if (this.option === 'initial') {
  //       this.onType(this.data.initials);
  //     }

  //     if (this.option === 'type' && this.signatureBase64 === undefined) {
  //       this.onType(this.data.fullName);
  //     }
  //     // console.log(this.checked, this.data.email, this.signatureBase64);

  //     if (this.signatureBase64 !== null) {
  //       this.saveSignature();

  //       if (this.showSavedSignature && this.showWaterMark) {
  //         this.addWaterMark();
  //       } else if (!this.showWaterMark && this.signatureBase64) {
  //         this.closeDialog(this.signatureBase64);
  //       } else if (!this.showSavedSignature && this.signatureBase64) {
  //         this.closeDialog(this.signatureBase64);
  //       }
  //     }
  //   } else {
  //     if (this.savedOption === 'default') {
  //       this.signatureBase64 = this.defaultSignature.signature;
  //     }

  //     if (this.signatureBase64 !== null) {
  //       if (this.showSavedSignature && this.showWaterMark) {
  //         this.addWaterMark();
  //       } else if (!this.showWaterMark && this.signatureBase64) {
  //         this.closeDialog(this.signatureBase64);
  //       } else if (!this.showSavedSignature && this.signatureBase64) {
  //         this.closeDialog(this.signatureBase64);
  //       }
  //     }
  //   }
  // }

  onNameTypeSelected(event: any) {
    // const defaultFontSize = this.fontSize;
    // this.fontSize = this.data.fontSize;

    // const base64 = this.onType(this.data.fullName, event.value);
    // if (base64) {
    //   console.log(
    //     base64,
    //     this.signatureNameTypeList.find((item) => item.fontName === event.value)
    //   );

    //   this.option = 'type';
    //   this.fontSize = defaultFontSize;
    // }
    this.option = 'type';
    this.signatureBase64 = event.value;
  }

  onInitialTypeSelected(event: any) {
    // const defaultFontSize = this.fontSize;
    // this.fontSize = this.data.fontSize;
    // const base64 = this.onType(this.data.initials, event.value);
    // if (base64) {
    //   this.option = 'initial';
    //   this.fontSize = defaultFontSize;
    // }

    this.option = 'initial';
    this.signatureBase64 = event.value;
  }

  onDoneSignaturePad() {
    if (this.option === 'draw') {
      if (this.hasDrawing(this.signatureCanvas)) {
        this.signatureBase64 = this.signatureCanvas.toDataURL('image/png', 1.0);
      } else {
        return;
      }
    }

    if (this.signatureBase64 !== null) {
      this.saveSignature();

      // this.addWaterMark();

      // if (this.showSavedSignature && this.showWaterMark) {
      //   this.addWaterMark();
      // } else if (!this.showWaterMark && this.signatureBase64) {
      //   this.closeDialog(this.signatureBase64);
      // } else if (!this.showSavedSignature && this.signatureBase64) {
      //   this.closeDialog(this.signatureBase64);
      // }

      this.closeDialog(this.signatureBase64);

      // this.closeDialog({
      //   base64: this.signatureBase64,
      //   activeTab: this.option.charAt(0).toUpperCase() + this.option.slice(1),
      // });
    }

    // if (this.currentTab === 1) {
    //   if (this.option === 'draw' && this.hasDrawing(this.signatureCanvas)) {
    //     this.signatureBase64 = this.signatureCanvas.toDataURL('image/png', 1.0);
    //   }

    //   if (this.option === 'initial') {
    //     this.onType(this.data.initials);
    //   }

    //   if (this.option === 'type' && this.signatureBase64 === undefined) {
    //     this.onType(this.data.fullName);
    //   }
    //   // console.log(this.checked, this.data.email, this.signatureBase64);

    //   if (this.signatureBase64 !== null) {
    //     this.saveSignature();

    //     if (this.showSavedSignature && this.showWaterMark) {
    //       this.addWaterMark();
    //     } else if (!this.showWaterMark && this.signatureBase64) {
    //       this.closeDialog(this.signatureBase64);
    //     } else if (!this.showSavedSignature && this.signatureBase64) {
    //       this.closeDialog(this.signatureBase64);
    //     }
    //   }
    // } else {
    //   if (this.savedOption === 'default') {
    //     this.signatureBase64 = this.defaultSignature.signature;
    //   }

    //   if (this.signatureBase64 !== null) {
    //     if (this.showSavedSignature && this.showWaterMark) {
    //       this.addWaterMark();
    //     } else if (!this.showWaterMark && this.signatureBase64) {
    //       this.closeDialog(this.signatureBase64);
    //     } else if (!this.showSavedSignature && this.signatureBase64) {
    //       this.closeDialog(this.signatureBase64);
    //     }
    //   }
    // }
  }

  isEditorOrSelfSign(): boolean {
    // const signType = this.route.snapshot.queryParams.signType;
    if ((this.signType && this.signType === 'self') || this.data.isEditor) {
      return true;
    }
    return false;
  }

  onToolOptionClicked(option: 'type' | 'draw' | 'upload') {
    this.option = option;
    this.currentTab = 0;
    if (this.option === 'draw') {
      setTimeout(() => {
        this.onSign();
      }, 500);
    }
  }

  onSavedMenuClicked(menu: 'default' | 'others') {
    this.savedOption = menu;
  }

  onDefaultSignatureClicked(defaultSignature: string) {
    if (defaultSignature !== null) {
      this.signatureBase64 = defaultSignature;
    }
  }

  onOtherSignatureClicked(event: any, signature: string) {
    if (signature !== null) {
      this.signatureBase64 = signature;
    }
  }

  openScanSignature() {
    this.closeDialog();

    this.dialog.open(ScanQrCodeComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: ['flwmn-dialog', 'flwmn-dialog-lg'],
      backdropClass: 'flwmn-dialog-backdrop',
    });

    // this.subscription.add(
    //   dialogRef.afterClosed().subscribe((result: any) => {
    //     if (result) {
    //       // this.currentSignature = result;
    //     }
    //   })
    // );
  }

  onSignatureClicked() {
    this.currentTab = 0;
  }

  onInitialClicked() {
    this.currentTab = 1;
    this.option = 'type';
  }

  // createSvgFromCanvas() {
  //   if (this.points.length === 0) {
  //     return;
  //   }

  //   let pathData = `M ${this.points[0].x} ${this.points[0].y}`;
  //   for (let i = 1; i < this.points.length; i++) {
  //     pathData += ` L ${this.points[i].x} ${this.points[i].y}`;
  //   }

  //   const svgNS = 'http://www.w3.org/2000/svg';
  //   const svg = document.createElementNS(svgNS, 'svg');
  //   svg.setAttribute('xmlns', svgNS);
  //   svg.setAttribute('width', this.signatureCanvas.width.toString());
  //   svg.setAttribute('height', this.signatureCanvas.height.toString());
  //   svg.setAttribute(
  //     'viewBox',
  //     `0 0 ${this.signatureCanvas.width} ${this.signatureCanvas.height}`
  //   );

  //   const pathElement = document.createElementNS(svgNS, 'path');
  //   pathElement.setAttribute('d', pathData);
  //   pathElement.setAttribute('stroke', this.themeColors[this.selectedTheme]);
  //   pathElement.setAttribute('stroke-width', this.lineWidth.toString());
  //   pathElement.setAttribute('fill', 'none');
  //   pathElement.setAttribute('stroke-linecap', this.lineCap);
  //   pathElement.setAttribute('stroke-linejoin', this.lineJoin);

  //   svg.appendChild(pathElement);

  //   const svgString = new XMLSerializer().serializeToString(svg);
  //   const base64SVG = `data:image/svg+xml;base64,${btoa(
  //     unescape(encodeURIComponent(svgString))
  //   )}`;

  //   // this.signatureUploadEvent.emit(base64SVG);
  //   this.signatureBase64 = base64SVG;
  // }
  createSvgFromCanvas(canvas: HTMLCanvasElement, value?: string) {
    const svgNS = 'http://www.w3.org/2000/svg';
    const svg = document.createElementNS(svgNS, 'svg');

    if (value === undefined && this.points.length > 0) {
      // Case 1: Create SVG from points
      let pathData = `M ${this.points[0].x} ${this.points[0].y}`;
      for (let i = 1; i < this.points.length; i++) {
        pathData += ` L ${this.points[i].x} ${this.points[i].y}`;
      }

      svg.setAttribute('xmlns', svgNS);
      svg.setAttribute('width', canvas.width.toString());
      svg.setAttribute('height', canvas.height.toString());
      svg.setAttribute('viewBox', `0 0 ${canvas.width} ${canvas.height}`);

      const pathElement = document.createElementNS(svgNS, 'path');
      pathElement.setAttribute('d', pathData);
      pathElement.setAttribute('stroke', this.themeColors[this.selectedTheme]);
      pathElement.setAttribute('stroke-width', this.lineWidth.toString());
      pathElement.setAttribute('fill', 'none');
      pathElement.setAttribute('stroke-linecap', this.lineCap);
      pathElement.setAttribute('stroke-linejoin', this.lineJoin);

      svg.appendChild(pathElement);
    } else if (value !== undefined) {
      // Case 2: Create SVG from text
      svg.setAttribute(
        'width',
        `${this.textContext!.measureText(value).width + 12}`
      );
      svg.setAttribute('height', '100');

      const textElement = document.createElementNS(svgNS, 'text');
      textElement.setAttribute('x', '4');
      textElement.setAttribute('y', '50%');
      textElement.setAttribute('font-family', this.font);
      textElement.setAttribute('font-size', this.fontSize);
      textElement.setAttribute('fill', this.themeColors[this.selectedTheme]);
      textElement.textContent = value;

      svg.appendChild(textElement);
    }

    const svgString = new XMLSerializer().serializeToString(svg);
    const base64SVG = `data:image/svg+xml;base64,${btoa(
      unescape(encodeURIComponent(svgString))
    )}`;
    this.signatureBase64 = base64SVG;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
