<ng-container *ngIf="(isLoading$ | async) !== false">
  <div class="general-loader-container">
    <div class="general-loader-backdrop"></div>

    <div class="general-loader-content">
      <app-general-loader
        [loaderText]="'Verifying... Please wait'"
      ></app-general-loader>
    </div>
  </div>
</ng-container>

<!-- *ngIf="acceptStatus || rejectStatus" id="device-verification" -->
<!-- <section *ngIf="acceptStatus || rejectStatus" id="device-verification">
  <div class="wallpaper-container">
    <div class="wallpaper-illustrations-container">
      <img
        src="/assets/images/auth/auth-wallpaper-illustration-left.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-left"
      />

      <img
        src="/assets/images/auth/auth-wallpaper-illustration-right.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-right"
      />
    </div>
  </div>

  <div class="page">
    <div class="page-inner">
      <div class="body">
        <div class="logo-container">
          <a routerLink="/">
            <img
              src="/assets/logo/flowmono-logo-white.svg"
              alt="Flowmono Logo White"
              class="logo"
            />
          </a>
        </div>

        <div class="content-section">
          <div class="form-section">
            <div class="device-verification-image-container">
              <img
                src="/assets/icons/auth/password.svg"
                alt="Device Verification"
              />
            </div>

            <h5 class="flwmn-heading-5 fw-700 header-text">
              Device Verification
            </h5>

            <p
              *ngIf="this.feedback === 'accept'"
              class="flwmn-para-1 subheader-text"
            >
              Your device has now been verified! please continue on your login
              screen.
            </p>

            <p
              *ngIf="this.feedback === 'reject'"
              class="flwmn-para-1 subheader-text"
            >
              This device has now been blocked. Please login to update your
              login details.
            </p>
          </div>
        </div>
      </div>

      <footer>
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>
  </div>
</section> -->

<section id="dev-verification" *ngIf="acceptStatus || rejectStatus">
  <div class="logo-overlay-container">
    <div class="img-positioning-container">
      <app-svg
        src="/assets/images/auth/left-illustration.svg"
        svgPathStroke="#e8d4ff"
        svgPathFill="#e8d4ff"
        class="logo-illustration-left"
      ></app-svg>
    </div>
  </div>

  <div class="all-content-container">
    <div class="main-login flwmn-drive-scroll-bar">
      <div class="logo-container">
        <a routerLink="/">
          <img
            src="/assets/logo/flowmono-logo.svg"
            alt="Flowmono Logo White"
            class="logo"
          />
        </a>
      </div>

      <div class="login-page-content-inner">
        <ng-container>
          <div class="content-section-verify">
            <div class="form-section">
              <div class="device-verification-image-container">
                <img
                  src="/assets/icons/auth/password.svg"
                  alt="Device Verification"
                />
              </div>

              <h5 class="flwmn-heading-5 fw-600 header-text">
                {{
                  this.feedback === 'accept'
                    ? 'Verified.'
                    : ' Verify your email address!'
                }}
              </h5>

              <p
                *ngIf="this.feedback === 'accept'"
                class="flwmn-para-1 subheader-text"
              >
                Your device has now been verified! please continue on your login
                screen.
              </p>

              <p
                *ngIf="this.feedback === 'reject'"
                class="flwmn-para-1 subheader-text"
              >
                This device has now been blocked. Please login to update your
                login details.
              </p>
            </div>
          </div>
        </ng-container>
      </div>

      <footer class="footer-copyright">
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>
  </div>
</section>
