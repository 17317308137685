import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import * as SigningRequestActions from './signing-request.actions';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class SigningRequestEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(SigningRequestActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getSigningRequestsBySigningRequestStatus$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SigningRequestActions.GetSigningRequestsBySigningRequestStatus),
        withLatestFrom(this.store.select('auth')),
        switchMap(([signingRequestData, authState]) => {
          // let params = new HttpParams();

          // if (signingRequestData.payload.searchValue) {
          //   params = params.append(
          //     'searchResult',
          //     signingRequestData.payload.searchValue
          //   );
          // }

          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/SigningRequests/getsigningrequestsbysigningrequeststatus`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...signingRequestData.payload,
              },
              {
                // params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SigningRequestActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Signing Request] Get Signing Requests By Signing Request Status Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Signing Request] Failed To Get Signing Requests By Signing Request Status',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Signing Request][CatchError] Failed To Get Signing Requests By Signing Request Status ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getSigningRequestsBySigningRequestBucket$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SigningRequestActions.GetSigningRequestsBySigningRequestBucket),
        withLatestFrom(this.store.select('auth')),
        switchMap(([signingRequestData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/SigningRequests/getsigningrequestsbysigningrequestbucket`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...signingRequestData.payload,
              },
              {
                // params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SigningRequestActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Signing Request] Get Signing Requests By Signing Request Bucket Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Signing Request] Failed To Get Signing Requests By Signing Request Bucket',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Signing Request][CatchError] Failed To Get Signing Requests By Signing Request Bucket ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getSigningRequestById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SigningRequestActions.GetSigningRequestById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([signingRequestData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocDocumentUrl}/SigningRequests/getbyid/${signingRequestData.payload.signingRequestId}/${authState.user.SubscriberId}/${authState.user.UserId}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SigningRequestActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                this.store.dispatch({
                  type: '[Signing Request] Get Signing Request By Id Was Successful',
                });

                return SigningRequestActions.SaveSigningRequestById({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch({
                  type: '[Signing Request] Failed To Get Signing Request By Id',
                });

                return SigningRequestActions.SaveSigningRequestById({
                  payload: null,
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Signing Request][CatchError] Failed To Get Signing Request By Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  resendSigningRequest$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SigningRequestActions.ResendSigningRequest),
        withLatestFrom(this.store.select('auth')),
        switchMap(([signingRequestData, authState]) => {
          const payload = {
            encryptedRequest: this.helperService.encryptData(
              JSON.stringify(signingRequestData.payload)
            ),
          };

          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/Documents/resendtosignatories`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...payload,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SigningRequestActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Signing Request] Resend Signing Request Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Signing Request] Failed To Resend Signing Request',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Signing Request][CatchError] Failed To Resend Signing Request ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getSigningRequestsUserActivity$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SigningRequestActions.GetSigningRequestUserActivity),
        withLatestFrom(this.store.select('auth')),
        switchMap(([signingRequestData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/UserActivity/getuseractivitybysigningrequestid/${authState.user.SubscriberId}/${authState.user.UserId}/${signingRequestData.payload.signingRequestGuid}/${signingRequestData.payload.skip}/${signingRequestData.payload.take}`,

              {
                // params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SigningRequestActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    SigningRequestActions.SaveSigningRequestUserActivity({
                      payload: resData.entity,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Signing Request Activity] Failed To Get Signing Request Activity',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Signing Request Activity][CatchError] Failed To Get Signing Request Activity ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getSigningRequestsAuditTrailDownload$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SigningRequestActions.GetSigningRequestAuditTrailDownload),
        withLatestFrom(this.store.select('auth')),
        switchMap(([signingRequestData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/SigningRequests/downloadsigningrequestaudit/${signingRequestData.payload.signingRequestId}/${authState.user.SubscriberId}`,

              {
                // params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SigningRequestActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    SigningRequestActions.SaveSigningRequestAuditTrailDownload({
                      payload: resData.entity,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Signing Request Audit Trail] Failed To Get  Signing Request Audit Trail',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Signing Request Audit Trail] Failed To Get  Signing Request Audit Trail ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteDraftItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRequestActions.DeleteDraftItem),
      withLatestFrom(this.store.select('auth')),
      switchMap(
        ([
          {
            payload: { type, ...rest },
          },
          authState,
        ]) => {
          return this.http
            .post(
              `${environment.onyxDocDocumentUrl}/SigningRequests/movetotrash`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...rest,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  SigningRequestActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData?.entity,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch(
                    SigningRequestActions.GetSigningRequestsBySigningRequestBucket(
                      {
                        payload: {
                          signingRequestBucket: type,
                          skip: 0,
                          take: 10,
                        },
                      }
                    )
                  );

                  return {
                    type: '[Delete Signing Request Draft]  Delete Signing Request Draft Failed',
                  };
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Delete Signing Request Draft]  Delete Signing Request Draft Failed',
                  };
                }

                // return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Delete Signing Request Draft Creation][CatchError] Failed to Delete Signing Request Draft ${errorRes.message}`
                );
              })
            );
        }
      )
    )
  );
}
