import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import * as fromApp from '../app/app.reducer';
import * as SubscriptionPlanActions from '../subscription-plan/subscription-plan.actions';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import {
  USE_ACCESS_TOKEN,
  USE_DEVELOPER_TOKEN,
} from '../../interceptors/app.interceptor.service';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class SubscriptionPlanEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private router: Router,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(SubscriptionPlanActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllSubscriptionPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionPlanActions.GetAllSubscriptionPlans),
      withLatestFrom(this.store.select('auth')),
      switchMap(([susbscriptionData, authState]) => {
        this.store.dispatch(
          SubscriptionPlanActions.IsFetching({ payload: true })
        );

        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/getsubscriptionplans/`,
            {
              subscriberid: authState.user.SubscriberId,
              userid: authState.user.UserId,
              skip: 0,
              take: 1000,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsFetching({ payload: false })
              );

              this.store.dispatch(
                SubscriptionPlanActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriptionPlanActions.SaveAllSubscriptionPlans({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return SubscriptionPlanActions.SaveAllSubscriptionPlans({
                  payload: [],
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Get All Subscriptions ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getSubscriptionPricings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionPlanActions.GetAllSubscriptionPlanPricings),
      withLatestFrom(this.store.select('auth')),
      switchMap(([susbscriptionData, authState]) => {
        this.store.dispatch(
          SubscriptionPlanActions.IsFetching({ payload: true })
        );
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/SubscriptionPlanPricings/getsubscriptionpricings/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsFetching({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriptionPlanActions.SaveAllSubscriptionPlanPricings({
                  payload: resData.entity.reverse(),
                });
              } else {
                return {
                  type: '[Subscription Plan] Failed To Get All Subscription Pricings',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Get All Subscription Pricings ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getActiveSubscriptionPlansForWebsite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionPlanActions.GetActiveSubscriptionPlansForWebsite),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        this.store.dispatch(
          SubscriptionPlanActions.IsLoading({ payload: true })
        );
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/getactivesubscriptionplans`,
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                return SubscriptionPlanActions.SaveActiveSubscriptionPlansForWebsite(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  SubscriptionPlanActions.SaveActiveSubscriptionPlansForWebsite(
                    { payload: null }
                  )
                );

                return {
                  type: '[Subscription Plan] Failed To Get Active Subscription Plans For Website',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Get Active Subscription Plans For Website ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getActiveSubscriptionPlans$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionPlanActions.GetActiveSubscriptionPlans),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        this.store.dispatch(
          SubscriptionPlanActions.IsLoading({ payload: true })
        );
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/getactivesubscriptionplans/${authState.user.Role.SubscriberId}/${authState.user.UserId}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                return SubscriptionPlanActions.SaveActiveSubscriptionPlans({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Subscription Plan] Failed To Get Active Subscription Plans',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Get Active Subscription Plans ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSubscriptionPlanByIdForWebsite$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.GetSubscriptionPlanByIdForWebsite),
        withLatestFrom(this.store.select('auth')),
        switchMap(([subscriptionData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/getsubscriptionplanbyid/${environment.onyxDocAdminSubscriberId}/${subscriptionData.payload.id}/null`
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Subscription Plan] Get Subscription Plan By Id For Website Was successful',
                  });

                  return resData;
                } else {
                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Get Subscription Plan By Id For Website',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Get Subscription Plan By Id For Website ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getSubscriptionPlanById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionPlanActions.GetSubscriptionPlanById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/getsubscriptionplanbyid/${environment.onyxDocAdminSubscriberId}/${subscriptionData.payload.id}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return SubscriptionPlanActions.SaveSubscriptionPlanById({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  SubscriptionPlanActions.SaveSubscriptionPlanById({
                    payload: null,
                  })
                );

                return {
                  type: '[Subscription Plan] Failed To Get Subscription Plan By Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Get Subscription Plan By Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSubscriptionPlanByIdT$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.GetSubscriptionPlanById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([subscriptionData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/getsubscriptionplanbyid/${environment.onyxDocAdminSubscriberId}/${subscriptionData.payload.id}/${authState.user.UserId}`
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Subscription Plan] Get Subscription Plan By Id Was successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to get subscription plan',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Get Subscription Plan By Id',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Get Subscription Plan By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  activateSubscriptionPlan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionPlanActions.ActivateSubscriptionPlan),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/activatesubscriptionplan`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...subscriptionData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  SubscriptionPlanActions.GetAllSubscriptionPlans()
                );

                return {
                  type: '[Subscription Plan] Activate Subscription Plan Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Subscription Plan] Failed To Activate Subscription Plan',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Activate Subscription Plan ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deactivateSubscriptionPlan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionPlanActions.DeactivateSubscriptionPlan),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/deactivatesubscriptionplan`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...subscriptionData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  SubscriptionPlanActions.GetAllSubscriptionPlans()
                );

                return {
                  type: '[Subscription Plan] Deactivate Subscription Plan Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Subscription Plan] Failed To Deactivate Subscription Plan',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Deactivate Subscription Plan ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getAllSubscriptionFeatures$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.GetAllSubscriptionFeatures),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/SubscriptionPlanFeatures/getallsubscriptionplanfeatures/${authState.user.SubscriberId}/${authState.user.UserId}`
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Subscription Plan] Get All Subscription Features Successful',
                  });
                  return resData.entity.reverse();
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Subscription Plan] Failed To Get All Subscription Features',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Get All Subscription Features ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  signupAndSubscribe$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.SignupAndSubscribe),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/Subscriptions/signupandsubscribe`,
              susbscriptionData.payload
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Subscription Plan] Signup And Subscribe Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Signup And Subscribe',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Signup And Subscribe ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createSubscriptionPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionPlanActions.CreateSubscripitonPlan),
      withLatestFrom(this.store.select('auth')),
      switchMap(([susbscriptionData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/create`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              userId: authState.user.UserId,
              ...susbscriptionData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: true })
                );

                this.store.dispatch(
                  SubscriptionPlanActions.GetAllSubscriptionPlans()
                );

                this.router.navigate([
                  '/app/account-settings/subscription-plan-configuration',
                ]);

                return {
                  type: '[Subscription Plan] Create Subscription Plan Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Subscription Plan] Failed To Create Subscription Plan',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Create Subscription Plan ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  updateSubscriptionPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionPlanActions.UpdateSubscripitonPlan),
      withLatestFrom(this.store.select('auth')),
      switchMap(([susbscriptionData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/update`,
            {
              subscriberId: authState.user.Role.SubscriberId,
              ...susbscriptionData.payload,
              userId: authState.user.UserId,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsLoading({ payload: false })
              );

              if (resData.succeeded) {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: true })
                );

                this.store.dispatch(
                  SubscriptionPlanActions.GetAllSubscriptionPlans()
                );

                this.router.navigate([
                  '/app/account-settings/subscription-plan-configuration',
                ]);
                return {
                  type: '[Subscription Plan] Update Subscription Plan Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Subscription Plan] Failed To Update Subscription Plan',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Update Subscription Plan ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getActiveCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionPlanActions.GetActiveCurrencies),
      withLatestFrom(this.store.select('auth')),
      switchMap(([susbscriptionData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Currency/getactivecurrencies/${authState.user.Role.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                return SubscriptionPlanActions.SaveActiveCurrencies({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Subscription Plan] Failed To Get Active Currencies',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Get Active Currencies ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  deleteSubscriptionPlanPricing$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.DeleteSubscriptionPricing),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/SubscriptionPlanPricings/deletesubscriptionpricing`,
              {
                subscriberId: authState.user.Role.SubscriberId,
                subscriptionPlanId:
                  susbscriptionData.payload.subscriptionPlanId,
                id: susbscriptionData.payload.id,
                userId: authState.user.UserId,
              },
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Subscription plan pricing was deleted successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Delete Subscription Plan Pricing Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Delete Subscription Plan Pricing',
                  });
                }

                return {
                  instance: susbscriptionData?.additionalPayload?.instance,
                  resData: resData,
                  index: susbscriptionData?.additionalPayload?.index,
                };
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Delete Subscription Plan Pricing ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getAllSubscriptionPlan$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.GetSubscriptionPlan),
        withLatestFrom(this.store.select('auth')),
        switchMap(([subscribersData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/getactivesubscriptionplans/${authState.user.SubscriberId}/${authState.user.UserId}`
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    SubscriptionPlanActions.SaveAllSubscriptionPlan({
                      payload: resData.entity.reverse(),
                    })
                  );
                } else {
                  this.store.dispatch({
                    type: '[Subscribers] Failed To Get All Subscription Plan',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Get All Subscription Plan ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getCurrentSubscriptionPlan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionPlanActions.GetCurrentSubscriptionPlan),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionPlanData, authState]) => {
        return this.http
          .get<GenericResponse>(
            // `${environment.onyxDocSubscriptionUrl}/Subscriptions/getactivesubscriptionbysubscriberid/${authState.user.SubscriberId}/${authState.user.UserId}`
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/getactivesubscriptions/${authState.user.SubscriberId}/${authState.user.UserId}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsLoading({ payload: true })
              );

              if (resData.succeeded === true) {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: false })
                );
                return SubscriptionPlanActions.SaveCurrentSubscriptionPlan({
                  payload: resData.entity,
                });
              } else {
                return {
                  type: '[Subscribers] Failed To Get Current Subscription Plan',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Get Current Subscription Plan ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getPreviousSubscriptionPlan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionPlanActions.GetPreviousSubscriptionPlan),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionPlanData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/SubscriptionPlans/getprevioussubscriptionplan/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriptionPlanActions.SavePreviousSubscriptionPlan({
                  payload: resData.entity,
                });
              } else {
                return {
                  type: '[Subscribers] Failed To Get Previous Subscription Plan',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Get Previous Subscription Plan ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSubscriptionPlanHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionPlanActions.GetSubscriptionPlanHistory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionPlanData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Subscriptions/getallsubscriptions/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriptionPlanActions.SaveSubscriptionPlanHistory({
                  payload: resData.entity.reverse(),
                });
              } else {
                return {
                  type: '[Subscribers] Failed To Get Subscription Plan History',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Get Subscription Plan History ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  /*************************************************************/
  getSubscriptionPlanFeaturesBySubscriptionPlanId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SubscriptionPlanActions.GetSubscriptionPlanFeaturesBySubscriptionPlanId
      ),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionPlanData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/SubscriptionPlanFeatures/getsubscriptionplanfeaturesbysubscriptionplan/${authState.user.SubscriberId}/${subscriptionPlanData.payload.subscriptionPlanId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriptionPlanActions.SaveSubscriptionPlanFeaturesBySubscriptionPlanId(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return SubscriptionPlanActions.SaveSubscriptionPlanFeaturesBySubscriptionPlanId(
                  {
                    payload: [],
                  }
                );
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Get Subscription Plan Features By Subscription Plan Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  // getSubscriptionPlanFeaturesBySubscriptionPlanIdAndRoleAccessLevel$ =
  //   createEffect(() => {
  //     return this.actions$.pipe(
  //       ofType(
  //         SubscriptionPlanActions.GetSubscriptionPlanFeaturesBySubscriptionPlanIdAndRoleAccessLevel
  //       ),
  //       withLatestFrom(this.store.select('auth')),
  //       switchMap(([subscriptionPlanData, authState]) => {
  //         return this.http
  //           .get<GenericResponse>(
  //             `${environment.onyxDocSubscriptionUrl}/SubscriptionPlanFeatures/getsubscriptionplanfeaturesbysubscriptionplanidandroleroleaccesslevel/${subscriptionPlanData.payload.skip}/${subscriptionPlanData.payload.take}/${subscriptionPlanData.payload.roleAccessLevel}/${authState.user.SubscriberId}/${subscriptionPlanData.payload.subscriptionPlanId}/${authState.user.UserId}`
  //           )
  //           .pipe(
  //             map((resData: any) => {
  //               this.store.dispatch(
  //                 SubscriptionPlanActions.IsLoading({ payload: false })
  //               );

  //               if (resData.succeeded === true) {
  //                 return SubscriptionPlanActions.SaveSubscriptionPlanFeaturesBySubscriptionPlanIdAndRoleAccessLevel(
  //                   {
  //                     payload: resData.entity,
  //                   }
  //                 );
  //               } else {
  //                 const notification: Notification = {
  //                   state: 'error',
  //                   message: resData.message || resData.messages[0],
  //                 };

  //                 this.notificationService.openSnackBar(
  //                   notification,
  //                   'flwmn-notification-error'
  //                 );

  //                 return SubscriptionPlanActions.SaveSubscriptionPlanFeaturesBySubscriptionPlanIdAndRoleAccessLevel(
  //                   {
  //                     payload: [],
  //                   }
  //                 );
  //               }
  //             }),
  //             catchError((errorRes) => {
  //               return this.handleCatchError(
  //                 errorRes,
  //                 `[Subscription Plan][CatchError] Failed To Get Subscription Plan Features By Subscription Plan Id And Role Access Level ${errorRes.message}`
  //               );
  //             })
  //           );
  //       })
  //     );
  //   });
  /*************************************************************/

  getActiveSubscriptionPlanForCorporateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionPlanActions.GetActiveSubscriptionPlanForCorporateUser),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionPlanData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/SubscriptionPlanFeatures/getsubscriptionplanfeaturesbysubscriberid/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                SubscriptionPlanActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return SubscriptionPlanActions.SaveActiveSubscriptionPlanForCorporateUser(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                return {
                  type: '[Subscribers] Failed To Get Active Subscription Plan Features For Corporate Users',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Subscription Plan][CatchError] Failed To Get Active Subscription Plan Features For Corporate Users ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  cancelSubscriptionAutoRenewal$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.CancelSubscriptionAutoRenewal),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/Subscriptions/cancelsubscription`,
              {
                subscriberId: authState.user.Role.SubscriberId,
                id: susbscriptionData.payload.id,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Subscription auto renewal was cancelled successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch(
                    SubscriptionPlanActions.GetCurrentSubscriptionPlan()
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Cancel Subscription auto renewal Was successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to Cancel subscription auto renewal',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Cancel Subscription Auto Renewal',
                  });

                  return {
                    status: false,
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Cancel Subscription Auto Renewal ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  changeSubscription$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.ChangeSubscription),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/Subscriptions/changesubscription`,
              {
                ...susbscriptionData.payload,
                subscriberId: authState.user.Role.SubscriberId,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Subscription was changed successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Subscription Was changed successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to Change subscription',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Change Subscription',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Change Subscription ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  renewSubscription$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.RenewSubscription),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/Subscriptions/renewsubscription`,
              {
                ...susbscriptionData.payload,
                subscriberId: authState.user.Role.SubscriberId,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Subscription was renewed successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Subscription Was renewed successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to renew subscription',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Renew Subscription',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Renew Subscription ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getAuditParameter$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.GetAuditParameter),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/AuditTrails/getauditparameters/${authState.user.UserId}/${authState.user.SubscriberId}`
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Subscription Plan] Audit paramter retrived successfully',
                  });

                  return resData.entity;
                } else {
                  // this.store.dispatch(
                  //   GeneralActions.SetNotification({
                  //     payload: {
                  //       isSuccess: false,
                  //       message: 'Failed to Change subscription',
                  //     },
                  //   })
                  // );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Get Audit Parameter',
                  });

                  return resData.entity;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Get Audit Parameter ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getSubscriptionById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.GetSubscriptionById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/Subscriptions/getsubscriptionbyid/${authState.user.SubscriberId}/${susbscriptionData.payload.id}/${authState.user.UserId}`
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Subscription Plan] Get Subscription by ID',
                  });

                  // return this.store.dispatch(
                  //   SubscriptionPlanActions.SaveSubscriptionById({
                  //     payload: resData.entity,
                  //   })
                  // );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to get subscription',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Get Subscription by Id',
                  });

                  // return {
                  //   status: false,
                  // };
                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Get Subscription By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  checkExpirationPeriod$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.CheckExpirationPeriod),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/SubscriptionExpirationCheck/create`,
              {
                ...susbscriptionData.payload,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Subscription Plan] Check Expiration Period Was Successful',
                  });

                  return resData;
                } else {
                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Check Exipration Period',
                  });

                  return {
                    status: false,
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Check Exipration Period ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getAllStoragePlan$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.GetAllStoragePlan),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/StoragePlanConfiguration/getallstorageplanconfiguration/${authState.user.SubscriberId}/${authState.user.UserId}`
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  SubscriptionPlanActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Subscription Plan] Get All Storage Plan Was Successful',
                  });

                  // return this.store.dispatch(
                  //   SubscriptionPlanActions.SaveSubscriptionById({
                  //     payload: resData.entity,
                  //   })
                  // );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to get all storage plan',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Get Active Storage Plan',
                  });

                  // return {
                  //   status: false,
                  // };
                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Get All Storage Plan ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getActiveStoragePlanById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.GetActiveStoragePlanById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([subscriptionData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/StoragePlanConfiguration/getactivestorageplanconfigurationbyid/${authState.user.SubscriberId}/${subscriptionData.payload.id}/${authState.user.UserId}`
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Subscription Plan] Get Active Storage Plan By Id Was Successful',
                  });

                  this.store.dispatch(
                    SubscriptionPlanActions.IsLoading({ payload: false })
                  );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to get active storage plan by id',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Get Active Storage Plan By Id',
                  });

                  // return {
                  //   status: false,
                  // };
                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Get Active Storage Plan By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createStoragePlan$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.CreateStoragePlan),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/StoragePlanConfiguration/createstorageplanconfiguration`,
              {
                ...susbscriptionData.payload,
                subscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded) {
                  this.store.dispatch(
                    SubscriptionPlanActions.GetAllStoragePlan()
                  );

                  const notification: Notification = {
                    state: 'success',
                    message: 'Storage plan created successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch(
                    SubscriptionPlanActions.IsLoading({ payload: false })
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Create Storage Plan Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to create storage plan',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Create Storage Plan',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Create Storage Plan ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateStoragePlan$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.UpdateStoragePlan),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/StoragePlanConfiguration/updatestorageplanconfiguration`,
              {
                ...susbscriptionData.payload,
                subscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded) {
                  this.store.dispatch(
                    SubscriptionPlanActions.GetAllStoragePlan()
                  );

                  const notification: Notification = {
                    state: 'success',
                    message: 'Storage plan updated successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch(
                    SubscriptionPlanActions.IsLoading({ payload: false })
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Update Storage Plan Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to update storage plan',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Update Storage Plan',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Update Storage Plan ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  activateStoragePlan$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.ActivateStoragePlan),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/StoragePlanConfiguration/activatestorageplanconfiguration`,
              {
                ...susbscriptionData.payload,
                subscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded) {
                  this.store.dispatch(
                    SubscriptionPlanActions.GetAllStoragePlan()
                  );

                  const notification: Notification = {
                    state: 'success',
                    message: 'Storage plan activated successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch(
                    SubscriptionPlanActions.IsLoading({ payload: false })
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Activate Stroage Plan Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to activate storage plan',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Activate Storage Plan',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Activate Storage Plan ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deactivateStoragePlan$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.DeactivateStoragePlan),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/StoragePlanConfiguration/deactivatestorageplanconfiguration`,
              {
                ...susbscriptionData.payload,
                subscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded) {
                  this.store.dispatch(
                    SubscriptionPlanActions.GetAllStoragePlan()
                  );

                  const notification: Notification = {
                    state: 'success',
                    message: 'Storage plan deactivated successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch(
                    SubscriptionPlanActions.IsLoading({ payload: false })
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Deactivate Storage Plan Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed to deactivated storage plan',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Deactivate Storage Plan',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Deactivate Storage Plan ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  purchaseStoragePlan$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionPlanActions.PurchaseStoragePlan),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/StoragePlanConfiguration/createstorageplanconfigurationsubscription`,
              {
                ...susbscriptionData.payload,
                subscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Storage plan purchased successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch(
                    SubscriptionPlanActions.IsLoading({ payload: false })
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Purchase Storage Plan Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: 'Failed To purchase storage plan',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Subscription Plan] Failed To Purchase Storage Plan',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Subscription Plan][CatchError] Failed To Purchase Storage Plan ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );
}
