import { createAction, props } from '@ngrx/store';
import { DocumentType } from '../../enums';
import {
  CommentDetails,
  DriveCollaborator,
  FileProgressItem,
  Filter,
} from '../../interfaces/drive.interface';

export const ResetStore = createAction('[Drive] Reset Store');

export const SetCurrentFilter = createAction(
  '[Drive] Set Current Filter',
  props<{ payload: 'File' | 'Folder' }>()
);

export const IsLoading = createAction(
  '[Drive] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const IsDataLoading = createAction(
  '[Drive] Is Data Loading',
  props<{
    payload: boolean;
  }>()
);

export const IsFetching = createAction(
  '[Drive] Is Fetching',
  props<{
    payload: boolean;
  }>()
);

export const IsLoadingInformation = createAction(
  '[Drive] Is Loading Information',
  props<{
    payload: boolean;
  }>()
);

export const MovingDriveItem = createAction(
  '[Drive] Is Moving Drive Item ',
  props<{
    payload: boolean;
  }>()
);

export const IsMoveModalLoadingData = createAction(
  '[Drive] Is Loading Move modal Loading state',
  props<{
    payload: boolean;
  }>()
);

export const IsDriveLeftPaneToggled = createAction(
  '[Drive] Is Drive Left Pane Toggled',
  props<{
    payload: boolean;
  }>()
);

export const IsScrollFetching = createAction(
  '[Drive] Is scroll Fetch Loading',
  props<{
    payload: boolean;
  }>()
);

export const IsStorageLoading = createAction(
  '[Drive] Is Storage Loading',
  props<{
    payload: boolean;
  }>()
);

export const SetModulePagination = createAction(
  '[Drive] SetDrivePagination',
  props<{
    payload: { skip: number; take: number };
  }>()
);

export const SetCurrentModuleViewed = createAction(
  '[Drive] Set Current Module Opened for Reloading Data',
  props<{
    payload:
      | 'my-drive'
      | 'recent-files'
      | 'recycle-bin'
      | 'shared-with-others'
      | 'shared-with-me'
      | 'secured-folder';
  }>()
);

export const TrackCurrentlyOpenedFolderGuid = createAction(
  '[Drive] Track Currently Opened Folder Guid',
  props<{
    payload: string;
  }>()
);

export const GetMyDrive = createAction(
  '[Drive] Get My Drive',
  props<{
    payload: {
      Filter: Filter;
      skip?: number;
      take?: number;
    };
  }>()
);

export const GetMyDriveJSON = createAction('[Drive] Get My Drive JSON');

export const SaveDriveItems = createAction(
  '[Drive] Save Drive Items',
  props<{
    payload: any[];
  }>()
);

export const SaveMyDriveJSON = createAction(
  '[Drive] Save My Drive JSON',
  props<{
    payload: any[];
  }>()
);

export const GetDriveItems = createAction(
  '[Drive] Get Drive Items',
  props<{
    payload: {
      itemId: any;
      type:
        | 'my-drive'
        | 'recent-files'
        | 'shared-with-me'
        | 'shared-with-others'
        | 'secured-folder'
        | 'recycle-bin';
      filter: Filter;

      skip?: number;
      take?: number;
    };
  }>()
);

export const SaveDriveItem = createAction(
  '[Drive] Save Drive Item',
  props<{
    payload: any[];
  }>()
);

export const GetDriveDetails = createAction(
  '[Drive] Get Drive Details',
  props<{
    payload: {
      itemId: string | null;
    };
  }>()
);

export const SaveDriveDetails = createAction(
  '[Drive] Save Drive Details',
  props<{
    payload: any | null;
  }>()
);

export const GetDriveActivities = createAction(
  '[Drive] Get Drive Activities',
  props<{
    payload: {
      itemId: string | null;
    };
  }>()
);

export const SaveDriveActivities = createAction(
  '[Drive] Save Drive Activities',
  props<{
    payload: any[];
  }>()
);

export const SaveSingleClickCurrentItem = createAction(
  '[Drive] Save Single Click Current Item',
  props<{
    payload: any;
  }>()
);

export const SaveCtrlClickedItems = createAction(
  '[Drive] Save Control Clicked Drive Items',
  props<{
    payload: any;
  }>()
);

export const AddOrRemoveSelectedItem = createAction(
  '[Drive] Add Control Clicked item',
  props<{ payload: any }>()
);

export const SaveDoubleClickCurrentItem = createAction(
  '[Drive] Save Double Click Current Item',
  props<{
    payload: any;
  }>()
);

/* Folders Config */

export const GetFolders = createAction(
  '[Drive] Get All folders',
  props<{
    payload: {
      skip: number;
      take: number;
      parentGuid?: string;
      search?: string;
      createdStartDate?: string;
      createdEndDate?: string;
      lastModifiedStartDate?: string;
      lastModifiedEndDate?: string;
    };
  }>()
);

export const GetRootFoldersTreeStructure = createAction(
  '[Drive] Get Root folders tree Structure',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetNodeFoldersTreeStructure = createAction(
  '[Drive] Get All Node Folder tree Structure',
  props<{
    payload: {
      parentGuId: string;
      skip: number;
      take: number;
    };
  }>()
);

export const GetSecuredFolders = createAction(
  '[Drive] Get All Secured folders',
  props<{
    payload: {
      Skip: number;
      Take: number;
      FolderGuid?: string;
      Search?: string;
      CreatedStartDate?: string;
      CreatedEndDate?: string;
      LastModifiedStartDate?: string;
      LastModifiedEndDate?: string;
    };
  }>()
);

// Get all Recycle Folder
export const GetRecycleFolders = createAction(
  '[Drive] Get All Recycle Folder',
  props<{
    payload: {
      skip: number;
      take: number;
      search?: string;
      createdStartDate?: string;
      createdEndDate?: string;
      lastModifiedStartDate?: string;
      lastModifiedEndDate?: string;
    };
  }>()
);

// Get all Recycle file
export const GetRecycleFiles = createAction(
  '[Drive] Get All Recycle File',
  props<{
    payload: {
      skip: number;
      take: number;
      search?: string;
      createdStartDate?: string;
      createdEndDate?: string;
      lastModifiedStartDate?: string;
      lastModifiedEndDate?: string;
    };
  }>()
);

export const GetFoldersForModalSetups = createAction(
  '[Drive] Get folders For Modal setUp',
  props<{
    payload: {
      skip: number;
      take: number;
      parentGuid?: string;
      search?: string;
    };
  }>()
);

export const GetDriveFolderByGuId = createAction(
  '[Drive] Get Drive folder By Guid',
  props<{
    payload: {
      guId: string;
    };
  }>()
);

export const GetDriveFolderByGuIdForDrive = createAction(
  '[Drive] Get Drive folder By Guid For Drive',
  props<{
    payload: {
      guId: string;
    };
  }>()
);

export const GetFolderOnExternalAccessByGuId = createAction(
  '[Drive] Get Drive folder Recursively By Guid',
  props<{
    payload: {
      guId: string;
    };
  }>()
);

export const GetFolderByStatus = createAction(
  '[Drive] Get Folder By Status',
  props<{
    payload: {
      skip: number;
      take: number;
      status: number;
    };
  }>()
);

export const GetFolderByFolderStatus = createAction(
  '[Drive] Get Folder By Folder Status',
  props<{
    payload: {
      skip: number;
      take: number;
      folderStatus: number;
    };
  }>()
);

export const GetRecentFolders = createAction(
  '[Drive] Get Recent folders',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const CreateFolderDrive = createAction(
  '[Drive] Create drive Folder',
  props<{
    payload: {
      parentFolderId: string;
      name: string;
    };
  }>()
);

export const CreateFolder = createAction(
  '[Drive] Create Folder',
  props<{
    payload: {
      parentGuid?: string;
      name: string;
    };
    triggerReload: boolean;
    parentId?: string;
  }>()
);

// create folder previous payload
/*   props<{
    payload: {
      rootFolderId: number;
      parentFolderId: number;
      name: string;
      description: string;
      folderType: number;
      createFolderRecipientRequests: {
        roleId: number | null;
        roleName: string | null;
        email: string | null;
        filePermission: number;
      }[];
    };
  }>() */

// export const UpdateFolder = createAction(
//   '[Drive] Update Folder',
//   props<{
//     payload: {
//       id: number;
//       name: string;
//       editFolderRecipientRequests: {
//         id: number;
//         roleId: number | null;
//         roleName: string | null;
//         sharedWithName: string | null;
//         email: string | null;
//         filePermission: number;
//       }[];
//     };
//   }>()
// );

export const UpdateFolder = createAction(
  '[Drive] Update Folder',
  props<{
    payload: {
      guId: string;
      name: string;
    };
    parentGuid?: string;
  }>()
);

/* Move Folder  To Recycle bin */
export const EmptyRecycleBin = createAction('[Drive] Empty Recycle Bin');

export const DeleteFolder = createAction(
  '[Drive] Delete Folder',
  props<{
    payload: {
      folderGuIds: string[];
    };
    parentGuid?: string;
  }>()
);

/* Recycle Folder */
export const ActivateFolder = createAction(
  '[Drive] Activate Folder',
  props<{
    payload: {
      guId: string;
    };
  }>()
);

export const MoveFolder = createAction(
  '[Drive] Move Folder',
  props<{
    payload: {
      parentFolderGuId: string;
      folderGuIds: string[];
    };
  }>()
);

export const DuplicateFolder = createAction(
  '[Drive] Duplicate Folder',
  props<{
    payload: {
      folderId: number;
    };
  }>()
);

export const DuplicateDocument = createAction(
  '[Drive] Duplicate Document',
  props<{
    payload: {
      documentId: number;
    };
  }>()
);

export const ShareFolder = createAction(
  '[Drive] Share Folder',
  props<{
    payload: {
      folderId: number;
      fileLink: string;
      fileShareMessage: string;
      fileShareDetails: {
        roleId: number;
        roleName: string | null;
        sharedWithName: string;
        email: string;
        filePermission: number;
        folderId: number;
        documentId: number;
      }[];
    };
  }>()
);

export const ShareDocument = createAction(
  '[Drive] Share Document',
  props<{
    payload: {
      documentId: number;
      fileLink: string;
      fileShareSubject: string | null;
      fileShareMessage: string;
      fileShareDetails: {
        roleId: number;
        roleName: string | null;
        sharedWithName: string;
        email: string;
        filePermission: number;
        folderId: number;
        documentId: number;
      }[];
    };
  }>()
);

export const MoveFiles = createAction(
  '[Drive] Move Files',
  props<{
    payload: {
      folderIds: number[];
      documentIds: number[];
      destinationFolderId: number;
    };
  }>()
);

export const ArchiveFolder = createAction(
  '[Drive] Archive Folder',
  props<{
    payload: {
      folderId: number;
    };
  }>()
);

export const ArchiveDocument = createAction(
  '[Drive] Archive Document',
  props<{
    payload: {
      documentId: number;
    };
  }>()
);

export const UnarchiveFolder = createAction(
  '[Drive] Unarchive Folder',
  props<{
    payload: {
      folderId: number;
    };
  }>()
);

export const UnarchiveDocument = createAction(
  '[Drive] Unarchive Document',
  props<{
    payload: {
      documentId: number;
    };
  }>()
);

/* export const DeleteFolder = createAction(
  '[Drive] Delete Folder',
  props<{
    payload: {
      folderId: number;
      moveToTrash: boolean;
    };
  }>()
); */

export const getFolderContentById = createAction(
  '[Drive] Get Folder Content By Id',
  props<{
    payload: {
      folderId: number;
      rootFolderId: number;
    };
  }>()
);

export const SaveFolderContent = createAction(
  '[Drive] Save Folder Content',
  props<{
    payload: any[] | null;
  }>()
);

export const UploadDrives = createAction(
  '[Drive] Upload Drive',
  props<{
    payload: {
      DestinationFolderGuId?: string;
      FolderType: number;
      Files: File[];
    };
    metaData: {
      id: number;
    };
  }>()
);

export const UploadDriveProgress = createAction(
  '[Drive] set Drive Upload Progress',
  props<{
    payload: number;
  }>()
);

export const GetDriveFile = createAction(
  '[Drive] Get Drive File Items',
  props<{
    payload: {
      skip: number;
      take: number;
      folderGuid?: string;
      createdStartDate?: string;
      createdEndDate?: string;
      lastModifiedStartDate?: string;
      lastModifiedEndDate?: string;
      search?: string;
    };
  }>()
);

export const GetSecuredDriveFile = createAction(
  '[Drive] Get Secured Drive File',
  props<{
    payload: {
      Skip: number;
      Take: number;
      FolderGuid?: string;
      CreatedStartDate?: string;
      CreatedEndDate?: string;
      LastModifiedStartDate?: string;
      LastModifiedEndDate?: string;
      Search?: string;
    };
  }>()
);

export const GetDriveFileByFileGuId = createAction(
  '[Drive] Get Drive File By File GuId',
  props<{
    payload: {
      fileGuId: string;
    };
  }>()
);

export const GetDriveFileByDriveItemStatus = createAction(
  '[Drive] Get Drive File By Drive Item Status',
  props<{
    payload: {
      skip: number;
      take: number;
      driveitemstatus: string;
    };
  }>()
);

export const GetRecentDriveFile = createAction(
  '[Drive] Get Recent Drive File',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetDriveFileByStatus = createAction(
  '[Drive] Get Drive File By Status',
  props<{
    payload: {
      skip: number;
      take: number;
      status: number;
    };
  }>()
);

export const SaveDriveFiles = createAction(
  '[Drive] Save Drive Files',
  props<{
    payload: any[];
  }>()
);

export const MoveDriveFile = createAction(
  '[Drive] Move Drive File',
  props<{
    payload: {
      filesGuIds: string[];
      parentFolderGuId: string;
    };
  }>()
);

export const DuplicateDriveFile = createAction(
  '[Drive] Duplicate Drive File',
  props<{
    payload: {
      fileGuId: string;
    };
  }>()
);

export const RenameDriveFile = createAction(
  '[Drive] Rename Drive File',
  props<{
    payload: {
      guId: string;
      name: string;
    };
  }>()
);

export const DeleteDriveFile = createAction(
  '[Drive] Delete Drive File',
  props<{
    payload: {
      filesGuIds: string[];
    };
  }>()
);

export const RecycleDriveFolders = createAction(
  '[Drive] Recycle Drive Folder',
  props<{
    payload: {
      folderGuIds: string[];
    };
  }>()
);

export const RecycleDriveFiles = createAction(
  '[Drive] Recycle Drive File',
  props<{
    payload: {
      filesGuIds: string[];
    };
  }>()
);

/* Recycle File */
export const ActivateDriveFile = createAction(
  '[Drive] Activate Drive File',
  props<{
    payload: {
      guId: string;
    };
  }>()
);

export const RestoreDriveFile = createAction(
  '[Drive] Restore Drive File',
  props<{
    payload: {
      filesGuIds: string[];
    };
  }>()
);

export const RestoreDriveFolder = createAction(
  '[Drive] Restore Drive Folder',
  props<{
    payload: {
      folderGuIds: string[];
    };
  }>()
);

export const UploadDocument = createAction(
  '[Drive] Upload Document',
  props<{
    payload: {
      mimeType: string;
      name: string;
      destinationFolderId: number;
      documentType: DocumentType;
      file: string;
      extension: string;
    };
  }>()
);

export const UploadDocumentsUsingIformFile = createAction(
  '[Drive] Upload Documents Using Iform File',
  props<{
    payload: {
      signingRequestId: number;
      files: File[];
      destinationFolderId: number | string;
    };
    additionalPayload?: {
      bearerToken?: string;
    };
  }>()
);

export const UpdateDocumentName = createAction(
  '[Drive] Update Document Name',
  props<{
    payload: {
      documentId: number;
      name: string;
    };
  }>()
);

export const DeleteDocument = createAction(
  '[Drive] Delete Document',
  props<{
    payload: {
      documentId: number;
      moveToTrash: boolean;
    };
  }>()
);

export const GetAuditParameter = createAction('[Drive] Get Audit Parameter');

export const CreateFeedback = createAction(
  '[Drive] Create Feedback',
  props<{
    payload: {
      documentId: number;
      userId: string;
      comment: string;
      rating: number;
      subscriberId: number;
    };
  }>()
);

export const GetCommentOnDocumentById = createAction(
  '[Drive] Get Comment On Document',
  props<{
    payload: {
      documentId: number;
    };
  }>()
);

export const SaveCommentOnDocument = createAction(
  '[Drive] Save Comment On Document',
  props<{
    payload: any;
  }>()
);

// Address book

export const CreateAddressBook = createAction(
  '[Drive] Create Address Book ',
  props<{
    payload: {
      firstName: string;
      lastName: string;
      emailAddress: string;
      phoneNumber: string;
      profilePicture: string;
      pictureExtension: string;
      mimeType: string;
      companyName: string;
      companyAddress: string;
      companyEmail: string;
      companyPhoneNumber: string;
      state: string;
      country: string;
      registrationNumber: string;
      postalCode: string;
      contactCategory: string;
      contactType: number;
      phoneCode: string;
      companyPhoneCode: string;
    };
  }>()
);

export const UpdateAddressBook = createAction(
  '[Drive] Update Address Book ',
  props<{
    payload: {
      id: number;
      firstName: string;
      lastName: string;
      emailAddress: string;
      phoneNumber: string;
      profilePicture: string;
      pictureExtension: string;
      mimeType: string;
      companyName: string;
      companyAddress: string;
      companyEmail: string;
      companyPhoneNumber: string;
      state: string;
      country: string;
      registrationNumber: string;
      postalCode: string;
      contactCategory: string;
      contactType: number;
      phoneCode: string;
      companyPhoneCode: string;
    };
  }>()
);

export const GetAddressBookBySubscriberId = createAction(
  '[Drive] Get Address Book By Subscriber Id'
);

export const SaveAddressBookBySubscriberId = createAction(
  '[Drive] Save Address Book By Subscriber Id',
  props<{
    payload: any;
  }>()
);

export const GetContactById = createAction(
  '[Drive] Get Contact By Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const UpdateAddressBookStatus = createAction(
  '[Drive] Update Address Book Status',
  props<{
    payload: {
      id: number;
      status: number;
    };
  }>()
);

export const GetAllCompanyDetails = createAction(
  '[Drive] Get All Company Detail'
);

export const SaveAllCompanyDetails = createAction(
  '[Drive] Ssve All Company Detail',
  props<{
    payload: any;
  }>()
);

export const CreateDocumentPin = createAction(
  '[Drive] Create Document Pin',
  props<{
    payload: {
      documentPin: string;
      instance: 'normal' | 'lock document';
    };
    additionalPayload?: {
      document?: any;
    };
  }>()
);

export const UpdateDocumentPin = createAction(
  '[Drive] Update Document Pin',
  props<{
    payload: {
      currentDocumentPin: string;
      newDocumentPin: string;
    };
  }>()
);

export const ForgotDocumentPin = createAction('[Drive] Forgot Document Pin');

export const ResetDocumentPin = createAction(
  '[Drive] Reset Document Pin',
  props<{
    payload: {
      token: string;
      email: string;
      newDocumentPin: string;
    };
  }>()
);

export const VerifyDocumentPin = createAction(
  '[Drive] Verify Document Pin',
  props<{
    payload: {
      documentPin: string;
      // document: any;
      // nextAction: string;
    };
  }>()
);

export const LockDocument = createAction(
  '[Drive] Lock Document',
  props<{
    payload: {
      documentId: number;
    };
  }>()
);

export const UnlockDocument = createAction(
  '[Drive] Unlock Document',
  props<{
    payload: {
      documentId: number;
      documentPin: string;
    };
  }>()
);

export const ActionSuccess = createAction(
  '[Drive] Action Success',
  props<{
    payload: { action: string | number; uniqueId: number };
  }>()
);

export const GetUserActivity = createAction(
  '[Drive] Get User Drive Activity',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetUserActivityListByObjectId = createAction(
  '[Drive] Get Drive Item Activity By Object Id',
  props<{
    payload: {
      skip: number;
      take: number;
      objectId: string;
    };
  }>()
);

export const GetUserActivityListByName = createAction(
  '[Drive] Get Drive Item Activity By Name',
  props<{
    payload: {
      skip: number;
      take: number;
      objectName: string;
    };
  }>()
);

export const GetUserActivityListByUserActivityType = createAction(
  '[Drive] Get User Drive Activity By User Activity Type',
  props<{
    payload: {
      skip: number;
      take: number;
      userActivityType: string;
    };
  }>()
);

export const GetDriveStorage = createAction('[Drive] Get User Drive Storage');

export const GetAllDriveStorage = createAction(
  '[Drive] Get All User Drive Storage',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

/* Upload Progress */
export const AddFileUploadProgressItem = createAction(
  '[Drive] Add File Upload Progress Item',
  props<{ payload: FileProgressItem }>()
);

export const UpdateFileUploadProgressItemProgress = createAction(
  '[Drive] update File Upload Progress Item progress',
  props<{
    payload: {
      id: number;
      progress: number;
    };
  }>()
);

export const UpdateFileUploadProgressItemUploadState = createAction(
  '[Drive] update File Upload Progress Item Upload State',
  props<{
    payload: {
      id: number;
      progress: number;
      uploadState: 'Uploading' | 'Success' | 'Failed';
      message?: string;
    };
  }>()
);

export const ClearFileUploadProgress = createAction(
  '[Drive] Clear File Upload Progress State'
);

export const RemoveUploadProgress = createAction(
  '[Drive] Remove File Upload Progress Item',
  props<{
    payload: {
      id: number;
    };
  }>()
);

/* Shared With Section */
export const SharingLoading = createAction(
  '[Drive] share Drive loading',
  props<{
    payload: boolean;
  }>()
);

export const ShareDriveFolder = createAction(
  '[Drive] Share Drive Folder',
  props<{
    payload: {
      sharedItemGuid: string;
      collaborators: DriveCollaborator[];
      comment: string;
    };
  }>()
);

export const GetSharedWithMeFolders = createAction(
  '[Drive] Get Shared with me Folder',
  props<{
    payload: {
      Skip: number;
      Take: number;
      ParentGuid?: string;
      Search?: string;
      CreatedStartDate?: string;
      CreatedEndDate?: string;
      LastModifiedStartDate?: string;
      LastModifiedEndDate?: string;
    };
  }>()
);

export const GetSharedWithOthersFolders = createAction(
  '[Drive] Get Shared with Others Folder',
  props<{
    payload: {
      Skip: number;
      Take: number;
      ParentGuid?: string;
      Search?: string;
      CreatedStartDate?: string;
      CreatedEndDate?: string;
      LastModifiedStartDate?: string;
      LastModifiedEndDate?: string;
    };
  }>()
);

export const ShareDriveFile = createAction(
  '[Drive] Share Drive File',
  props<{
    payload: {
      sharedItemGuid: string;
      collaborators: DriveCollaborator[];
      comment: string;
    };
  }>()
);

export const GetSharedWithMeFiles = createAction(
  '[Drive] Get Shared with me Files',
  props<{
    payload: {
      Skip: number;
      Take: number;
      FolderGuid?: string;
      Search?: string;
      CreatedStartDate?: string;
      CreatedEndDate?: string;
      LastModifiedStartDate?: string;
      LastModifiedEndDate?: string;
    };
  }>()
);

export const GetSharedWithOthersFiles = createAction(
  '[Drive] Get Shared with Others Files',
  props<{
    payload: {
      Skip: number;
      Take: number;
      ParentGuid?: string;
      Search?: string;
      CreatedStartDate?: string;
      CreatedEndDate?: string;
      LastModifiedStartDate?: string;
      LastModifiedEndDate?: string;
    };
  }>()
);

export const RemoveCollaborator = createAction(
  '[Drive] Remove Drive Folder/File Collaborator',
  props<{
    payload: {
      sharedItemGuid: string;
      sharedItemType: number;
      collaborators: DriveCollaborator[];
      comment: string;
    };
  }>()
);

export const RemoveSelfFromSharedWithMeDriveItem = createAction(
  '[Drive] Remove Self from shared with me file',
  props<{
    payload: {
      sharedItemGuid: string;
      sharedItemType: number;
    };
  }>()
);

export const SecureFolder = createAction(
  '[Drive] Secure folders',
  props<{
    payload: {
      folderGuIds: string[];
    };
  }>()
);

export const UnsecureFolder = createAction(
  '[Drive] Unsecure folders',
  props<{
    payload: {
      folderGuIds: string[];
    };
  }>()
);

export const SecureFiles = createAction(
  '[Drive] Secure Files',
  props<{
    payload: {
      filesGuIds: string[];
    };
  }>()
);

export const UnsecureFiles = createAction(
  '[Drive] Unsecure Files',
  props<{
    payload: {
      filesGuIds: string[];
    };
  }>()
);

export const GetSecureOtp = createAction('[Drive] Get Secure Folder Otp');

export const CreateSecurityPin = createAction(
  '[Drive] Create Security PIn on Secure Folder',
  props<{
    payload: {
      securityCode?: string;
      securityType: number;
    };
  }>()
);

export const VerifyOtp = createAction(
  '[Drive] Verify PIn on Secure Folder',
  props<{
    payload: {
      securityCode: string;
      securityType: number;
    };
  }>()
);

export const UpdateSecurityType = createAction(
  '[Drive] UPdate securityType on Secure Folder',
  props<{
    payload: {
      securityType: number;
    };
  }>()
);

export const ForgotSecureFolderPass = createAction(
  '[Drive] Forgot Secure Folder PassCode'
);

export const ResetSecurityPin = createAction(
  '[Drive] Reset security Pin on Secure Folder',
  props<{
    payload: {
      newPin: number;
    };
  }>()
);

export const UpdateSecurityPassword = createAction(
  '[Drive] Update security Password on Secure Folder',
  props<{
    payload: {
      currentPassword: string;
      newPassword: string;
    };
  }>()
);

export const ResetSecurityPassword = createAction(
  '[Drive] Reset security Password on Secure Folder',
  props<{
    payload: {
      token: string;
      newSecurityPassword: string;
    };
  }>()
);

export const ForgotSecureFolderPassword = createAction(
  '[Drive] Forgot Secure Folder Password'
);

export const CheckedIntoSecureFolder = createAction(
  '[Drive] Set Secure Folder Verification state',
  props<{
    payload: boolean;
  }>()
);

/* Drive comments */
export const GetDriveCommentsByObject = createAction(
  '[Drive] Get Drive Comments By Object ',
  props<{
    payload: {
      ObjectGuId: string;
      Skip: number;
      Take: number;
      StartTime?: string;
      Search?: string;
    };
  }>()
);

export const GetDriveCommentsByObjectLists = createAction(
  '[Drive] Get Drive Comments By Object lists',
  props<{
    payload: {
      ObjectGuId: string;
      Skip: number;
      Take: number;
      StartTime?: string;
      Search?: string;
    };
  }>()
);

export const GetDriveCommentById = createAction(
  '[Drive] Get Drive Comment By Id',
  props<{
    payload: {
      // Comment Id
      GuId: string;
      StartTime?: string;
    };
  }>()
);

export const GetDriveCommentByIdSuccess = createAction(
  '[Drive] Get Drive Comment By Id Success',
  props<{ commentGuId: string; replies: CommentDetails[] }>()
);

export const ResetCommentByIdStore = createAction(
  '[Drive] Reset Comment By Id store'
);

export const GetDriveCommentByUserId = createAction(
  '[Drive] Get Drive Comment By User Id',
  props<{
    payload: {
      ObjectGuId: string;
      Skip: number;
      Take: number;
      StartTime?: string;
      Search?: string;
    };
  }>()
);

export const GetDriveCommentRepliesByParentGuid = createAction(
  '[Drive] Get Drive Comment Replies By Parent Guid',
  props<{
    payload: {
      ParentGuid: string;
      Skip: number;
      Take: number;
      StartTime?: string;
    };
  }>()
);

export const AddDriveFileComments = createAction(
  '[Drive] Create Drive File Comments',
  props<{
    payload: CommentDetails;
  }>()
);

export const UpdateDriveFileComments = createAction(
  '[Drive] Update Drive File Comments',
  props<{
    payload: CommentDetails;
  }>()
);

export const ReplyDriveFileComments = createAction(
  '[Drive] Reply Drive File Comments',
  props<{
    payload: CommentDetails;
  }>()
);

export const DeleteDriveItemComment = createAction(
  '[Drive] Delete Drive File Comment',
  props<{
    payload: {
      commentGuid: string;
    };
  }>()
);
