import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  map,
  share,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import * as PaymentActions from 'src/app/@core/stores/payment/payment.actions';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../../services/notification.service';
import { ascendingSort } from '../../utils/helpers';
import * as fromApp from '../app/app.reducer';
import {
  USE_ACCESS_TOKEN,
  USE_DEVELOPER_TOKEN,
} from '../../interceptors/app.interceptor.service';
import { Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class PaymentEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private notificationService: NotificationService,
    private router: Router,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(PaymentActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllPaymentIntegrations$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentActions.GetAllPaymentIntegrations),
        withLatestFrom(this.store.select('auth')),
        switchMap(([paymentData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/PaymentChannels/getpaymentchannels/${authState.user.SubscriberId}/${authState.user.UserId}`
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Payment Integration] Get All Payment Integration Successful',
                  });
                  return ascendingSort(resData.entity, 'name');
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Payment Integration] Failed To Get All Payment Integrations',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Payment][CatchError]  Failed To Get All API Calls Log By Subscriber Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  activatePaymentIntegrationStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActions.ActivatePaymentIntegrationStatus),
      withLatestFrom(this.store.select('auth')),
      switchMap(([paymentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/PaymentChannels/activatepaymentchannel`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...paymentData.payload,
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                return PaymentActions.GetAllPaymentIntegrations();
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Payment Integration] Activate Payment Integration Status Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Payment][CatchError]  Activate Payment Integration Status Failed ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deactivatePaymentIntegrationStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActions.DeactivatePaymentIntegrationStatus),
      withLatestFrom(this.store.select('auth')),
      switchMap(([paymentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/PaymentChannels/deactivatepaymentchannel`,
            {
              userId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
              ...paymentData.payload,
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );
                return PaymentActions.GetAllPaymentIntegrations();
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Payment Integration] Deactivate Payment Integration Status Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Payment][CatchError]  Deactivate Payment Integration Status Failed ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  createPaymentIntegration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentActions.CreatePaymentIntegration),
        withLatestFrom(this.store.select('auth')),
        switchMap(([userData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocSubscriptionUrl}/PaymentChannels/create`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...userData.payload,
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch(
                    PaymentActions.GetAllPaymentIntegrations()
                  );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  this.store.dispatch({
                    type: '[Payment Integration] Create Payment Integration Failed',
                  });
                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Payment Integration][CatchError]  Create Payment Integration Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  ediPaymentIntegration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentActions.EditPaymentIntegration),
        withLatestFrom(this.store.select('auth')),
        switchMap(([userData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocSubscriptionUrl}/PaymentChannels/update`,
              {
                userId: authState.user.UserId,
                subscriberId: authState.user.SubscriberId,
                ...userData.payload,
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                  this.store.dispatch(
                    PaymentActions.GetAllPaymentIntegrations()
                  );

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                  this.store.dispatch({
                    type: '[Payment Integration] Edit Payment Integration Failed',
                  });
                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Payment Integration][CatchError]  Edit Payment Integration Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );
  // ${authState.user.SubscriberId}/${authState.user.UserId}
  getActivePaymentChannels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActions.GetActivePaymentChannels),
      withLatestFrom(this.store.select('auth')),
      switchMap(([paymentData, authState]) => {
        return this.http
          .get(
            `${
              environment.onyxDocSubscriptionUrl
            }/PaymentChannels/getactivepaymentchannels/${
              paymentData.payload
                ? paymentData.payload.subscriberId
                : authState?.user?.Role?.SubscriberId
            }/${
              paymentData.payload
                ? paymentData.payload.userId
                : authState?.user?.UserId
            }`,
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(PaymentActions.IsLoading({ payload: false }));

              if (resData.succeeded) {
                return PaymentActions.SaveActivePaymentChannels({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Payment] Failed To Get Active Payment Channels',
                };
              }
            }),
            catchError((err) => {
              return this.handleCatchError(
                err,
                `[Payment][CatchError]  Failed To Get All API Calls Log By Subscriber Id ${err.message}`
              );
            })
          );
      })
    );
  });

  initiatePaymentWithPaystack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaymentActions.InitiatePaymentWithPaystack),
        withLatestFrom(this.store.select('auth')),
        switchMap(([paymentData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocSubscriptionUrl}/Paystack/createpaystackpayment`,
              paymentData.payload
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  PaymentActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Payment] Initialize Payment With Paystack Was Succesful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Payment Integration] Failed To Initialize Payment With Paystack',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Payment Integration][CatchError]  Edit Payment Integration Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  verifyPaystackPayment$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaymentActions.VerifyPaystackPayment),
        withLatestFrom(this.store.select('auth')),
        switchMap(([paymentData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocSubscriptionUrl}/Paystack/verifypaystackpayment`,
              {
                ...paymentData.payload,
                userId: paymentData?.payload?.userId ?? authState.user.UserId,
                subscriberId:
                  paymentData?.payload?.subscriberId ??
                  authState.user.SubscriberId,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  PaymentActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Payment] Paystack Payment Was Verified Succesfully',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Payment] Failed To Verify Paystack Payment',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Payment][CatchError] Failed To Verify Paystack Payment ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  initiatePaymentWithFlutterwave$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaymentActions.InitiatePaymentWithFlutterwave),
        withLatestFrom(this.store.select('auth')),
        switchMap(([paymentData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocSubscriptionUrl}/Flutterwave/initializeflutterwavepayment`,
              paymentData.payload
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  PaymentActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Payment] Initialize Payment With Flutterwave Was Succesful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Payment] Failed To Initialize Payment With Flutterwave',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Payment][CatchError] Failed To Initialize Payment With Flutterwave ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  verifyFlutterwavePayment$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaymentActions.VerifyFlutterwavePayment),
        withLatestFrom(this.store.select('auth')),
        switchMap(([paymentData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocSubscriptionUrl}/Flutterwave/verifyflutterwavepayment`,
              {
                ...paymentData.payload,
                userId: paymentData?.payload?.userId ?? authState.user.UserId,
                subscriberId:
                  paymentData?.payload?.subscriberId ??
                  authState.user.SubscriberId,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  PaymentActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Payment] Flutterwave Payment Was Verified Succesfully',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Payment] Failed To Verify Flutterwave Payment',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Payment][CatchError] Failed To Verify Flutterwave Payment ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  verifyStripePayment$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaymentActions.VerifyStripePayment),
        withLatestFrom(this.store.select('auth')),
        switchMap(([paymentData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocSubscriptionUrl}/Stripe/getpaymentintentstatus`,
              {
                ...paymentData.payload,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  PaymentActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Payment] Stripe Payment Was Verified Succesfully',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Payment] Failed To Verify Stripe Payment',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Payment][CatchError] Failed To Verify Stripe Payment ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  initiatePaymentWithStripe$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaymentActions.InitiatePaymentWithStripe),
        withLatestFrom(this.store.select('auth')),
        switchMap(([paymentData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocSubscriptionUrl}/Stripe/createcardpayment`,
              paymentData.payload
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  PaymentActions.IsLoading({ payload: false })
                );

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Payment] Initialize Payment With Stripe Was Succesful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Payment] Failed To Initialize Payment With Stripe',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Payment][CatchError] Failed To Initialize Payment With Stripe ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  makeSubscriptionPayment$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaymentActions.MakeSubscriptionPayment),
        withLatestFrom(this.store.select('auth')),
        switchMap(([susbscriptionData, authState]) => {
          const params = new HttpParams().set(
            'isSubscriberNewlyCreated',
            susbscriptionData?.payload?.isSubscriberNewlyCreated ?? false
          );
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/SubscriptionPayment/makesubscriptionpayment`,
              {
                ...susbscriptionData.payload,
                subscriberId:
                  susbscriptionData.payload.subscriberId ??
                  authState.user.SubscriberId,
                userId:
                  susbscriptionData.payload.userId ?? authState.user.UserId,
                customer: {
                  email:
                    susbscriptionData.payload.customer.email ??
                    authState.user.Email,
                  phoneNumber:
                    susbscriptionData.payload.customer.phoneNumber ??
                    authState.user.PhoneNumber,
                  name:
                    susbscriptionData.payload.customer.name ??
                    `${authState.user.FirstName}  ${authState.user.LastName}`,
                },
              },
              {
                context: new HttpContext().set(
                  USE_DEVELOPER_TOKEN,
                  susbscriptionData.payload.isSubscriberNewlyCreated
                    ? true
                    : false
                ),

                params,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  PaymentActions.IsLoading({
                    payload: false,
                  })
                );
                if (resData.succeeded) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Payment link generated successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch(
                    PaymentActions.IsLoading({ payload: false })
                  );

                  this.store.dispatch({
                    type: '[Payment] Make Subscription Payment Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Payment] Failed To Make Subscription Payment',
                  });

                  if (!susbscriptionData.payload.isSubscriberNewlyCreated) {
                    this.router.navigate(['/app/account-settings/billing']);
                  }

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Payment][CatchError] Failed To Make Subscription Payment ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getPaymentHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActions.GetPaymentHistory),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionData, authState]) => {
        let param = new HttpParams();

        param = param.set('skip', 0);
        param = param.set('take', 500);

        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocSubscriptionUrl}/Payments/getpaymenthistorybysubscriber/${authState.user.SubscriberId}/${authState.user.UserId}`,
            {
              params: param,
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(PaymentActions.IsLoading({ payload: false }));
              if (resData.succeeded === true) {
                return PaymentActions.SavePaymentHistory({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  PaymentActions.SavePaymentHistory({
                    payload: null,
                  })
                );

                return {
                  type: '[Payment] Failed To Get Payment History',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Payment][CatchError]  Failed To Get Payment History ${errorRes.message}`
              );
            })
          );
      })
    );
  });
}
